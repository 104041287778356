import {
  NEW_ACTIVE_THREAD,
  FETCH_CONVERSATION_MESSAGES,
  FETCH_CONVERSATION_STATUS,
  SELECT_UNSELECT_MESSAGES,
  OPEN_CLOSE_CHECKBOXES,
  MESSAGES_TO_FORWARD,
  SEND_NEW_MESSAGE,
  SET_ACTIVE_TAB,
  SEND_MESSAGE_STATUS,
  CREATE_THREAD,
  SAVE_THREADS,
  GET_THREADS,
  GET_THREADS_RECENT,
  FILTER_MESSAGES,
  RECEIVE_MESSAGE,
  RECENT_UPDATED,
  ARCHIVE_MESSAGE,
  UPDATE_THREAD_SEEN,
  REMOVE_THREAD,
  REMOVE_THREAD_UNREAD,
  BEEN_FORWARDED_TO_CCM,
  BLOCKED_USER,
  UPDATE_HAS_BLOCKED_USER,
  UPDATE_IS_BLOCKED_USER,
  UPDATE_A_MESSAGE,
  GROUP_NAME_UPDATED,
  SET_DRAFT_MESSAGE,
  SET_DEFAULT_DRAFT_MESSAGES,
  IS_FETCHING_SELECTED_THREAD,
  SELECTED_THREAD,
  TO_DOS_LOADING,
  HAS_MORE_TO_DOS,
  SET_TO_DOS_ALL,
  SET_TO_DOS_ALL_HIGH,
  SET_TO_DOS_ALL_MED,
  SET_TO_DOS_ALL_ROUTINE,
  SET_TO_DOS_PENDING,
  SET_TO_DOS_PENDING_HIGH,
  SET_TO_DOS_PENDING_MED,
  SET_TO_DOS_PENDING_ROUTINE,
  SET_TO_DOS_CRITICAL,
  SET_TO_DOS_DONE,
  SET_TO_DOS_DONE_HIGH,
  SET_TO_DOS_DONE_MED,
  SET_TO_DOS_DONE_ROUTINE,
  SET_TO_DOS_RECENT,
  SET_TO_DOS_PAGE_ALL,
  IS_SENDING_MESSAGE,
  SET_HAS_CRITICAL,
  SET_PREV_OPENED_THREAD,
  SET_ATTENTIONED_MESSAGE,
  SET_OPEN_CRITICAL,
  SET_MESSAGES_SHOWN,
  SET_MESSAGES_BADGES,
  SET_ACTIVITY_LIST,
  SET_RICH_TEXT,
} from "../actions/types";

import Parse from "parse";
import sort from "fast-sort";
import moment from "moment";
import { tagRecent } from "../actions/ThreadAction";
import ActivityList from "../components/Pages/Navbar/ActivityList";

const initialState = {
  activeTab: localStorage.getItem("activeTab") || "dashboard",
  selectedThread: {},
  activeThread: {},
  conversation: {},
  selected_messages: [],
  threads: [],
  threadConversationList: [],
  tempConversationList: [], // this will hold temporary thread until user send a message to get the latest threadObject
  filterResult: [],
  fetchStatus: "", // So that we can handle if it failed
  initialLoad: false, // Check if message already been fetch for the first time
  showCheckBoxes: false,
  isFetchingSelectedThread: false,
  isSending: false,
  allToDosPendingCritical: [],
  prevOpenedThread: null,
  openCritical: false,
  activities: [],
  selectedSubFilter: 2,

  // If fetching threads in progress
  isLoading: false,

  // Cache of current message by threadId
  draftMessages: JSON.parse(localStorage.getItem("draftMessages") || "{}"),

  // Total number of unread messages
  unreadMessagesTotal: 0,

  // The current page viewed
  currentPage: 0,

  // If more items can be loaded by scrolling
  hasMore: false,

  // The sub tabs in inbox: ALL, UNREAD, PRIORITY
  activeMessageTab: "ALL",

  // The sub tab for UNREAD inbox
  activeUnreadTab: "all",

  // Whether to sort threads in reverse order
  // Sort by what? Ask the view
  sortReverse: false,

  // Whether to filter threads with attention
  filterAttention: false,

  // Whether emoji container is shown
  emojiShown: false,

  // Whether tag modal is shown
  tagsModalShown: false,

  // The selected recent for tagging
  recentToTag: {},

  // Whether tag filter modal is shown
  tagsFilterModalShown: false,
  // Recents filtered by label
  threadsByLabel: [],

  // The current circleLabel for filtering
  circleLabelId: "",

  // Whether to show the tag selection
  tagsSelectShown: false,

  // Threads filtered by label
  threadsByLabel: {
    initialLoaded: false,
    fetchSuccess: true,
    hasMore: false,
    items: [],
    page: 0,
  },

  // Threads unread
  threadsUnread: {
    initialLoaded: false,
    fetchSuccess: true,
    hasMore: false,
    items: [],
    page: 0,
  },

  // FOr reply preview
  replyModalShown: false,
  replyFileUrl: null,
  // allToDos: [],
  allToDosRecent: [],
  allToDosCurrentPage: 0,
  allToDosHasMore: false,
  isToDosLoading: false,
  attentionedMessage: null,
  read: "",
  messageBadges: {
    attention: false,
    personal: false,
    urgent: false,
  },
};

/**
 * Check if the current conversation request load more
 * @param {Object} state
 * @param {string} threadID
 */
const updateConversation = (
  conversation,
  threadId,
  newMessages,
  newFiles,
  initialLoad = { isFetching: false, fetchResult: "success" }
) => {
  // if (conversation.thread) {
  // 	if (conversation.thread.threadId === threadId) {
  return {
    ...conversation,
    messages: newMessages,
    files: newFiles,
    initialLoad: initialLoad,
  };
  // 	}
  // 	return conversation;
  // }
  // return conversation;
};

const updateMessages = (messages, message) => {
  var AddTimeDifferent;
  /**
   * Add TimeDifferent if duration from the last message and the new message is 30min
   */
  if (messages.length === 0) {
    AddTimeDifferent = {
      __TimeType: "TimeDifferent",
      dateTime: message.createdAt,
    };
  } else {
    var lastDate = moment(messages[messages.length - 1].createdAt);
    var thisDate = moment(message.createdAt);
    var duration = moment.duration(thisDate.diff(lastDate));
    var minutes = duration.asMinutes();
    if (minutes > 60) {
      AddTimeDifferent = {
        __TimeType: "TimeDifferent",
        dateTime: message.createdAt,
      };
    }
  }

  // Update newMassage base on timeDiff.
  if (AddTimeDifferent) {
    return [...messages, AddTimeDifferent, message];
  } else {
    return [...messages, message];
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TO_DOS_ALL:
      return {
        ...state,
        allToDos: action.data,
        allToDosCurrentPage: action.page,
        allToDosHasMore: action.hasMore,
      };

    case SET_TO_DOS_ALL_HIGH:
      return {
        ...state,
        allToDoHigh: action.data,
        allToDoHighCP: action.page,
        allToDoHighHM: action.hasMore,
      };

    case SET_TO_DOS_ALL_MED:
      return {
        ...state,
        allToDoMed: action.data,
        allToDoMedCP: action.page,
        allToDoMedHM: action.hasMore,
      };

    case SET_TO_DOS_ALL_ROUTINE:
      return {
        ...state,
        allToDoRoutine: action.data,
        allToDoRoutineCP: action.page,
        allToDoRoutineHM: action.hasMore,
      };

    case SET_TO_DOS_PENDING:
      return {
        ...state,
        allToDosPending: action.data,
        pendingToDosCurrentPage: action.page,
        pendingToDosHasMore: action.hasMore,
      };

    case SET_TO_DOS_PENDING_HIGH:
      return {
        ...state,
        allToDosPendingHigh: action.data,
        pendingToDoHighCP: action.page,
        pendingToDoHighHM: action.hasMore,
      };

    case SET_TO_DOS_PENDING_MED:
      return {
        ...state,
        allToDosPendingMed: action.data,
        pendingToDoMedCP: action.page,
        pendingToDoMedHP: action.hasMore,
      };

    case SET_TO_DOS_PENDING_ROUTINE:
      return {
        ...state,
        allToDosPendingRoutine: action.data,
        pendingToDoRoutineCP: action.page,
        pendingToDoRoutineHM: action.hasMore,
      };

    case SET_TO_DOS_CRITICAL:
      return {
        ...state,
        allToDosPendingCritical: action.data,
      };

    case SET_TO_DOS_DONE:
      return {
        ...state,
        allToDosDone: action.data,
        doneToDosCurrentPage: action.page,
        doneToDosHasMore: action.hasMore,
      };

    case SET_TO_DOS_DONE_HIGH:
      return {
        ...state,
        allToDoDoneHigh: action.data,
        doneToDoHighCP: action.page,
        doneToDoHighHM: action.hasMore,
      };

    case SET_TO_DOS_DONE_MED:
      return {
        ...state,
        allToDoDoneMed: action.data,
        doneToDoMedCP: action.page,
        doneToDoMedHP: action.hasMore,
      };

    case SET_TO_DOS_DONE_ROUTINE:
      return {
        ...state,
        allToDoDoneRoutine: action.data,
        doneToDoRoutineCP: action.page,
        doneToDoRoutineHM: action.hasMore,
      };

    case SET_TO_DOS_RECENT:
      return {
        ...state,
        allToDosRecent: action.data,
        recentToDosCurrentPage: action.page,
        recentToDosHasMore: action.hasMore,
      };

    case SET_TO_DOS_PAGE_ALL:
      return {
        ...state,
        allToDosCurrentPage: action.allToDosCurrentPage,
      };

    case TO_DOS_LOADING:
      return {
        ...state,
        isToDosLoading: action.isToDosLoading,
      };
    case HAS_MORE_TO_DOS:
      return {
        ...state,
        hasMoreToDos: action.hasMoreToDos,
      };
    /**
     * Set loading
     *
     */
    case "SET_MESSAGE_TAB":
      const filterAttention =
        action.value === "ALL" || action.value === "ALERTS" || action.value === "RECENT"
          ? false
          : state.filterAttention;
      const subUnreadTab = action.value === "ALL" ? "all" : "";

      return {
        ...state,
        activeMessageTab: action.value,
        filterAttention,
        activeUnreadTab: subUnreadTab,
      };
      break;

    case "SET_SUB_MESSAGE_TAB":
      return {
        ...state,
        activeUnreadTab: action.value,
      };
      break;

    case "SET_EMOJI_SHOWN":
      return {
        ...state,
        emojiShown: action.value,
      };
      break;

    /**
     * Set loading
     *
     */
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.value,
      };
      break;

    /**
     * Set total unread count
     *
     */
    case "SET_UNREAD_COUNT":
      return {
        ...state,
        unreadMessagesTotal: action.value,
      };
      break;

    /**
     * Store draft message
     *
     */
    case SET_DRAFT_MESSAGE:
      return {
        ...state,
        draftMessages: {
          ...state.draftMessages,
          [action.threadId]: action.message,
        },
      };
      break;

    case SET_DEFAULT_DRAFT_MESSAGES:
      return {
        ...state,
        draftMessages: {
          ...state.draftMessages,
        },
      };
      break;

    /**
     * Set activeTab.
     * @param {string} action.tab
     */
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };
      break;

    /**
     * Save threads
     * @param {Object[]} action.threads
     */
    case SAVE_THREADS:
      if (action.status === "FAILED") {
        return {
          ...state,
          fetchStatus: action.status,
        };
      }

      const { replaceThreads, page, hasMore, circleLabelId } = action;

      const currentPage = page;

      const threads = replaceThreads ? action.threads : state.threads.concat(action.threads);

      return {
        ...state,
        threads,
        initialLoad: true,
        fetchStatus: action.status,
        circleLabelId,
        hasMore,
        currentPage,
      };
      break;

    /**
     * @return {Object} state - Thread List
     */
    case GET_THREADS:
      return state;
      break;

    /**
     * Save threads
     * @param {Object[]} action.threads
     */
    case GET_THREADS_RECENT:
      if (action.status === "FAILED") {
        return {
          ...state,
          fetchStatus: action.status,
        };
      }

      return {
        ...state,
        recentThreads: action.threads,
      };
      break;

    /**
     * Create temporary thread to fetch conversation message.
     * @param {Object} action.data - Circle Object or Contact Object
     * @param {string} action.fromTab
     */
    case CREATE_THREAD:
      let tempThreadId = "";
      if (action.fromTab === "group" || action.fromTab === "chart") {
        tempThreadId = action.data.objectId;
      } else if (action.fromTab === "contact") {
        let idArray = [Parse.User.current().id, action.data.contact.objectId];
        idArray = sort(idArray).asc((p) => p.toLowerCase());
        tempThreadId = idArray.join("_");
      } else if (action.fromTab === "subgroup") {
      }

      // Check threadConversationList if thread exist
      var currentThread = state.threads.find((c) => c.threadId === tempThreadId);

      if (currentThread) {
        var currentConversation = state.threadConversationList.find((c) => c.thread.threadId === tempThreadId);
        if (currentConversation) {
          return {
            ...state,
            activeThread: currentThread,
            conversation: currentConversation,
            // activeTab : "message"
          };
        } else {
          // NOTE Update this block of code with the same with NEW_ACTIVE_THREAD
          var newConversation = {
            thread: currentThread,
            messages: [],
            files: [],
            initialLoad: {
              isFetching: false,
              fetchResult: "none", // success || failed || none
            },
          };
          return {
            ...state,
            activeThread: currentThread,
            conversation: newConversation,
            threadConversationList: [...state.threadConversationList, newConversation],
            // activeTab : "message"
          };
        }
      } else {
        var currentConversation = state.tempConversationList.find((c) => c.thread.threadId === tempThreadId);
        if (currentConversation) {
          return {
            ...state,
            activeThread: currentConversation.thread,
            conversation: currentConversation,
          };
        }
        /**
         * Create a new thread then save it to tempConversationList
         * so that it will fetch the thread conversation.
         */
        // REVIEW Update this threadObject base on the parse Recent Class
        let thread = {
          senderName: "",
          senderObjectId: "",
          threadDetail: "",
          unreadMessageCount: 0,
          threadId: tempThreadId,
          objectId: tempThreadId,
        };

        switch (action.fromTab) {
          case "group":
          case "chart":
            thread.threadType = "group";
            if (action.data.image) {
              thread.groupImageURL = action.data.image.url;
            }
            thread.groupName = action.data.name;
            thread.groupType = action.data.groupType;
            thread.patientDOB = action.data.dob;

            break;

          case "contact":
            thread.partnerHcuType = "regular";
            thread.partnerName = action.data.contact.displayName;
            thread.partnerObjectId = action.data.contact.objectId;
            thread.threadType = "private";
            if (action.data.contact.picture) thread.partnerImageURL = action.data.contact.picture.url;
            if (action.data.contact.hcuSubscriptionType)
              thread.partnerHcuType = action.data.contact.hcuSubscriptionType;
            break;

          case "subgroup":
            thread.threadType = "group";
            if (action.data.image) {
              thread.groupImageURL = action.data.image.url;
            }
            thread.groupName = action.data.name;
            thread.groupType = action.data.groupType;
            thread.subgroupCircle = {
              className: "Circle",
              objectId: action.data.objectId,
              __type: "Pointer",
            };

            thread.subgroupMembers = action.data.subgroup.members;
            thread.subgroupName = action.data.subgroup.name;
            thread.subgroupSession = action.data.subgroup;
            thread.subgroupSessionId = action.data.subgroup.objectId;
            thread.threadId = action.data.subgroup.objectId;
            thread.objectId = action.data.subgroup.objectId;
            break;

          default:
            break;
        }

        var newConversation = {
          thread: thread,
          messages: [],
          files: [],
          initialLoad: {
            isFetching: false,
            fetchResult: "none", // success || failed || none
          },
        };
        return {
          ...state,
          activeThread: thread,
          conversation: newConversation,
          tempConversationList: [...state.tempConversationList, newConversation],
        };
      }

      break;

    case SELECTED_THREAD:
      return {
        ...state,
        selectedThread: action.selectedThread,
      };

      break;

    case IS_FETCHING_SELECTED_THREAD:
      return {
        ...state,
        isFetchingSelectedThread: action.isFetchingSelectedThread,
      };

      break;

    /**
     * Set activeThread
     * @param {Object} action.thread
     */
    case NEW_ACTIVE_THREAD:
      if (action.thread === "") {
        return {
          ...state,
          activeThread: {},
          conversation: {},

          // Hide emoji picker when selecting new thread
          emojiShown: false,
        };
      }
      var currentConversation = state.threadConversationList.find((c) => c.thread.threadId === action.thread.threadId);
      if (currentConversation) {
        return {
          ...state,
          activeThread: action.thread,
          conversation: currentConversation,

          // Hide emoji picker when selecting new thread
          emojiShown: false,
        };
      } else {
        var newConversation = {
          thread: action.thread,
          messages: [],
          files: [],
          initialLoad: {
            isFetching: false,
            fetchResult: "none",
          },

          // Hide emoji picker when selecting new thread
          emojiShown: false,
        };
        // Create new conversation
        return {
          ...state,
          activeThread: action.thread,
          conversation: newConversation,
          threadConversationList: [...state.threadConversationList, newConversation],

          // Hide emoji picker when selecting new thread
          emojiShown: false,
        };
      }
      break;

    /**
     * Set activeThread
     * @param {Object} action.thread
     */
    case "NEW_ACTIVE_PRIORITY":
      if (action.thread === "") {
        return {
          ...state,
          activeThread: {},
          conversation: {},
        };
      }

      var currentConversation = state.threadConversationList.find((c) => c.thread.threadId === action.thread.threadId);
      if (currentConversation) {
        return {
          ...state,
          activeThread: action.thread,
          conversation: currentConversation,
        };
      } else {
        var newConversation = {
          thread: action.thread,
          messages: [],
          files: [],
          initialLoad: {
            isFetching: false,
            fetchResult: "none",
          },
        };
        // Create new conversation
        return {
          ...state,
          activeThread: action.thread,
          conversation: newConversation,
          threadConversationList: [...state.threadConversationList, newConversation],
        };
      }
      break;

    /**
     *
     * @param {string} action.threadId
     */
    case FETCH_CONVERSATION_STATUS:
      if (state.conversation.thread.threadId === action.threadId) {
        return {
          ...state,
          conversation: {
            ...state.conversation,
            initialLoad: {
              ...state.conversation.initialLoad,
              isFetching: true,
            },
          },
          threadConversationList: state.threadConversationList.map((conv) => {
            if (conv.thread.threadId === action.threadId) {
              return {
                ...conv,
                initialLoad: {
                  ...state.conversation.initialLoad,
                  isFetching: true,
                },
              };
            }
            return conv;
          }),
        };
      }
      return {
        ...state,
        threadConversationList: state.threadConversationList.map((conv) => {
          if (conv.thread.threadId === action.threadId) {
            return {
              ...conv,
              initialLoad: {
                ...state.conversation.initialLoad,
                isFetching: true,
              },
            };
          }
          return conv;
        }),
      };
      break;

    /**
     * Handle request messages from Load Messages and Load More Messages
     * @param {Object[]} action.conversation - List of messages
     * @param {Object[]} action.files - List of conversation files
     * @param {string} action.threadId
     */
    case FETCH_CONVERSATION_MESSAGES:
      // if (action.conversation.length === 0) {
      // 	// FIXME bug when loading more with no result

      // 	// if (state.conversation.thread.threadId === action.threadId) {
      // 	// 	let newConversation = {
      // 	// 		...state.conversation,
      // 	// 		isLoadingMore : false
      // 	// 	}
      // 	// }

      // 	return {
      // 		...state,
      // 	}

      // }
      if (state.conversation.thread.threadId === action.threadId) {
        // Update Message depends on message length
        var newMessages = action.reload ? [] : [...state.conversation.messages];
        if (newMessages.length === 0) {
          newMessages = action.conversation;
        } else {
          newMessages = [...action.conversation, ...newMessages];
        }

        var newFiles = [...state.conversation.files];
        if (newFiles.length === 0) {
          newFiles = action.files;
        } else {
          newFiles = [...action.files, ...newFiles];
        }

        // Update Conversation
        const newConversation = updateConversation(state.conversation, action.threadId, newMessages, newFiles);

        // Update threadConversationList or tempConversationList
        return {
          ...state,
          conversation: newConversation,
          threadConversationList: state.threadConversationList.map((conv) => {
            if (conv.thread.threadId === action.threadId) {
              return newConversation;
            }
            return conv;
          }),
          tempConversationList: state.tempConversationList.map((conv) => {
            if (conv.thread.threadId === action.threadId) {
              return newConversation;
            }
            return conv;
          }),
        };
      } else {
        return {
          ...state,
          threadConversationList: state.threadConversationList.map((conv) => {
            if (conv.thread.threadId === action.threadId) {
              // Update New Message base on tempConversationList Conversation
              var newMessages = [...conv.messages];
              if (newMessages.length === 0) {
                newMessages = action.conversation;
              } else {
                newMessages = [...action.conversation, ...newMessages];
              }

              var newFiles = [...conv.files];
              if (newFiles.length === 0) {
                newFiles = action.files;
              } else {
                newFiles = [...action.files, ...newFiles];
              }
              // Update conversation
              const newConversation = updateConversation(conv, action.threadId, newMessages, newFiles);
              return newConversation;
            }
            return conv;
          }),
          tempConversationList: state.tempConversationList.map((conv) => {
            // Update New Message base on tempConversationList Conversation
            if (conv.thread.threadId === action.threadId) {
              // Update New Message from temConversationList
              var newMessages = [...conv.messages];
              if (newMessages.length === 0) {
                newMessages = action.conversation;
              } else {
                newMessages = [...action.conversation, ...newMessages];
              }
              // Update file list
              var newFiles = [...conv.files];
              if (newFiles.length === 0) {
                newFiles = action.files;
              } else {
                newFiles = [...action.files, ...newFiles];
              }
              const newConversation = updateConversation(conv, action.threadId, newMessages, newFiles);
              return newConversation;
            }
            return conv;
          }),
        };

        // Update threadConversation list
        // Update conversation
      }

      return state;

      break;

    /**
     * Save new message to List
     * @param {Object} action.message
     * @param {string} action.threadId
     */

    case IS_SENDING_MESSAGE:
      return {
        ...state,
        isSending: action.isSending,
      };

      break;

    case SEND_NEW_MESSAGE:
      /*
				If there may be no selected thread,
				such case when forwarding conference logs
			*/
      if (!state.conversation || !state.conversation.messages) {
        return state;
      }

      let currentUser = Parse.User.current();
      let AddTimeDifferent;
      let newMessages2 = [...state.conversation.messages];
      let newFiles2 = [...state.conversation.files]; //FIXME When sending message with file need to be updated.
      /**
       * Add TimeDifferent if duration from the last message and the new message is 30min
       */

      if (newMessages2.length === 0) {
        AddTimeDifferent = {
          __TimeType: "TimeDifferent",
          dateTime: action.message.createdAt,
        };
      } else {
        var lastDate = moment(newMessages2[newMessages2.length - 1].createdAt);
        var thisDate = moment(action.message.createdAt);
        var duration = moment.duration(thisDate.diff(lastDate));
        var minutes = duration.asMinutes();
        if (minutes > 60) {
          AddTimeDifferent = {
            __TimeType: "TimeDifferent",
            dateTime: action.message.createdAt,
          };
        }
      }
      // Update newMassage base on timeDiff.
      if (AddTimeDifferent) {
        let msg = null;
        if (action.message.actionItem) {
          msg = {
            ...action.message,
            actionItem: {
              objectId: action.message.actionItem,
            },
          };
        } else {
          msg = action.message;
        }
        newMessages2 = [...newMessages2, AddTimeDifferent, msg];
      } else {
        let msg = null;
        if (action.message.actionItem) {
          msg = {
            ...action.message,
            actionItem: {
              objectId: action.message.actionItem,
            },
          };
        } else {
          msg = action.message;
        }
        newMessages2 = [...newMessages2, msg];
      }

      // If not in threaList save new thread
      var thread = state.threads.find((c) => c.threadId === action.threadId);

      // let _thread = {};
      if (thread) {
        console.log("thread -- yes");
        var _thread = {
          ...thread,
          threadDetail: action.message.text,
          messageDate: {
            iso: action.message.createdAt.toISOString(),
            __type: "Date",
          },
          senderObjectId: currentUser.id,
          senderName: currentUser.get("firstName"),
        };

        var newConversation = updateConversation(state.conversation, action.threadId, newMessages2, newFiles2);

        // If selected thread and new message are the same
        if (state.conversation.thread.threadId != action.threadId) {
          newMessages2.splice(-2);
        }
        let _threads = [...state.threads.filter((item) => item.threadId !== _thread.threadId)];
        _threads = [_thread, ..._threads];

        return {
          ...state,
          // activeThread: _thread,
          conversation: newConversation,
          activeTab: "message",
          threads: _threads,
          threadConversationList: state.threadConversationList.map((conv) => {
            if (conv.thread.threadId === action.threadId) {
              return newConversation;
            }
            return conv;
          }),
        };
      } else if (!thread && action.isForwarding) {
        return {
          ...state,
        };
      } else {
        // Update thread for the threadList
        var _thread = {
          ...state.conversation.thread,
          threadDetail: action.message.text,
          messageDate: {
            iso: action.message.createdAt.toISOString(),
            __type: "Date",
          },
          senderObjectId: currentUser.id,
          senderName: currentUser.get("firstName"),
        };

        const newConversation = updateConversation(state.conversation, action.threadId, newMessages2, newFiles2);

        return {
          ...state,
          activeThread: _thread,
          activeTab: "message",
          conversation: newConversation,
          threads: [_thread, ...state.threads],
          threadConversationList: [...state.threadConversationList, newConversation],
          tempConversationList: state.tempConversationList.filter((item) => item.thread.threadId !== action.threadId), // Remove the conversation
        };
      }
      break;

    /**
     * Save new message to List
     * @param {Object} action.message
     * @param {string} action.threadId
     * @param {string} action.thumbUrl
     */
    case "UPDATE_MESSAGE_THUMBNAIL":
      debugger;
      var thread = state.threads.find((c) => c.threadId === action.threadId);
      if (thread) {
        /**
         * Check if conversation is not empty
         * if empty return only the state
         */
        if (state.conversation.thread) {
          /**
           * Return the new conversation and messages since the activethread is the sender
           */
          if (state.conversation.thread.threadId === action.threadId) {
            const newMessages = state.conversation.messages.map((msg) => {
              if (msg.objectId === action.objectId) {
                return {
                  ...msg,
                  thumbnail: {
                    ...(msg.thumbnail || {}),
                    ...action.thumbnail,
                  },
                };
              }
              return msg;
            });

            const newConversation = {
              ...state.conversation,
              messages: newMessages,
            };

            return {
              ...state,
              conversation: newConversation,
              threadConversationList: state.threadConversationList.map((conv) => {
                if (conv.thread.threadId === action.threadId) {
                  return newConversation;
                }
                return conv;
              }),
              tempConversationList: state.tempConversationList.map((conv) => {
                if (conv.thread.threadId === action.threadId) {
                  return newConversation;
                }
                return conv;
              }),
            };
          }
        } else {
          // Return state since user doesnt have a conversation at the moment
          return state;
        }
      } else {
        return state;
      }

      return state;
      break;

    /**
     * Update message status either success or error
     * @param {Object} action.message - Message to be update
     * @param {string} action.newMessageObjectId - change the temporary objectId to the generated objectId by parse.com
     * @param {string} action.threadId
     */
    case SEND_MESSAGE_STATUS:
      if (!state.conversation || !state.conversation.messages) {
        return state;
      }
      var newMessage = state.conversation.messages.map((message) => {
        if (message.objectId === action.messageObjectId) {
          if (action.status === "success") {
            return {
              ...message,
              picture: action.message.picture,
              newMessageStatus: "",
              objectId: action.newMessageObjectId,
              attachments: action.message.attachments,
              replyTo: action.message.replyTo,
              user: action.message.user,
            };
          }
          if (action.status === "error") {
            return {
              ...message,
              newMessageStatus: "error",
            };
          }
          if (action.status === "sending") {
            return {
              ...message,
              newMessageStatus: "sending",
            };
          }
          return message;
        }
        return message;
      });

      let newFiles3 = [...state.conversation.files];

      if (state.conversation.thread && state.conversation.thread.threadId === action.threadId) {
        const attachmentPics = getAttachmentPics(action.message);
        newFiles3 = newFiles3.concat(attachmentPics);
        const { picture } = action.message;

        if (picture) {
          newFiles3.unshift({
            ...picture,
            conversationId: action.message.objectId,
          });
        }
      }

      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: newMessage,
          files: newFiles3,
        },
        threadConversationList: state.threadConversationList.map((conv) => {
          if (conv.thread.threadId === action.threadId) {
            if (conv.messages.length === 0) {
              return {
                ...conv,
                messages: newMessage,
                files: newFiles3,
              };
            } else {
              return {
                ...conv,
                messages: newMessage,
                files: newFiles3,
              };
            }
          }
          return conv;
        }),
      };
      break;

    /**
     * Filter messages
     * @param {string} action.text
     */
    case FILTER_MESSAGES:
      if (action.text === "") return { ...state, filterResult: [] };
      return {
        ...state,
        filterResult: [
          ...state.threads.filter((thread) => {
            if (thread.threadType === "group") {
              return thread.groupName.toLowerCase().includes(action.text);
            }

            if (thread.threadType === "private") {
              return thread.partnerName.toLowerCase().includes(action.text);
            }
            return false;
          }),
        ],
      };
      break;

    /**
     * Update a Message with an attachment
     * @param {Object} action.message
     * @param {string} action.threadId
     */
    case UPDATE_A_MESSAGE:
      var thread = state.threads.find((c) => c.threadId === action.threadId);
      if (thread) {
        /**
         * Check if conversation is not empty
         * if empty return only the state
         */
        if (state.conversation.thread) {
          /**
           * Return the new conversation and messages since the activethread is the sender
           */
          if (state.conversation.thread.threadId === action.threadId) {
            let newFiles = [...state.conversation.files]; //FIXME When sending message with file need to be updated.

            const attachmentPics = getAttachmentPics(action.message);
            newFiles = newFiles.concat(attachmentPics);

            const newMessages = state.conversation.messages.map((msg) => {
              if (msg.objectId === action.message.objectId) {
                return action.message;
              }
              return msg;
            });
            const newConversation = updateConversation(state.conversation, action.threadId, newMessages, newFiles);

            return {
              ...state,
              conversation: newConversation,
              threadConversationList: state.threadConversationList.map((conv) => {
                if (conv.thread.threadId === action.threadId) {
                  return newConversation;
                }
                return conv;
              }),
              tempConversationList: state.tempConversationList.map((conv) => {
                if (conv.thread.threadId === action.threadId) {
                  return newConversation;
                }
                return conv;
              }),
            };
          } else {
            /**
             * Update either the threadConversationList or tempConversationList
             */
            return {
              ...state,
              tempConversationList: state.tempConversationList.map((conv) => {
                // Update New Message base on tempConversationList Conversation
                if (conv.thread.threadId === action.threadId) {
                  // Update New Message from temConversationList
                  const newMessages = conv.messages.map((msg) => {
                    if (msg.objectId === action.message.objectId) {
                      return action.message;
                    }
                    return msg;
                  });
                  // Update file list
                  // FIXME need to fix add file on the state.files
                  var newFiles = [...conv.files];
                  const newConversation = updateConversation(conv, action.threadId, newMessages, newFiles);
                  return newConversation;
                }
                return conv;
              }),
              threadConversationList: state.threadConversationList.map((conv) => {
                if (conv.thread.threadId === action.threadId) {
                  const newMessages = conv.messages.map((msg) => {
                    if (msg.objectId === action.message.objectId) {
                      return action.message;
                    }
                    return msg;
                  });
                  // FIXME TypeError: Cannot convert undefined or null to object
                  // FIXME need to fix add file on the state.files
                  var newFiles = [...conv.files];
                  const newConversation = updateConversation(conv, action.threadId, newMessages, newFiles);
                  return newConversation;
                }
                return conv;
              }),
            };
          }
        } else {
          // Return state since user doesnt have a conversation at the moment
          return state;
        }
      } else {
        return state;
      }

      return state;
      break;

    /**
     * Receive New Message from pubnub
     * @param {Object} action.message
     * @param {string} action.threadId
     */
    case RECEIVE_MESSAGE:
      var thread = state.threads.find((c) => c.threadId === action.threadId);

      if (thread) {
        /**
         * Check if conversation is not empty
         * if empty return only the state
         */
        if (state.conversation.thread) {
          /**
           * Return the new conversation and messages since the activethread is the sender
           */

          if (state.conversation.thread.threadId === action.threadId) {
            let newFiles = [...state.conversation.files];

            const attachmentPics = getAttachmentPics(action.message);
            newFiles = newFiles.concat(attachmentPics);

            const { picture } = action.message;

            if (picture) {
              newFiles.unshift({
                ...picture,
                conversationId: action.message.objectId,
              });
            }

            const newMessages = updateMessages([...state.conversation.messages], action.message);
            const newConversation = updateConversation(state.conversation, action.threadId, newMessages, newFiles);

            return {
              ...state,
              // For every new message, add 1 to unreadMessagesTotal
              unreadMessagesTotal: state.unreadMessagesTotal + 1,
              conversation: newConversation,
              threadConversationList: state.threadConversationList.map((conv) => {
                if (conv.thread.threadId === action.threadId) {
                  return newConversation;
                }
                return conv;
              }),
              tempConversationList: state.tempConversationList.map((conv) => {
                if (conv.thread.threadId === action.threadId) {
                  return newConversation;
                }
                return conv;
              }),
            };
          } else {
            /**
             * Update either the threadConversationList or tempConversationList
             */
            return {
              ...state,

              // For every new message, add 1 to unreadMessagesTotal
              unreadMessagesTotal: state.unreadMessagesTotal + 1,
              tempConversationList: state.tempConversationList.map((conv) => {
                // Update New Message base on tempConversationList Conversation
                if (conv.thread.threadId === action.threadId) {
                  // Update New Message from temConversationList
                  const newMessages = updateMessages([...conv.messages], action.message);
                  // Update file list
                  // FIXME need to fix add file on the state.files
                  var newFiles = [...conv.files];
                  const newConversation = updateConversation(conv, action.threadId, newMessages, newFiles);
                  return newConversation;
                }
                return conv;
              }),
              threadConversationList: state.threadConversationList.map((conv) => {
                if (conv.thread.threadId === action.threadId) {
                  const newMessages = updateMessages([...conv.messages], action.message);
                  // FIXME TypeError: Cannot convert undefined or null to object
                  // FIXME need to fix add file on the state.files
                  var newFiles = [...conv.files];
                  const newConversation = updateConversation(conv, action.threadId, newMessages, newFiles);
                  return newConversation;
                }
                return conv;
              }),
            };
          }
        }
      }

      return state;
      break;

    /**
     * New message need to update recent
     * @param {Object} action.thread
     */
    case RECENT_UPDATED:
      //check if active thread is thread
      var thread = state.threads.find((c) => c.threadId === action.thread.threadId);
      // var threadUnread = state.threadsUnread.items.find((c) => c.threadId === action.thread.threadId);
      if (thread) {
        let _threads = [...state.threads.filter((item) => item.threadId !== action.thread.threadId)];
        let _threadsUnread = [...state.threadsUnread.items.filter((item) => item.threadId !== action.thread.threadId)];
        let _badgePersonal = state.messageBadges.personal;
        let _badgeAttention = state.messageBadges.attention;
        let _badgeUrgent = state.messageBadges.urgent;

        if (
          state.activeUnreadTab === "all" ||
          (state.activeUnreadTab === "personal" && action.thread.threadType === "private")
        ) {
          let letFilteredPinnedThreads = [action.thread, ..._threads].filter((item) => item.isPinned);
          let letFilteredUnPinnedThreads = [action.thread, ..._threads].filter((item) => !item.isPinned);
          let letFilteredPinnedThreadsUnread = [action.thread, ..._threadsUnread].filter((item) => item.isPinned);
          let letFilteredUnPinnedThreadsUnread = [action.thread, ..._threadsUnread].filter((item) => !item.isPinned);

          _threads = [...letFilteredPinnedThreads, ...letFilteredUnPinnedThreads];
          _threadsUnread = [...letFilteredPinnedThreadsUnread, ...letFilteredUnPinnedThreadsUnread];
        } else if (
          (state.activeUnreadTab === "urgent" &&
            action.thread.threadType === "group" &&
            action.thread.hasUnreadUrgent) ||
          (state.activeUnreadTab === "attention" &&
            action.thread.threadType === "group" &&
            action.thread.hasUnreadAttention)
        ) {
          _threads = [action.thread, ..._threadsUnread];
          _threadsUnread = [action.thread, ..._threadsUnread];
        } else {
          _threads = [..._threads];
          _threadsUnread = [..._threadsUnread];
        }

        if (action.thread.threadType === "group" && action.thread.hasUnreadAttention) {
          _badgeAttention = true;
        }

        if (action.thread.threadType === "group" && action.thread.hasUnreadUrgent) {
          _badgeUrgent = true;
        }

        if (action.thread.threadType === "private") {
          _badgePersonal = true;
        }

        if (state.activeThread.threadId === action.thread.threadId) {
          // no notification sound since the RECEIVE_MESSAGE will handle the sound notif
          return {
            ...state,
            activeThread: action.thread,
            threads: _threads,
            threadUnread: {
              ...state.threadsUnread,
              items: _threadsUnread,
            },
            messageBadges: {
              personal: _badgePersonal,
              attention: _badgeAttention,
              urgent: _badgeUrgent,
            },
          };
        }

        return {
          ...state,
          threads: _threads,
          threadsUnread: {
            ...state.threadsUnread,
            items: _threadsUnread,
          },
          messageBadges: {
            personal: _badgePersonal,
            attention: _badgeAttention,
            urgent: _badgeUrgent,
          },
        };
      } else {
        /**
         * Check if thread is on temp then change the tempConversation.thread with the latest thread.
         */
        var tempConversation = {
          ...state.tempConversationList.find((c) => c.thread.threadId === action.thread.threadId),
        };
        let __threads = [action.thread, ...state.threads];
        let __threadsUnread = [action.thread, ...state.threadsUnread.items];
        if (tempConversation) {
          tempConversation = {
            ...tempConversation,
            thread: action.thread,
          };

          return {
            ...state,
            threads: __threads,
            threadsUnread: {
              ...state.threadsUnread,
              items: __threadsUnread,
            },
            tempConversationList: state.tempConversationList.filter(
              (item) => item.thread.threadId !== action.thread.threadId
            ),
          };
        }

        /**
         * Return only the new threads since the recent thread is not inside of tempConversationList
         */
        return {
          ...state,
          threads: __threads,
        };
      }

      return {
        ...state,
      };
      break;

    /**
     * Recent removed
     * @param {Object} action.threadId
     */
    case "RECENT_REMOVED":
      return {
        ...state,
        threads: threadsReducer.removeRecent(state.threads, action),
        threadsByLabel: threadsFilterReducer(state.threadsByLabel, action),
        threadsUnread: threadsFilterReducer(state.threadsUnread, action),
      };
      break;

    case "RECENT_PINNED":
      return {
        ...state,
        threads: threadsReducer.pinRecent(state.threads, action),
        threadsByLabel: threadsFilterReducer(state.threadsByLabel, action),
        threadsUnread: threadsFilterReducer(state.threadsUnread, action),
      };
      break;

    case "NOTIF_TYPE_UPDATED":
      const updated = state.threads.map((thread) => {
        if (thread.objectId === action.recentId) {
          return {
            ...thread,
            notificationType: action.notificationType,
          };
        }
        return thread;
      });

      return {
        ...state,
        threads: updated,
      };
      break;

    case ARCHIVE_MESSAGE:
      if (state.conversation.thread.threadId === action.message.threadId) {
        return {
          ...state,
          conversation: {
            ...state.conversation,
            messages: [
              ...state.conversation.messages.filter((data) => {
                return data.objectId !== action.message.objectId;
              }),
            ],
          },
          threadConversationList: state.threadConversationList.map((conv) => {
            //NOTE for delayed archive message
            if (conv.thread.threadId === action.message.threadId) {
              return {
                ...conv,
                messages: [
                  ...conv.messages.filter((data) => {
                    //NOTE for delayed archive message
                    return data.objectId !== action.message.objectId;
                  }),
                ],
              };
            }
            return conv;
          }),
          tempConversationList: state.tempConversationList.map((conv) => {
            if (conv.thread.threadId === action.message.threadId) {
              return {
                ...conv,
                messages: [
                  ...conv.messages.filter((data) => {
                    return data.objectId !== action.message.objectId;
                  }),
                ],
              };
            }
            return conv;
          }),
        };
      }
      return {
        ...state,
      };
      break;

    case UPDATE_THREAD_SEEN:
      return {
        ...state,
        threads: threadsReducer.seenRecent(state.threads, action),
        threadsUnread: threadsFilterReducer(state.threadsUnread, action, state),
        threadsByLabel: threadsFilterReducer(state.threadsByLabel, action),
      };
      break;

    case REMOVE_THREAD:
      let newThreads = [...state.threads.filter((item) => item.threadId !== action.threadId)];
      return {
        ...state,
        threads: newThreads,
        threadConversationList: [
          ...state.threadConversationList.filter((conv) => {
            //NOTE for delayed archive message
            return conv.thread.threadId === action.objectId;
          }),
        ],
        tempConversationList: [
          ...state.tempConversationList.filter((conv) => {
            return conv.thread.threadId === action.objectId;
          }),
        ],
        activeThread: {},
        conversation: {},
      };
      break;

    case BEEN_FORWARDED_TO_CCM:
      if (state.activeThread.threadId === action.threadId) {
        const newConversation = {
          ...state.conversation,
          messages: state.conversation.messages.map((message, i) => {
            if (message.objectId === action.objectId) {
              return {
                ...message,
                forwardedToCCM: true,
              };
            }
            return message;
          }),
        };

        return {
          ...state,
          conversation: newConversation,
          threadConversationList: state.threadConversationList.map((conv) => {
            if (conv.thread.threadId === action.threadId) {
              return newConversation;
            }
            return conv;
          }),
        };
      }
      return {
        ...state,
      };
      break;

    case BLOCKED_USER:
      /**
       * Check if conversation is not empty
       * if empty return only the state
       */
      if (state.conversation.thread) {
        /**
         * Return the new conversation and messages since the activethread is the sender
         */
        if (state.conversation.thread.threadId === action.threadId) {
          return {
            ...state,
            conversation: {
              ...state.conversation,
              blocked: { ...action.blocked },
              contactStatus: action.contactStatus,
            },
            threadConversationList: state.threadConversationList.map((conv) => {
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: { ...action.blocked },
                  contactStatus: action.contactStatus,
                };
              }
              return conv;
            }),
            tempConversationList: state.tempConversationList.map((conv) => {
              // Update New Message base on tempConversationList Conversation
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: { ...action.blocked },
                  contactStatus: action.contactStatus,
                };
              }
              return conv;
            }),
          };
        } else {
          /**
           * Update either the threadConversationList or tempConversationList
           */
          return {
            ...state,
            tempConversationList: state.tempConversationList.map((conv) => {
              // Update New Message base on tempConversationList Conversation
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: { ...action.blocked },
                  contactStatus: action.contactStatus,
                };
              }
              return conv;
            }),
            threadConversationList: state.threadConversationList.map((conv) => {
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: { ...action.blocked },
                  contactStatus: action.contactStatus,
                };
              }
              return conv;
            }),
          };
        }
      } else {
        // Return state since user doesnt have a conversation at the moment
        return state;
      }
      break;

    case UPDATE_HAS_BLOCKED_USER:
      /**
       * Check if conversation is not empty
       * if empty return only the state
       */
      if (state.conversation.thread) {
        /**
         * Return the new conversation and messages since the activethread is the sender
         */
        if (state.conversation.thread.threadId === action.threadId) {
          return {
            ...state,
            conversation: {
              ...state.conversation,
              blocked: {
                ...state.conversation.blocked,
                userHasBlocked: action.hasBlock,
              },
            },
            threadConversationList: state.threadConversationList.map((conv) => {
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: {
                    ...conv.blocked,
                    userHasBlocked: action.hasBlock,
                  },
                };
              }
              return conv;
            }),
            tempConversationList: state.tempConversationList.map((conv) => {
              // Update New Message base on tempConversationList Conversation
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: {
                    ...conv.blocked,
                    userHasBlocked: action.hasBlock,
                  },
                };
              }
              return conv;
            }),
          };
        } else {
          /**
           * Update either the threadConversationList or tempConversationList
           */
          return {
            ...state,
            tempConversationList: state.tempConversationList.map((conv) => {
              // Update New Message base on tempConversationList Conversation
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: {
                    ...conv.blocked,
                    userHasBlocked: action.hasBlock,
                  },
                };
              }
              return conv;
            }),
            threadConversationList: state.threadConversationList.map((conv) => {
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: {
                    ...conv.blocked,
                    userHasBlocked: action.hasBlock,
                  },
                };
              }
              return conv;
            }),
          };
        }
      } else {
        // Return state since user doesnt have a conversation at the moment
        return state;
      }
      break;

    case UPDATE_IS_BLOCKED_USER:
      /**
       * Check if conversation is not empty
       * if empty return only the state
       */
      if (state.conversation.thread) {
        /**
         * Return the new conversation and messages since the activethread is the sender
         */
        if (state.conversation.thread.threadId === action.threadId) {
          return {
            ...state,
            conversation: {
              ...state.conversation,
              blocked: {
                ...state.conversation.blocked,
                userIsBlocked: action.isBlock,
              },
            },
            threadConversationList: state.threadConversationList.map((conv) => {
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: {
                    ...conv.blocked,
                    userIsBlocked: action.isBlock,
                  },
                };
              }
              return conv;
            }),
            tempConversationList: state.tempConversationList.map((conv) => {
              // Update New Message base on tempConversationList Conversation
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: {
                    ...conv.blocked,
                    userIsBlocked: action.isBlock,
                  },
                };
              }
              return conv;
            }),
          };
        } else {
          /**
           * Update either the threadConversationList or tempConversationList
           */
          return {
            ...state,
            tempConversationList: state.tempConversationList.map((conv) => {
              // Update New Message base on tempConversationList Conversation
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: {
                    ...conv.blocked,
                    userIsBlocked: action.isBlock,
                  },
                };
              }
              return conv;
            }),
            threadConversationList: state.threadConversationList.map((conv) => {
              if (conv.thread.threadId === action.threadId) {
                return {
                  ...conv,
                  blocked: {
                    ...conv.blocked,
                    userIsBlocked: action.isBlock,
                  },
                };
              }
              return conv;
            }),
          };
        }
      } else {
        // Return state since user doesnt have a conversation at the moment
        return state;
      }
      break;

    case GROUP_NAME_UPDATED:
      return {
        ...state,
        threads: [
          ...state.threads.map((t) => {
            if (t.threadId === action.groupObjectId) {
              return {
                ...t,
                groupName: action.name,
              };
            }
            return t;
          }),
        ],
        activeThread: {
          ...state.activeThread,
          groupName: action.name,
        },
      };
      break;

    case SELECT_UNSELECT_MESSAGES:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: action.messages,
        },
      };
      break;

    case OPEN_CLOSE_CHECKBOXES:
      return {
        ...state,
        showCheckBoxes: action.showCheckBoxes,
        selected_messages: [],
      };
      break;

    case MESSAGES_TO_FORWARD:
      return {
        ...state,
        selected_messages: action.selected_messages,
      };
      break;

    case SET_HAS_CRITICAL:
      return {
        ...state,
        hasCritical: action.hasCritical,
      };
      break;

    case "SORT_THREADS":
      return {
        ...state,
        sortReverse: action.reverse,
      };
      break;

    case "FILTER_THREADS":
      return {
        ...state,
        filterAttention: action.attention,
      };
      break;

    case "SHOW_TAGS_MODAL":
      return {
        ...state,
        tagsModalShown: true,
        recentToTag: action.recent,
      };
      break;

    case "HIDE_TAGS_MODAL":
      return {
        ...state,
        tagsModalShown: false,
      };
      break;

    case "SHOW_REPLY_PREVIEW":
      return {
        ...state,
        replyModalShown: true,
        replyTo: action.replyTo,
      };
      break;

    case "HIDE_REPLY_PREVIEW":
      return {
        ...state,
        replyModalShown: false,
        replyTo: null,
      };
      break;

    case "TAG_CIRCLE":
      return {
        ...state,
        threads: threadsReducer.tagCircle(state.threads, action),
        threadsByLabel: threadsFilterReducer(state.threadsByLabel, action),
        threadsUnread: threadsFilterReducer(state.threadsUnread, action),
        recentToTag: {
          ...state.recentToTag,
          labels: [...(state.recentToTag.labels || []), action.label],
        },
      };
      break;

    case "UNTAG_CIRCLE":
      return {
        ...state,
        threads: threadsReducer.untagCircle(state.threads, action),
        threadsByLabel: threadsFilterReducer(state.threadsByLabel, action),
        threadsUnread: threadsFilterReducer(state.threadsUnread, action),
        recentToTag: {
          ...state.recentToTag,
          labels: (state.recentToTag.labels || []).filter((label) => label.objectId != action.label.objectId),
        },
      };
      break;

    case "TAG_RECENT":
      return {
        ...state,
        threads: threadsReducer.tagRecent(state.threads, action),
        threadsByLabel: threadsFilterReducer(state.threadsByLabel, action),
        threadsUnread: threadsFilterReducer(state.threadsUnread, action),
        recentToTag: {
          ...state.recentToTag,
          labels: [...(state.recentToTag.labels || []), action.label],
        },
      };
      break;

    case "UNTAG_RECENT":
      return {
        ...state,
        threads: threadsReducer.untagRecent(state.threads, action),
        threadsByLabel: threadsFilterReducer(state.threadsByLabel, action),
        threadsUnread: threadsFilterReducer(state.threadsUnread, action),
        recentToTag: {
          ...state.recentToTag,
          labels: (state.recentToTag.labels || []).filter((label) => label.objectId != action.label.objectId),
        },
      };
      break;

    case "SHOW_TAGS_FILTER_MODAL":
      return {
        ...state,
        tagsFilterModalShown: true,
      };
      break;

    case "HIDE_TAGS_FILTER_MODAL":
      return {
        ...state,
        tagsFilterModalShown: false,
      };
      break;

    case "SHOW_TAGS_SELECT":
      return {
        ...state,
        tagsSelectShown: true,
        circleLabelId: action.circleLabelId,
      };
      break;

    case "HIDE_TAGS_SELECT":
      return {
        ...state,
        tagsSelectShown: false,
        circleLabelId: initialState.circleLabelId,
      };
      break;

    case "SAVE_THREADS_BY_LABEL":
      return {
        ...state,
        threadsByLabel: threadsFilterReducer(state.threadsByLabel, action),
      };
      break;

    case "SAVE_THREADS_UNREAD":
      return {
        ...state,
        threadsUnread: threadsFilterReducer(state.threadsUnread, action),
      };
      break;

    case REMOVE_THREAD_UNREAD:
      return {
        ...state,
        threadsUnread: removeThreadUndread(state.threadsUnread, action),
      };
      break;

    case "LINK_TO_LABEL":
      return {
        ...state,
        activeTab: "message",
        activeMessageTab: "ALL",
        activeUnreadTab: "",
        filterAttention: false,
      };
      break;

    case "LINK_TO_IMPORTANT":
      return {
        ...state,
        activeTab: "message",
        activeMessageTab: "ALL",
        activeUnreadTab: "attention",
        filterAttention: false,
        tagsSelectShown: false,
      };
      break;

    case "LINK_TO_URGENT":
      return {
        ...state,
        activeTab: "message",
        activeMessageTab: "ALL",
        activeUnreadTab: "urgent",
        filterAttention: false,
        tagsSelectShown: false,
      };
      break;

    case "LINK_TO_RECENT":
      return {
        ...state,
        activeTab: "message",
        activeMessageTab: "ALL",
        activeUnreadTab: "",
      };
      break;

    case "LINK_TO_INBOX":
      return {
        ...state,
        activeTab: "message",
        activeMessageTab: "ALL",
        activeUnreadTab: "all",
        filterAttention: false,
        tagsSelectShown: false,
      };
      break;

    case "LINK_TO_TASKS":
      return {
        ...state,
        activeTab: "message",
        activeMessageTab: "TASKS",
        selectedSubFilter: action.subFilter || 2,
      };
      break;

    case "LINK_TO_NOTES":
      return {
        ...state,
        activeTab: "conference",
      };
      break;

    case "LINK_TO_TODOS":
      return {
        ...state,
        activeTab: "todos",
      };
      break;

    case SET_PREV_OPENED_THREAD:
      return {
        ...state,
        prevOpenedThread: action.prevOpenedThread,
      };
      break;

    case SET_OPEN_CRITICAL:
      return {
        ...state,
        openCritical: action.value,
      };
      break;

    case SET_ATTENTIONED_MESSAGE:
      return {
        ...state,
        attentionedMessage: action.attentionedMessage,
      };
      break;

    case SET_MESSAGES_SHOWN:
      return {
        ...state,
        read: action.read,
      };
      break;

    case SET_MESSAGES_BADGES:
      return {
        ...state,
        messageBadges: action.messageBadges,
      };
      break;

    case SET_ACTIVITY_LIST:
      if (action.activities.length > 1) {
        return {
          ...state,
          activities: action.activities,
        };
      } else {
        return {
          ...state,
          activities: [...action.activities, ...state.activities],
        };
      }
      break;

    case SET_RICH_TEXT:
      return {
        ...state,
        richText: action.richText,
      };
      break;

    default:
      return state;
  }
}

const threadsFilterReducer = (state, action, rootState = {}) => {
  switch (action.type) {
    case "SAVE_THREADS_BY_LABEL":
      return saveThreads(state, action);
    case "SAVE_THREADS_UNREAD":
      return saveThreads(state, action);
    case "TAG_CIRCLE":
      return addLabels(state, action);
    case "UNTAG_CIRCLE":
      return removeLabels(state, action);
    case "TAG_RECENT":
      return tagRecent(state, action);
    case "UNTAG_RECENT":
      return untagRecent(state, action);
    case "RECENT_PINNED":
      return pinRecent(state, action);
    case "UPDATE_THREAD_SEEN":
      return seenRecent(state, action, rootState);
    case "RECENT_REMOVED":
      return removeRecent(state, action);

    default:
      return state;
  }

  function saveThreads(state, action) {
    const { page = 0, hasMore, fetchSuccess } = action;

    if (!fetchSuccess) {
      return {
        ...state,
        fetchSuccess: false,
      };
    }

    const replaceThreads = page === 0;

    const items = replaceThreads ? action.threads : state.items.concat(action.threads);

    return {
      ...state,
      initialLoaded: true,
      fetchSuccess: true,
      hasMore,
      items,
      page,
    };
  }

  function addLabels(state, action) {
    const updatedItems = state.items.map((recent) => {
      if (recent.threadId === action.circleId) {
        const oldLabels = recent.labels || [];

        return {
          ...recent,
          labels: [...oldLabels, action.label],
        };
      }

      return recent;
    });

    return {
      ...state,
      items: updatedItems,
    };
  }

  function removeLabels(state, action) {
    const updatedItems = state.items.map((recent) => {
      if (recent.threadId === action.circleId) {
        const oldLabels = recent.labels || [];

        return {
          ...recent,
          labels: oldLabels.filter((label) => label.objectId != action.label.objectId),
        };
      }

      return recent;
    });

    return {
      ...state,
      items: updatedItems,
    };
  }

  function tagRecent(state, action) {
    return {
      ...state,
      items: threadsReducer.tagRecent(state.items, action),
    };
  }

  function untagRecent(state, action) {
    return {
      ...state,
      items: threadsReducer.untagRecent(state.items, action),
    };
  }

  function pinRecent(state, action) {
    return {
      ...state,
      items: threadsReducer.pinRecent(state.items, action),
    };
  }

  function seenRecent(state, action, rootState) {
    return {
      ...state,
      items: threadsReducer.seenRecent(state.items, action, rootState),
    };
  }

  function removeRecent(state, action) {
    return {
      ...state,
      items: threadsReducer.removeRecent(state.items, action),
    };
  }
};

const threadsReducer = {
  tagRecent: (threads, action) => {
    return threads.map((t) => {
      if (t.objectId === action.recentId) {
        const oldLabels = t.labels || [];
        return {
          ...t,
          labels: [...oldLabels, action.label],
        };
      }
      return t;
    });
  },
  untagRecent: (threads, action) => {
    return threads.map((t) => {
      if (t.objectId === action.recentId) {
        const oldLabels = t.labels || [];
        return {
          ...t,
          labels: oldLabels.filter((label) => label.objectId != action.label.objectId),
        };
      }
      return t;
    });
  },
  tagCircle: (threads, action) => {
    return threads.map((t) => {
      if (t.threadId === action.circleId) {
        const oldLabels = t.labels || [];
        return {
          ...t,
          labels: [...oldLabels, action.label],
        };
      }
      return t;
    });
  },
  untagCircle: (threads, action) => {
    return threads.map((t) => {
      if (t.threadId === action.circleId) {
        const oldLabels = t.labels || [];

        return {
          ...t,
          labels: oldLabels.filter((label) => label.objectId != action.label.objectId),
        };
      }
      return t;
    });
  },
  pinRecent: (threads, action) => {
    return threads.map((thread) => {
      if (thread.objectId === action.threadId) {
        return {
          ...thread,
          isPinned: action.isPinned,
        };
      }
      return thread;
    });
  },
  seenRecent: (threads, action, rootState = {}) => {
    const { filterAttention } = rootState;

    return threads.map((recent) => {
      if (recent.threadId === action.threadId) {
        return {
          ...recent,
          unreadMessagesCount: 0,
          hasUnreadMessages: false,
          hasUnreadAttention: false,
          hasUnreadUrgent: false,

          /*
						Skip updating hasAttention when filtering by attention
						to prevent the thread from disappearing when opened
					*/
          hasAttention: filterAttention ? recent.hasAttention : false,
          hasUrgent: false,
        };
      }
      return recent;
    });
  },
  removeRecent: (threads, action) => {
    // Filter out the thread with id provided
    return threads.filter((thread) => thread.objectId != action.threadId);
  },
};

function getAttachmentPics(message) {
  const { attachments = [] } = message;

  return attachments
    .filter((a) => {
      return (a.fileType || "").includes("image") || (a.fileType || "").includes("pdf");
    })
    .map((a) => {
      return {
        ...a.file,
        conversationId: message.objectId,
        thumbUrl: a.thumbNail ? a.thumbNail.url : "",
      };
    });
}

function removeThreadUndread(threads, message) {
  var objectId = message.threads.objectId;
  threads.items.map((msg, index) => {
    if (message.threads.objectId === msg.objectId) {
      threads.items.splice(index, 1);
    }
  });
  return threads;
}
