import { parseAction } from "./REST";
import moment from "moment";
import { UPDATE_THREAD_SEEN } from "./types";
import { fetchUnreadCount, fetchUnreadBadges } from "./ThreadAction";
import config from "../config";

export const seenMessage = (threadId, objectId, seenDate, type) => (dispatch) => {
  let seenCloudFunction;
  let data = {};
  if (type === "sessionMessage") {
    seenCloudFunction = "setLastSessionMessageReadDate";
    data.subgroupSessionId = objectId;
    if (typeof seenDate == "undefined") {
      seenDate = new Date();
    } else {
      seenDate = new Date(seenDate);
    }
    data.timestamp = seenDate.getTime();
  } else {
    seenCloudFunction = "setLastMessageReadDate"; // "setLastMessageRead";
    // if (seenDate === undefined) {
      data.timestamp = new Date().getTime();
    // } else {
    //   seenDate = new Date(seenDate);
    //   data.timestamp = seenDate.getTime();
    // }
    data.threadId = threadId;
  }

  return parseAction("post", config.BASE_URL + "/parse/functions/" + seenCloudFunction, data)
    .then((req) => {
      dispatch({
        type: UPDATE_THREAD_SEEN,
        threadId: threadId,
      });

      fetchUnreadCount()(dispatch);
      fetchUnreadBadges()(dispatch);
    })
    .catch((err) => {
      console.dir(err);
    });
};

export const markThreadAsRead = (threadId) => (dispatch) => {
  const data = {
    threadIds: [threadId],
  };

  const headers = {
    "Content-Type": "application/json",
  };

  return parseAction("post", config.BASE_URL + "/parse/functions/markThreadsAsRead", data, headers)
    .then((req) => {
      dispatch({
        type: UPDATE_THREAD_SEEN,
        threadId: threadId,
      });

      fetchUnreadCount()(dispatch);
    })
    .catch((error) => {
      // NOTE Don't do anything
      return Promise.reject(error);
    });
};

export const deleteRecent = (recentId) => (dispatch) => {
  const data = {
    recentObjectIds: [recentId],
  };

  const headers = {
    "Content-Type": "application/json",
  };

  parseAction("post", config.BASE_URL + "/parse/functions/deleteRecents", data, headers)
    .then((req) => {
      dispatch({
        type: "RECENT_REMOVED",
        threadId: recentId,
      });

      fetchUnreadCount()(dispatch);
    })
    .catch((error) => {
      // NOTE Don't do anything
    });
};

export const pinRecent = (recentId, isPinned = true) => (dispatch) => {
  const data = {
    objectId: recentId,
    isPinned,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  dispatch({
    type: "RECENT_PINNED",
    threadId: recentId,
    isPinned,
  });

  parseAction("post", config.BASE_URL + "/parse/functions/pinRecent", data, headers);
};
