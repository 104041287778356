import React from "react";
import moment from "moment";
import defaultImage from "../../../assets/images/default.png";

const Member = ({ user = {}, isSelected, onSelect, onRemove }) => {
  const name = user.firstName + " " + user.lastName;
  const imgUrl = user.picture ? user.picture.url : defaultImage;
  const lastDateActive = (user.lastDateActive || {}).iso;
  const unavailabilityReason = user.unavailabilityReason || "";

  const className = isSelected
    ? "border-item layout-item list-group-item-action bg-warning text-white"
    : "border-item layout-item list-group-item-action";

  const handleClick = () => {
    return isSelected ? onRemove(user.objectId) : onSelect(user);
  };

  const activeStyle = {
    color: "#3dc81c",
    fontSize: "14px",
    marginLeft: "auto",
  };

  return (
    <div
      className="border-item participant-item"
      style={{
        minHeight: "62px",
        opacity: user.isActiveNow ? 1 : 0.6,
        background: user.isActiveNow ? "#FFFFFF" : "#FFFFFF",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <div class="round">
        <input
          type="checkbox"
          checked={isSelected}
          id="checkbox"
          disabled={true}
        />
        <label for="checkbox"></label>
      </div>
      <img
        src={imgUrl}
        alt=""
        className="profile-image rounded-circle mr-2"
        style={{ width: "30px", height: "30px" }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className="name m-0">
          {name} 
          {unavailabilityReason ? " (" + unavailabilityReason + ")" : ""}
        </span>
        {lastDateActive && (
          <span
            className="name"
            style={{ fontSize: "12px", opacity: 0.4 }}
          >{`${moment(lastDateActive).calendar()}`}</span>
        )}
      </div>
      {user.isActiveNow && <span style={activeStyle}>Online Now</span>}
      <br />
    </div>
  );
};

export default Member;
