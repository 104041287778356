import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import Parse from 'parse'
import moment from 'moment'
import CreateCorporate from './CreateCorporate'
import imageCompression from 'browser-image-compression';
import UserItem from './UserItem';
import PatientItem from './PatientItem';
import { 
    fetchOrganization,
    createOrganization, 
    updateOrganizationName, 
    updateOrganizationProfile, 
    addMembersToOrganization, 
    addGuestsToOrganization, 
    removeMembersFromOrganization, 
    removeGuestsFromOrganization,
    addAdminsToOrganization, 
    removeAdminsFromOrganization, 
    fetchOrganizationGroups, 
    addGroupsToOrganization, 
    removeGroupsFromOrganization, 
    addOrgEmail, 
    removeOrgEmail,
    setOrganizationRole,
} from '../../../actions/SessionAction'
import { createThread } from '../../../actions/ThreadAction';
import { fetchSubscriber } from '../../../actions/RevenueCatAction';
import AddMemberModal from './AddMemberModal'
import AddGuestModal from '../../Conversation/Setting/AddGuestModal'
import LoadingSpinner from '../../../components/Conversation/Bubble/LoadingSpinner'
import './ProfileSetting.css'
import _ from 'lodash'
import OrgEmails from './OrgEmails';
import Notify from '../../Common/Notify';
import GuestItem from './GuestItem';

const $ = window.$;
const defaultImage = require("../../../assets/images/group-default.png");

export class CorporateContainer extends Component {
    constructor() {
        super();
        this.state = {
            isUploading: false,
            willEditName: false,
            nameIsUpdating: false,
            failedToUpdateName: false,
            willUpload: false,
            isUploading: false,
            profilePictureURL: defaultImage,
            openAddMemberModal: false,
            openCreatePatientModal: false,
            isFetchingGroups: true,
            isFetchingGroupsStatus: "Error",
            isSearching: false,
            typeValue: "",
            errorAddMember: false,
            errorAddMemberMessage: "",
            newOrgEmail: '',
            addingOrgEmail: false,
        }

        this.fileHandleChange = this.fileHandleChange.bind(this);
        this.removeMember = this.removeMember.bind(this);
        this.removeGuest = this.removeGuest.bind(this);
        this.assignAdmin = this.assignAdmin.bind(this);
        this.removeAdmin = this.removeAdmin.bind(this);
        this.updateCorporateName = this.updateCorporateName.bind(this);
        this._renderLayout = this._renderLayout.bind(this);
        this.readURL = this.readURL.bind(this);
        this.openAddMemberModal = this.openAddMemberModal.bind(this);
        this.openCreatePatientModal = this.openCreatePatientModal.bind(this);
        this._renderNoSubscription = this._renderNoSubscription.bind(this);
        this.openAddGuestModal = this.openAddGuestModal.bind(this);
    }

    componentDidMount() {
        this.props.fetchOrganization();
        this.props.fetchSubscriber();
    }


    getSnapshotBeforeUpdate(prevProps, prevState) {
        $('[data-toggle="tooltip"]').tooltip();
        if (this.props.organization) {
            if (this.props.organization.image) {
                if (this.props.organization.image.url !== this.state.profilePictureURL && !this.state.willUpload) {
                    this.setState({
                        profilePictureURL: this.props.organization.image.url
                    })
                }
            }
        }

        return null;
    }

    fetchOrgGroups() {
        if (this.props.organizationFetchStatus === "SUCCESS" && this.props.organization.objectId) {
            this.props.fetchOrganizationGroups(this.props.organization.owner.objectId).then(() => {
                this.setState({
                    isFetchingGroups: false
                })
            }).catch(() => {
                this.setState({
                    isFetchingGroups: false,
                    isFetchingGroupsStatus: "Error"
                })
            })
        }
    }

    componentDidUpdate() {

    }

    openFileImage() {
        $(this.refs.corporateImage).trigger('click');
    }

    async fileHandleChange(e) {
        const imageFile = e.target.files[0];
        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            const URLFile = await imageCompression.getDataUrlFromFile(compressedFile);
            this.setState({
                file: URLFile,
                willUpload: true
            })

            this.readURL(compressedFile);
        } catch (error) {
            console.log(error);
        }
    }


    assignAdmin(member) {
        this.props.addAdminsToOrganization(member.user);
    }

    removeMember(objectId) {
        this.props.removeMembersFromOrganization(objectId);
    }

    removeGuest(objectId) {
        this.props.removeGuestsFromOrganization(objectId);
    }

    removeAdmin(member) {
        this.props.removeAdminsFromOrganization(member.user.objectId);
    }

    willEditName() {
        this.setState({
            willEditName: true,
            failedToUpdateName: false
        })
    }

    updateCorporateName(e) {
        e.preventDefault();
        let corporateName = this.refs.corporateName.value;


        if (corporateName === "" || this.props.organization.name === corporateName) {
            this.setState({
                nameIsUpdating: false,
                willEditName: false,
                failedToUpdateName: false
            })
            return;
        }

        this.setState({
            nameIsUpdating: true,
        })

        let self = this

        this.props.updateOrganizationName(corporateName, this.props.organization.objectId).then(() => {
            self.setState({
                nameIsUpdating: false,
                willEditName: false,
                failedToUpdateName: false,
            })
        }).catch((error) => {
            self.setState({
                failedToUpdateName: true,
                nameIsUpdating: false,
                willEditName: false,
            })
        })
    }

    readURL(file) {
        if (file) {
            var reader = new FileReader();

            reader.onload = function (e) {
                this.setState({
                    profilePictureURL: e.target.result
                });
            }.bind(this);

            reader.readAsDataURL(file);
        }
    }


    uploadPicture() {
        if (!this.state.willUpload) return;


        this.setState({
            isUploading: true
        })

        let file = this.state.file;
        this.props.updateOrganizationProfile(file, this.props.organization.objectId).then(() => {
            this.setState({
                isUploading: false,
                willUpload: false
            })
        });
    }

    openAddMemberModal() {
        this.setState({
            openAddMemberModal: true,
            errorAddMember: false
        })
    }

    openAddGuestModal() {
        this.setState({
            openAddGuestModal: true,
            errorAddMember: false
        })
    }

    closeAddMemberModal() {
        this.setState({
            openAddMemberModal: false
        })
    }

    closeAddGuestModal() {
        this.setState({
            openAddGuestModal: false
        })
    }

    openCreatePatientModal() {
        this.setState({
            openCreatePatientModal: true
        })
    }

    closeAddPatientModal() {
        this.setState({
            openCreatePatientModal: false
        })
    }

    addMember(user) {

        this.props.addMembersToOrganization([user])
        .then(() => {
            this.closeAddMemberModal();
        })
        .catch((message) => {
            if (message === "One or more users cannot be added because they are already members of another organization.") {
                Notify.errorWithTitle(
                    'Failed to add member',
                    message
                );
            }
            else if (message === "One or more users cannot be added because they are not using a corporate email.") {
                Notify.errorWithTitle(
                    'Failed to add member',
                    message
                );
            }
            else {
                Notify.error("We're having problem right now, please try again later.");
            }
        })
    }

    addGuest(toBeInvited) {
        this.closeAddGuestModal();

        this.props.addGuestsToOrganization(toBeInvited)
        .catch((message) => {
            Notify.error("We're having problem right now, please try again later.");
        })
    }

    isTyping(e) {
        let isSearching = true;
        if (this.input.value === "") isSearching = false;
        this.setState({
            typeValue: this.input.value,
            isSearching: isSearching
        })
    }

    addOrgEmail(e) {
        e.preventDefault();

        this.setState({ addingOrgEmail: true });

        this.props.addOrgEmail(
            this.state.newOrgEmail,
            this.props.organization.objectId,
        )
            .then(res => {
                this.setState({ addingOrgEmail: false });
                this.setState({ newOrgEmail: '' });
            })
            .catch(err => {
                this.setState({ addingOrgEmail: false });
            });
    }

    removeOrgEmail(e, email) {
        e.preventDefault();

        this.props.removeOrgEmail(
            email,
            this.props.organization.objectId,
        );
    }

    handleOrgEmailChange(e) {
        this.setState({ newOrgEmail: e.target.value });
    }

    _renderLayout() {
        if (this.props.organizationFetchStatus === "SUCCESS") {
            if (this.props.organization.objectId) {
                const organizationName = this.props.organization.name;
                const currentUser = Parse.User.current();
                let isOwner = this.props.organization.owner.objectId === currentUser.id;

                let tempAdminstrators = this.props.organization.administrators || [];
                let tempOwner = this.props.organization.administrators || [];
                let tempMembers = this.props.organization.members || [];
                let tempPatients = this.props.organizationGroups || [];
                let tempGuests = this.props.organization.guests || [];

                const { owner } = this.props.organization;

                // Filter out owner from members and admins
                tempAdminstrators = tempAdminstrators.filter(user => {
                    return user.objectId != owner.objectId;
                });

                tempOwner = (this.props.organization.administrators || []).filter(user => {
                    return user.objectId == owner.objectId;
                });

                tempMembers = tempMembers.filter(user => {
                    return user.objectId != owner.objectId;
                });

                if (this.state.isSearching) {

                    const { typeValue = '' } = this.state;

                    tempAdminstrators = tempAdminstrators.filter((admin) => {

                        return admin.displayNameLower.includes(typeValue.toLocaleLowerCase());
                    })

                    tempMembers = tempMembers.filter((member) => {
                        return member.displayNameLower.includes(typeValue.toLocaleLowerCase());
                    })

                    tempPatients = tempPatients.filter((patient) => {
                        return patient.nameLower.includes(typeValue.toLocaleLowerCase());
                    })

                    tempGuests = tempGuests.filter((member) => {
                        return member.displayNameLower.includes(typeValue.toLocaleLowerCase());
                    })
                }

                const tempAdminstratorsSorted = _.sortBy(tempAdminstrators, 'displayNameLower');

                const Admins = tempAdminstratorsSorted.map((user, i) => {
                    if (isOwner) {
                        if (user.objectId === currentUser.id) return null;
                    }

                    const admin = {
                        "user": user,
                        "isAdministrator": true,
                        "orgId": this.props.organization.objectId,
                    }
                    return <UserItem setRole={(data) => this.props.setOrganizationRole({data, organizations: this.props.organization, strRole: 'administrators'})} key={"admin_" + i} member={admin} isCreator={isOwner} isAdmin={isOwner} removeMember={this.removeMember} removeAdmin={this.removeAdmin} isSuperAdmin={!this.state.willEditName} />
                })

                const Owner = tempOwner.map((user, i) => {
                    const admin = {
                        "user": user,
                        "isAdministrator": true,
                        "orgId": this.props.organization.objectId,
                    }
                    return <UserItem setRole={(data) => this.props.setOrganizationRole({data, organizations: this.props.organization, strRole: 'administrators'})} key={"admin_" + i} member={admin} isCreator={isOwner} isAdmin={isOwner} removeMember={this.removeMember} removeAdmin={this.removeAdmin} isSuperAdmin={!this.state.willEditName} />
                });

                const tempMembersSorted = _.sortBy(tempMembers, 'displayNameLower');

                const Members = tempMembersSorted.map((user, i) => {
                    if (isOwner) {
                        if (user.objectId === currentUser.id) return null;
                    }
                    const member = {
                        "user": user,
                        "orgId": this.props.organization.objectId,
                    }
                    return <UserItem setRole={(data) => this.props.setOrganizationRole({data, organizations: this.props.organization, strRole: 'members'})} key={"member_" + i} member={member} isCreator={isOwner} isAdmin={false} removeMember={this.removeMember} assignAdmin={this.assignAdmin} />
                })

                const tempGuestsSorted = _.sortBy(tempGuests, 'displayNameLower');

                const Guests = tempGuestsSorted.map((user, i) => {
                    if (isOwner) {
                        if (user.objectId === currentUser.id) return null;
                    }
                    const member = {
                        "user": user,
                    }
                    return <GuestItem key={"member_" + i} member={member} isCreator={isOwner} isAdmin={false} removeGuest={this.removeGuest} assignAdmin={this.assignAdmin} isGuest={true} />
                })

                const Patients = tempPatients.map((patient, i) => {
                    return <PatientItem key={"patient_" + i} patient={patient} createThread={this.props.createThread} history={this.props.history} addGroupsToOrganization={this.props.addGroupsToOrganization} removeGroupsFromOrganization={this.props.removeGroupsFromOrganization} />
                })

                let memberIsEmpty = false;
                if (this.props.organization.members.length === 1) {
                    if (this.props.organization.members[0].objectId === Parse.User.current().id) {
                        memberIsEmpty = true;
                    }
                } else if (this.props.organization.members.length === 0) {
                    memberIsEmpty = true;
                }

                let fullWidth = isOwner ? {} : { width: "100%" };
                let defaultPlaceholder = isOwner ? "Search members or patient group" : "Search members";

                const {organization} = this.props;
                const {guests = []} = organization;

                return (
                    <>  
                        <h2 
                            className="hc-primary-text"
                            style={{
                                paddingTop: '10px',
                                paddingBottom: '10px',
                            }} >Corporate Organization</h2>
                        <div className="Tab-Search-Container" style={{ margin: "10px 0" }}>
                            <i className="fas fa-search"></i>
                            <input onChange={this.isTyping.bind(this)} id="search" placeholder={defaultPlaceholder} ref={i => this.input = i} type="search" value={this.props.typeValue} />
                        </div>
                        <ul className="nav nav-tabs" id="myTab" role="tablist" >
                            <li className="nav-item" style={fullWidth}>
                                <a className="nav-link active" id="user-tab" data-toggle="tab" href="#user" role="tab" aria-controls="user" aria-selected="true">USERS</a>
                            </li>
                            {isOwner &&
                                <li className="nav-item" style={{ position: "relative" }}>
                                    <a onClick={this.fetchOrgGroups.bind(this)} className="nav-link" id="patient-tab" data-toggle="tab" href="#patient" role="tab" aria-controls="patient" aria-selected="false">PATIENT HUBS</a>
                                </li>
                            }
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab">
                                <div style={{ paddingLeft: "85px", position: "relative", display: "block", marginTop: "25px" }}>
                                    <img style={{ position: "absolute", left: "0", border: "3px solid white", width: "75px", height: "75px", borderRadius: "30px" }} src={this.state.profilePictureURL} />
                                    {this.state.isUploading ?
                                        <span style={{ textAlign: "center", color: "rgb(74, 173, 82)", position: "absolute", left: "-10px", bottom: "-20px", fontSize: "14px" }}><i className="fas fa-circle-notch fa-spin" style={{ marginRight: "5px" }}></i>Uploading...</span>
                                        :
                                        <>
                                            {isOwner &&
                                                <div className="Upload-Button-Container">
                                                    <i onClick={this.openFileImage.bind(this)} style={{ fontSize: "15px", top: "32px", left: "0" }} className="fas fa-camera upload-button"></i>
                                                    <input ref="corporateImage" className="file-upload" type="file" accept="image/jpg,image/png,image/jpeg" onChange={this.fileHandleChange} />
                                                </div>
                                            }
                                        </>
                                    }
                                    {this.state.willUpload && !this.state.isUploading && <button onClick={this.uploadPicture.bind(this)} className="Confirm-Button" style={{ color: "#2194f3", marginTop: "10px", position: "absolute", left: "-15px", bottom: "-25px", fontSize: "14px" }}>Upload Picture</button>}
                                    {this.state.failedToUpdateName && <span className="hc-red-text" style={{ fontSize: "13px", fontWeight: "500", position: "absolute", top: "-20px" }}>Please check you internet connection or try again later.</span>}
                                    <div className="Form-Container" style={{ margin: "0" }}>
                                        <form onSubmit={this.updateCorporateName} style={{ position: "relative" }}>
                                            <div className="form-group">
                                                {!this.state.willEditName ?
                                                    <>
                                                        <p className="Corporate-Name hc-primary-text">{organizationName} {isOwner && <i onClick={this.willEditName.bind(this)} className="fas fa-pencil-alt" style={{ color: "rgb(74, 173, 82)", position: "absolute", right: "0", cursor: "pointer" }}></i>}</p>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="d-flex">
                                                            <div className="card mr-2">
                                                                <input ref="corporateName" type="text" id="corporateName" placeholder="Enter your organization name" defaultValue={organizationName} disabled={this.state.nameIsUpdating} />
                                                                <i className="fas fa-city hc-primary-text"></i>
                                                            </div>
                                                            {this.state.nameIsUpdating ?
                                                                <i className="fas fa-circle-notch fa-spin" style={{ color: "rgb(74, 173, 82)", position: "relative", left: "-11px", top: "7px" }}></i>
                                                                :
                                                                <button ref="submit" type="submit" className="btn hc-bg-primary text-white">
                                                                    <i className="fas fa-fw fa-check"></i>
                                                                </button>

                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className="form-group">
                                                {!this.state.willEditName ?
                                                    <OrgEmails emails={this.props.organization.emails} /> :
                                                    <>
                                                        <div className="d-flex">
                                                            <div className="card mr-2">
                                                                <input onChange={this.handleOrgEmailChange.bind(this)} value={this.state.newOrgEmail} type="text" id="emails" placeholder="Enter new corporate email" disabled={this.state.nameIsUpdating} />
                                                                <i className="fas fa-envelope hc-primary-text"></i>
                                                            </div>
                                                            {this.state.addingOrgEmail ?
                                                                <button className="btn" disabled>
                                                                    <i className="fad fa-fw fa-spinner-third fa-spin"></i>
                                                                </button>
                                                                :
                                                                <button onClick={this.addOrgEmail.bind(this)} type="button" className="btn btn-light text-grey">
                                                                    <i className="fas fa-fw fa-plus"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                        <ul className="my-2">
                                                            {this.props.organization.emails.map(email =>
                                                                <li>
                                                                    <span className="mr-2">{email}</span>
                                                                    {this.props.organization.emails.length > 1 ?
                                                                        <button onClick={(e) => this.removeOrgEmail(e, email)} type="button" className="btn btn-link text-danger">
                                                                            <i className="fa fa-times"></i>
                                                                        </button>
                                                                        : ''
                                                                    }
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                    {!this.state.willEditName &&
                                        <>
                                            <i className="fas fa-user hc-primary-text mr-1"></i>
                                            <span className="Corporate-Owner" style={{ position: "relative" }}>
                                                {this.props.organization.owner.displayName}
                                            </span>
                                        </>
                                    }
                                    {!this.state.willEditName &&
                                        <div>
                                            <i className="fas fa-lock text-warning mr-1"></i>
                                            <span className="text-grey">
                                                Super Admin
                                        </span>
                                        </div>
                                    }
                                </div>
                                {isOwner && (
                                    <div className="Corporate-Content">
                                        <h5 style={{ fontSize: "1rem", color: "rgba(0, 0, 0, 0.59)" }}>OWNER</h5>
                                        <div className="list-group">
                                            {Owner}
                                        </div>
                                    </div>
                                )}
                                <div className="Corporate-Content">
                                    <h5 style={{ fontSize: "1rem", color: "rgba(0, 0, 0, 0.59)" }}>ADMINS</h5>
                                    <div className="list-group">
                                        {Admins}
                                    </div>
                                </div>
                                <div className="Corporate-Content">
                                    <h5 style={{ fontSize: "1rem", color: "rgba(0, 0, 0, 0.59)" }}>MEMBERS</h5>
                                    {
                                        this.state.errorAddMember && <p style={{ color: "red", fontSize: "14px", textAlign: "center", marginTop: "25px" }}>{this.state.errorAddMemberMessage}</p>
                                    }
                                    {memberIsEmpty &&
                                        <>

                                            <p style={{ color: "#0000009e", fontSize: "14px", textAlign: "center", marginTop: "25px" }}>Your member is empty, start by adding your contact to your organization.</p>
                                            <button onClick={this.openAddMemberModal} className="btn btn-hubchart" style={{ width: "131px", fontSize: "16px", margin: "0 auto", display: "block" }}>Add Member</button>
                                        </>
                                    }
                                    <div className="list-group">
                                        {!memberIsEmpty && !this.state.isSearching && isOwner && <a href="#!;" onClick={this.openAddMemberModal} className="list-group-item list-group-item-action flex-column align-items-start" style={{ border: "0px solid white", paddingLeft: "60px", minHeight: "45px", height: "45px" }}>
                                            <i className="fas fa-plus-circle hc-primary-text" style={{ position: "absolute", left: "15px", fontSize: "31px" }}></i>
                                            <p className="name hc-primary-text" style={{ marginTop: "4px" }}>Add Member</p>
                                        </a>
                                        }
                                        {Members.length === 0 && this.state.isSearching &&
                                            <>
                                                <p style={{ color: "#0000009e", fontSize: "14px", textAlign: "center", marginTop: "25px" }}>There is no member with name {this.state.typeValue}</p>
                                            </>}
                                        {Members}
                                    </div>
                                </div>
                                <div className="Corporate-Content">
                                    <h5 style={{ fontSize: "1rem", color: "rgba(0, 0, 0, 0.59)" }}>GUESTS</h5>
                                    {!guests.length ?
                                        <>
                                            <p style={{ color: "#0000009e", fontSize: "14px", textAlign: "center", marginTop: "25px" }}>No corporate guests.</p>
                                            <button onClick={this.openAddGuestModal} className="btn btn-hubchart" style={{ width: "131px", fontSize: "16px", margin: "0 auto", display: "block" }}>Add Guest</button>
                                        </>
                                        : ''
                                    }
                                    <div className="list-group">
                                        {guests.length && !this.state.isSearching && isOwner && <a href="#!;" onClick={this.openAddGuestModal} className="list-group-item list-group-item-action flex-column align-items-start" style={{ border: "0px solid white", paddingLeft: "60px", minHeight: "45px", height: "45px" }}>
                                            <i className="fas fa-plus-circle hc-primary-text" style={{ position: "absolute", left: "15px", fontSize: "31px" }}></i>
                                            <p className="name hc-primary-text" style={{ marginTop: "4px" }}>Add Guest</p>
                                        </a>
                                        }
                                        {Guests.length === 0 && this.state.isSearching &&
                                            <>
                                                <p style={{ color: "#0000009e", fontSize: "14px", textAlign: "center", marginTop: "25px" }}>There is no guest with name {this.state.typeValue}</p>
                                            </>}
                                        {Guests}
                                    </div>
                                </div>
                            </div>
                            {isOwner && <div className="tab-pane fade" id="patient" role="tabpanel" aria-labelledby="patient-tab">
                                <div style={{ position: "relative" }}>
                                    <i className="fas fa-info-circle custom-tooltip" style={{ position: "absolute", right: "8px", top: "-30px", color: "#0000006b" }}>
                                        <span className="custom-tooltiptext" style={{ minWidth: "400px" }}>
                                            <p style={{ margin: "0" }}><i className="fas fa-star Star-Yellow"></i> indicate that the patient is already on your organization</p>
                                            <p style={{ margin: "0" }}>You can click this icon <i className="fas fa-star"></i> to add the patient to your organization</p>
                                        </span>
                                    </i>
                                </div>
                                <div className="list-group" style={{ marginTop: "5px" }}>
                                    {this.state.isFetchingGroups && <a key="group-load-more" style={{ border: "0px solid white", minHeight: "50px", height: "50px", borderRadius: "0px", padding: "0" }} className="list-group-item list-group-item-action flex-column align-items-start">
                                        <LoadingSpinner top={"0"} />
                                    </a>}

                                    {Patients.length === 0 && !this.state.isFetchingGroups && !this.state.isSearching &&
                                        <>
                                            <p style={{ color: "#0000009e", fontSize: "14px", textAlign: "center", marginTop: "25px" }}>Your patient hub is empty, start by creating a patient group.</p>
                                            <button onClick={this.openCreatePatientModal} className="btn btn-hubchart" style={{ width: "131px", fontSize: "16px", margin: "0 auto", display: "block" }}>Create Patient</button>
                                        </>}

                                    {Patients.length === 0 && !this.state.isFetchingGroups && this.state.isSearching &&
                                        <>
                                            <p style={{ color: "#0000009e", fontSize: "14px", textAlign: "center", marginTop: "25px" }}>There is no patient with name {this.state.typeValue}</p>
                                        </>}
                                    {Patients}
                                </div>

                            </div>}
                        </div>
                        <ToastContainer />
                    </>
                )
            } else {
                const revenueCat = this.props.revenueCatSubscriber
                let showCreate = false;
                if (revenueCat.subscriber) {
                    if (revenueCat.subscriber.entitlements.app_corporate) {
                        let now = new Date();
                        if (moment(revenueCat.subscriber.entitlements.app_corporate.expires_date).isBefore(now)) {
                            return (
                                <>
                                    <h4 style={{ textAlign: "center" }} className="hc-primary-text">Corporate Account Subscription</h4>
                                    <p style={{ textAlign: "center", marginTop: "15px" }}>To continue using corporate you need to subscribe again to Corporate Subscription.</p>
                                </>
                            )
                        } else {
                            showCreate = true;
                        }
                    } else {
                        return (
                            <>
                                <h4 style={{ textAlign: "center" }} className="hc-primary-text">Corporate Account Subscription</h4>
                                <p style={{ textAlign: "center", marginTop: "15px" }}>You need to subscribe to Corporate Subscription to use this feature.</p>
                            </>
                        )
                    }
                } else {
                    return (
                        <>
                            <h4 style={{ textAlign: "center" }} className="hc-primary-text">Corporate Account Subscription</h4>
                            <p style={{ textAlign: "center", marginTop: "15px" }}>We are having problem right now, try gain later or you can refresh the web.</p>
                        </>
                    )
                }

                if (showCreate) {
                    return (
                        <CreateCorporate createOrganization={this.props.createOrganization} />
                    )
                }
            }
        }
    }

    _renderNoSubscription(message) {
        return (
            <>
                <h4 style={{ textAlign: "center" }} className="hc-primary-text">Corporate Account Subscription</h4>
                <p style={{ textAlign: "center", marginTop: "15px" }}>{message}</p>
            </>
        )
    }

    render() {

        if (this.props.organization) {
            if (this.props.organization.image) {
                if (this.props.organization.image.url !== this.state.profilePictureURL && !this.state.willUpload) {
                    this.setState({
                        profilePictureURL: this.props.organization.image.url
                    })
                }
            }
        }

        return (
            <div 
                style={{ 
                    overflowY: 'auto',
                    background: '#e5e5e5',
                }}>
                <div 
                    className="container"
                    style={{
                        background: '#FFFFFF',
                    }}>
                    {this._renderLayout()}
                    <AddMemberModal isOpen={this.state.openAddMemberModal} onClose={this.closeAddMemberModal.bind(this)} onSelect={this.addMember.bind(this)}/>
                    {this.state.openAddGuestModal && <AddGuestModal from="organization" members={this.props.organization.guests} inviteGuest={this.addGuest.bind(this)} isOpen={this.state.openAddGuestModal} modalClose={this.closeAddGuestModal.bind(this)} />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    organization: state.session.organization,
    organizationFetchStatus: state.session.organizationFetchStatus,
    organizationGroups: state.session.organizationGroups,
    revenueCatSubscriber: state.session.revenueCatSubscriber
})
export default connect(mapStateToProps, {
    fetchOrganization,
    createOrganization, 
    updateOrganizationName, 
    updateOrganizationProfile, 
    addMembersToOrganization,
    addGuestsToOrganization, 
    removeMembersFromOrganization, 
    removeGuestsFromOrganization,
    addAdminsToOrganization, 
    removeAdminsFromOrganization, 
    fetchOrganizationGroups,
    addGroupsToOrganization,
    removeGroupsFromOrganization,
    setOrganizationRole,
    createThread,
    addOrgEmail,
    removeOrgEmail,
    fetchSubscriber,
})(CorporateContainer);
