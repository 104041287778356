import React, { Component } from "react";
import Parse from "parse";
import { connect } from "react-redux";
import "./App.css";
import { withRouter } from "react-router";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import Main from "./Pages/Main";
import Login from "./Pages/Login/Login";
import Signup from "./Pages/Signup/Signup";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import VideoCallContainer from "./Pages/VideoCall/VideoCallContainer";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import IdleTimer from "react-idle-timer";
import IdleModal from "./Pages/IdleModal";
import EmailContainer from "./Pages/Login/EmailContainer";
import VideoComponent from "./Pages/ConferenceCall2";
import PrivateCallWindow from "./Pages/PrivateCall/PrivateCallWindow";
import util from "../helper/util";
import FilesWindow from "./Pages/FilesWindow/FilesWindow";
import RedirectConference from "./RedirectConference";
import ContactProfile from "./Pages/ContactProfile";
import CorporateContainer from "./TabList/ProfileSetting/CorporateContainer";
import CorporateSetting from "./TabList/ProfileSetting/CorporateSetting";
import CorporateSubs from "./TabList/ProfileSetting/CorporateSubs";
import ProgressNotes from "./Pages/ProgressNotes";
import MedicationView from "./Pages/PatientTabs/MedicationView";
import PatientOrdersView from "./Pages/PatientTabs/PatientOrdersView";
import PatientDiagnosesView from "./Pages/PatientTabs/PatientDiagnosesView";
import PatientProblemsView from "./Pages/PatientTabs/PatientProblemsView";
import GroupedListsView from "./Pages/PatientTabs/GroupedListsView";
import ActionsView from "./Pages/PatientTabs/ActionsView";
import ProgressNoteView from "./Pages/ProgressNoteView";
import PrescriptionPDF from "./Conversation/Setting/PrescriptionPDF";
import OrdersPDF from "./Conversation/Setting/OrdersPDF";
import Facesheet from "./Conversation/Setting/Facesheet";
import { setIdleModalState } from "../actions/SessionAction";
import { setActiveTab, fetchThreads, setActiveThread } from "../actions/ThreadAction";
import CriticalComponent from "../components/Conversation/Setting/CriticalModal/CriticalModal";
import DownloadApp from "./Pages/MobileView/DownloadApp";
import { isAndroid, isIOS, isWindows } from "react-device-detect";
import Auth from "./Auth";
const pckgeJSON = require("../../package.json");

const $ = window.$;

document.body.onfocus = function(e) {
  // if (!JSON.parse(localStorage.getItem("is_logged_in") || "false")) {
  //   if (window.location.pathname !== "/") {
  //     window.location.reload();
  //   }
  // }
};

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      versionNumber: pckgeJSON.version,
      width: window.innerWidth,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);

    this.openIdleModal = this.openIdleModal.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  _onAction(e) {
    // User is doing somthing
    // NOTE Put is code for typing here
  }

  _onActive(e) {
    // User is now active
    // this.props.setIdleModalState("hidden");
    // if (this.state.newMessageUnreadCount !== 0) {
    //     this.setState({
    //         newMessageUnreadCount: 0
    //     })
    // }
    // clearInterval(this.stateInterval);
    // document.title = "Hubchart";
  }

  _onIdle(e) {
    // localStorage.setItem("idleModal", "visible");
    let currentObjectId = "";

    if (Parse.User.current()) {
      currentObjectId = Parse.User.current().id;
      // localStorage.clear();
      Parse.User.logOut().then(() => {
        localStorage.setItem("is_idled", true);
        localStorage.setItem("is_logged_in", false);
        window.location.href = "/";
      });
    } else {
      window.location.reload();
    }

    let exempt = false;
    let someModalIsOpen = JSON.parse(localStorage.getItem("someModalIsOpen") || "false");
    if (
      currentObjectId === "53wLRMbcNa" ||
      currentObjectId === "RgEiL86e2w" ||
      currentObjectId === "aqGTFrkLPl" ||
      currentObjectId === "9L8SEsVjI0"
    ) {
      exempt = true;
    }
    if (!exempt && !someModalIsOpen) {
      this.openIdleModal();
    }
  }

  closeCriticalModal() {
    this.setState({ criticalModalOpened: false });
  }

  openIdleModal() {
    this.setState({
      openIdleModal: true,
    });
  }

  closeIdleModal() {
    localStorage.setItem("idleModal", "hidden");
    $("#idleModal").modal("hide");
    this.setState({
      openIdleModal: false,
    });
  }

  componentDidMount() {
    /* 
        if (isMobile) {
            const url = window.location.href;
            const joinConference = url.includes('conferencecall');
            
            if (!joinConference) {
                window.location = "itms-apps://itunes.apple.com/app/id991641284";
            }
            // window.location = "hubchart://web";
        } */

    window.addEventListener("storage", () => {
      const idleMod = localStorage.getItem("idleModal") || "hidden";
      if (idleMod === "hidden") {
        this.closeIdleModal();
      }
    });

    const { history } = this.props;

    history.listen((location, action) => {
      // check for sw updates on page change
      navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
    });
  }

  render() {
    let isLogin = false,
      isVerified = false;
    if (Parse.User.current()) isLogin = true;

    if (isLogin) {
      if (true || Parse.User.current().get("emailVerified") === true) {
        isVerified = true;
      }
    }

    return (
      <div className="App-Container">
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          // timeout={3600000}
          timeout={1000 * 60 * 60} // 60 mins
          crossTab={{
            emitOnAllTabs: true,
          }}
        />
        <Route
          path="/"
          strict
          exact
          render={(props) => (isLogin && isVerified ? <Redirect to="/u" /> : <Login {...props}></Login>)}
        />
        <Route
          path="/u"
          render={(props) =>
            isLogin && isVerified ? (
              <DndProvider backend={HTML5Backend}>
                <Main {...props} {...this.props} />
              </DndProvider>
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          path="/conference_logs"
          render={(props) =>
            isLogin && isVerified ? (
              <DndProvider backend={HTML5Backend}>
                <Main {...props} {...this.props} tab="conference" />
              </DndProvider>
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          path="/email"
          render={(props) =>
            isLogin ? (
              isVerified ? (
                <Redirect to="/" />
              ) : (
                <EmailContainer {...props} {...this.props} />
              )
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route path="/signup" render={(props) => <Signup {...props} {...this.props} />} />
        <Route path="/forgotpassword" render={(props) => <ForgotPassword {...props} {...this.props} />} />
        <Route path="/videocall" strict exact render={(props) => <VideoCallContainer {...props} {...this.props} />} />
        <Route
          path="/corporate-subscription"
          strict
          exact
          render={(props) => {
            return isLogin && isVerified ? <CorporateSubs /> : <Redirect to="/" />;
          }}
        />
        <Route
          path="/corporate-view"
          strict
          exact
          render={(props) => {
            return isLogin && isVerified ? <CorporateContainer /> : <Redirect to="/" />;
          }}
        />
        <Route
          path="/corporate-setting"
          strict
          exact
          render={(props) => {
            return isLogin && isVerified ? <CorporateSetting /> : <Redirect to="/" />;
          }}
        />
        <Route
          path="/facesheet/:patientId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.patientId;
            return isLogin && isVerified ? <Facesheet {...props} patientId={threadId} /> : <Redirect to="/" />;
          }}
        />
        <Route
          path="/progressnotes/:patientId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.patientId;
            return isLogin && isVerified ? <ProgressNotes {...props} patientId={threadId} /> : <Redirect to="/" />;
          }}
        />
        <Route
          path="/medications/:threadId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.threadId;
            return isLogin && isVerified ? <MedicationView {...props} threadId={threadId} /> : <Redirect to="/" />;
          }}
        />
        <Route
          path="/patient-orders/:threadId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.threadId;
            return isLogin && isVerified && threadId ? (
              <PatientOrdersView {...props} threadId={threadId} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
        <Route
          path="/patient-diagnoses/:threadId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.threadId;
            return isLogin && isVerified && threadId ? (
              <PatientDiagnosesView {...props} threadId={threadId} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
        <Route
          path="/patient-problems/:threadId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.threadId;
            return isLogin && isVerified && threadId ? (
              <PatientProblemsView {...props} threadId={threadId} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
        <Route
          path="/grouped-lists/:threadId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.threadId;
            return isLogin && isVerified && threadId ? (
              <GroupedListsView {...props} threadId={threadId} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
        <Route
          path="/action-items/:threadId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.threadId;
            return isLogin && isVerified && threadId ? (
              <ActionsView {...props} threadId={threadId} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
        <Route
          path="/progressnotes/:patientId/view/:noteId"
          strict
          exact
          render={(props) => {
            const { location = {}, match = {} } = props;
            const { search = "" } = location;
            const patientId = match.params.patientId;
            const noteId = match.params.noteId;
            const urlParams = new URLSearchParams(search);
            const print = parseInt(urlParams.get("print") || 0);

            return isLogin && isVerified ? (
              <ProgressNoteView {...props} patientId={patientId} progressNoteId={noteId} printView={print} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
        <Route
          path="/prescription/:patientId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.patientId;
            return isLogin && isVerified ? <PrescriptionPDF {...props} patientId={threadId} /> : <Redirect to="/" />;
          }}
        />
        <Route
          path="/prescription-history/:patientId/:prescriptionId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.patientId;
            const prescriptionId = props.match.params.prescriptionId;
            return isLogin && isVerified ? (
              <PrescriptionPDF {...props} patientId={threadId} historyId={prescriptionId} fromMedications={true} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
        <Route
          path="/orders/:patientId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.patientId;
            return isLogin && isVerified ? <OrdersPDF {...props} patientId={threadId} /> : <Redirect to="/" />;
          }}
        />
        <Route
          path="/orders-history/:patientId/:orderHistoryId"
          strict
          exact
          render={(props) => {
            const threadId = props.match.params.patientId;
            const orderHistoryId = props.match.params.orderHistoryId;
            return isLogin && isVerified ? (
              <OrdersPDF {...props} patientId={threadId} orderHistoryId={orderHistoryId} fromPatientOrders={true} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
        <Route path="/conferencecall" strict exact render={(props) => <VideoComponent {...props} {...this.props} />} />

        <Route
          path="/conferencecallguest"
          strict
          exact
          render={(props) => <VideoComponent {...props} {...this.props} />}
        />

        <Route path="/privatecall" strict exact render={(props) => <PrivateCallWindow {...props} {...this.props} />} />

        <Route path="/redirect_conferencecall" component={RedirectConference} />

        <Route
          path="/files/:threadId"
          render={(props) => {
            let params = new URLSearchParams(props.location.search);

            const threadId = props.match.params.threadId;
            const imageUrl = params.get("imageUrl");
            const threadName = params.get("threadName");

            return isLogin && isVerified ? (
              <DndProvider backend={HTML5Backend}>
                <FilesWindow
                  threadId={threadId}
                  imageUrl={imageUrl}
                  threadName={threadName}
                  {...props}
                  {...this.props}
                />
              </DndProvider>
            ) : (
              <Redirect to="/" />
            );
          }}
        />

        <Route
          path="/auth/:sessionToken"
          strict
          exact
          render={(props) => {
            const params = new URLSearchParams(props.location.search);
            const sessionToken = props.match.params.sessionToken;
            return <Auth sessionToken={sessionToken} history={props.history} next={params.get("next")} />;
          }}
        />

        <div className="version-container-2">
          <span className="version-text">{`Ver ${this.state.versionNumber}`}</span>
        </div>
        {this.state.openIdleModal && (
          <IdleModal isOpen={this.state.openIdleModal} modalClose={this.closeIdleModal.bind(this)} />
        )}

        <CriticalComponent history={this.props.history} />

        {(this.state.width <= 768 || isAndroid || isIOS) && <DownloadApp />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  idleModalState: state.session.idleModalState,
  threads: state.thread.threads,
});

export default withRouter(
  connect(mapStateToProps, {
    setIdleModalState,
    setActiveTab,
    fetchThreads,
    setActiveThread,
  })(App)
);
