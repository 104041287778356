import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ProfilePic from "../../../Common/ProfilePic";
// import { SET_PATIENT_INFO_REDUCED, SET_HAS_CRITICAL } from "../../../actions/types";
// import Notify from "../../Common/Notify";
import { Select } from "../../../Common/Forms";
import DatePicker from 'react-datepicker';
import DOMPurify from "dompurify";

const $ = window.$;

function ActionViewModal ({ modalClose = () => {}, item }) {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState({ date: new Date() });
  const [desc, setDescription] = useState(item.text);
  const [assignedTo, setAssignee] = useState("");

  useEffect(() => {
    if (item.due_date) {
      setStartDate({ date: new Date(item.due_date.iso) });
    }
  }, []);

  function handleStartDate (date) {
    if (date === new Date()) {return}
    setStartDate({
      ...startDate,
      date: date
    });
  }

  function handleHtmlText(text) {
    const html_string = text;
    const cleanHTML = DOMPurify.sanitize(html_string);

    return <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />;
  }

  return (
    <div className="reminder-modal-container">
      <div className="reminder-container">
        <div className="save-changes-flex" style={{ marginLeft: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <i className="fas fa-exclamation-circle hc-primary-text fa-1x"></i>
            <h6>Task Details</h6>
            <i className="fas fa-times fa-lg" style={{ float: "right" }} role="button" onClick={() => modalClose()} />
          </div>
        </div>
        <div className="mb-15">
          <label>Assign to:</label>
          <div style={{border: '1px solid #d1d3e2', borderRadius:'0.35rem'}}>
            <div className="d-flex align-items-center text-truncate m-1">
              <ProfilePic url={''} size="25" />
              <span className="text-primary ml-1">{item.assignedTo.displayName}</span>
            </div>
            {/* <Select
              className="form-control"
              value={assignedTo}
              onChange={(e) => setAssignee(e.target.value)}
            >
              <option value="" disabled>Choose assignee</option>
              {members.map((member) => (
                <option key={member.objectId} value={member.objectId}>
                  {member.displayName}
                </option>
              ))}
            </Select> */}
          </div>
        </div>
        <div className="mb-15">
          <label>Description: </label>
          <div
            className="view-details"
          >
            {handleHtmlText(desc)}
          </div>
          {/* <textarea 
            className="form-control" 
            id="eventDescription" 
            name="eventDescription" 
            rows="12"
            onChange={e => setDescription(e.target.value)}
            value={handleHtmlText(desc)} 
            disabled
            /> */}
        </div>
        <div className="mb-15">
          {item.due_date && (
            <>
              <label>Due Date:</label>
              <div className="date-picker">
              <DatePicker
                className="text-center text-primary"
                todayButton={"Today"}
                dateFormat="EEEE, MMMM dd" 
                selected={startDate.date}
                onChange={date => handleStartDate(date)}
                disabled
              />
              </div>
            </>
          )}
        </div>
        <div>
          {/* Footer */}
        </div>
      </div>
    </div>
  );
}

export default ActionViewModal;
