import React, { Component } from "react";
import { parseAction } from "../../../actions/REST";
import "../../Pages/Login/Login.css";
import Loader from "../../Common/Loader";
import Member from "./Member";
import Parse from "parse";
import { assignAdmin, removeAdmin, removeMember, inviteMember } from "../../../actions/GroupAction";
import { connect } from "react-redux";
import Notify from "../../Common/Notify";
import _ from "lodash";
import MemberList from "./ParticipantsList";
import AddMember from "./AddMember";
import config from "../../../config";
import OverlayLoader from "../../Common/OverlayLoader";

const $ = window.$;

export class ParticipantsSelectModal extends Component {
  constructor() {
    super();
    this.state = {
      owner: {},
      admins: [],
      members: [],

      isLoading: false,
      loadingMessage: "",
      isCreator: false,
      isAdmin: false,
      allSelected: false,
      group: {},
      currentTab: "list",
      selected: [],
    };

    this.assignAdmin = this.assignAdmin.bind(this);
    this.removeAdmin = this.removeAdmin.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.inviteMember = this.inviteMember.bind(this);
  }

  componentDidMount() {
    if (this.props.isOpen) {
      $("#participantsSelectModal").modal({
        backdrop: "static",
        keyboard: false,
      });

      $("#participantsSelectModal").modal("show");

      this.fetchData();
    } else {
      $("#participantsSelectModal").modal("hide");
    }
    $("#participantsSelectModal").on(
      "hidden.bs.modal",
      function(e) {
        this.props.onClose();
      }.bind(this)
    );
  }

  setLoading(isLoading, loadingMessage) {
    this.setState({ isLoading, loadingMessage });
  }

  async fetchData() {
    const groupObjectId = this.props.threadId;

    this.setLoading(true);

    try {
      const [result] = await Promise.all([this.fetchMembers(groupObjectId)]);

      this.setMembers(result);
      this.setGroup(result);
      this.setLoading(false);
    } catch (err) {
      this.setLoading(false);
    }
  }

  fetchMembers(groupObjectId) {
    const method = "post";
    const url = config.BASE_URL + "/parse/functions/getCircleMembersV3";
    const data = {
      circleObjectId: groupObjectId,
    };

    return parseAction(method, url, data)
      .then((response) => {
        return response.result;
      })
      .catch((error) => {
        Notify.error("Could not fetch members.");

        return Promise.reject(error);
      });
  }

  fetchGroup(groupObjectId) {
    const method = "get";
    const url = config.BASE_URL + "/parse/classes/Circle";
    const data = {
      where: {
        objectId: groupObjectId,
      },
      include: ["owner"],
    };

    return parseAction(method, url, data)
      .then((response) => {
        return response.results[0];
      })
      .catch((err) => {
        Notify.error("Could not fetch group.");

        return Promise.reject(err);
      });
  }

  setMembers(result, group) {
    let { regMembers = [], orgMembers = [], orgAdmins = [] } = result;

    regMembers = _.compact(regMembers);

    const adminIds = _.map(group.administrators, "objectId");

    // Set isAdmin flag for all regular members
    regMembers.forEach((member) => {
      member.isAdministrator = _.includes(adminIds, member.objectId);
    });

    // Set isCorporateMember flag for all corporate members
    orgMembers.forEach((member) => {
      member.isCorporateMember = true;
    });

    // Combine regMembers and orgMembers into members
    let allMembers = [...regMembers, ...orgMembers];

    let members = [];
    let admins = [...orgAdmins];

    allMembers.forEach((member) => {
      if (member.isAdministrator) {
        admins.push(member);
      } else {
        members.push(member);
      }
    });

    const currentUserId = Parse.User.current().id;
    const filteredMembers = members.filter((member) => member.objectId !== currentUserId);
    const filteredAdmins = admins.filter((admin) => admin.objectId !== currentUserId);

    this.setState({
      members: filteredMembers,
      admins: filteredAdmins,
    });
  }

  setGroup(group = {}) {
    const owner = group.owner || {};

    const currentUser = Parse.User.current();

    const isCreator = owner.objectId === currentUser.id;

    const { administrators = [] } = group;

    const isAdmin = this.isAdmin(currentUser, administrators);

    this.setState({ group, isCreator, isAdmin, owner });
  }

  isAdmin(user, administrators) {
    const adminUser = _.some(administrators, (admin) => {
      return admin.objectId === user.id;
    });

    return adminUser;
  }

  assignAdmin(member) {
    const groupObjectId = this.props.threadId;
    const memberObjectId = member.objectId;

    this.setLoading(true, "Assigning admin...");

    this.props
      .assignAdmin(memberObjectId, groupObjectId)
      .then((circle) => {
        const { members, admins } = this.state;

        const updatedMembers = _.filter(members, (member) => {
          return member.objectId != memberObjectId;
        });

        const updatedAdmins = [
          ...admins,
          {
            ...member,
            isAdministrator: true,
          },
        ];

        this.setState({
          members: updatedMembers,
          admins: updatedAdmins,
        });

        this.setLoading(false);

        Notify.success("Assigned admin successfully!");
      })
      .catch((err) => {
        this.setLoading(false);

        Notify.error("Failed to assign admin.");

        return Promise.reject(err);
      });
  }

  removeAdmin(member) {
    const groupObjectId = this.props.threadId;
    const memberObjectId = member.objectId;

    this.setLoading(true, "Removing admin...");

    this.props
      .removeAdmin(memberObjectId, groupObjectId)
      .then((circle) => {
        const { members, admins } = this.state;

        const updatedAdmins = _.filter(admins, (member) => {
          return member.objectId != memberObjectId;
        });

        const updatedMembers = [
          ...members,
          {
            ...member,
            isAdministrator: false,
          },
        ];

        this.setState({
          members: updatedMembers,
          admins: updatedAdmins,
        });

        this.setLoading(false);

        Notify.success("Admin removed successfully!");
      })
      .catch((err) => {
        this.setLoading(false);

        Notify.error("Failed to remove admin.");

        return Promise.reject(err);
      });
  }

  removeMember(member) {
    const userObjectId = member.objectId;

    if (member.isCorporateMember) {
      const alertContent = (
        <div>
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>Unable to remove corporate member</div>
          <div style={{ fontSize: "14px" }}>Go to corporate settings to make changes</div>
        </div>
      );

      return Notify.info(alertContent);
    }

    this.setLoading(true, "Removing member...");

    this.props
      .removeMember(userObjectId, this.state.group.uuid)
      .then((circleMember) => {
        const { members, admins } = this.state;

        const updatedMembers = members.filter((user) => {
          return user.objectId != userObjectId;
        });

        const updatedAdmins = admins.filter((user) => {
          return user.objectId != userObjectId;
        });

        this.setState({
          members: updatedMembers,
          admins: updatedAdmins,
        });

        this.setLoading(false);

        Notify.success("Member removed successfully!");
      })
      .catch((err) => {
        this.setLoading(false);

        Notify.error("Failed to remove admin.");

        return Promise.reject(err);
      });
  }

  selectTab(currentTab) {
    this.setState({ currentTab });
  }

  inviteMember(toBeInvited) {
    const { group, members } = this.state;

    this.setLoading(true, "Adding members...");

    const promises = toBeInvited.map((contact) => {
      return this.props.inviteMember(contact, group.uuid);
    });

    Promise.all(promises)
      .then((circleMember) => {
        this.setState({
          members: [...members, ...toBeInvited],
        });

        this.props.onNewMember(toBeInvited);

        this.setLoading(false);

        Notify.success("Member added successfully!");
      })
      .catch((res) => {
        this.setLoading(false);

        const { responseJSON } = res;

        if (responseJSON) {
          const { error } = responseJSON;

          const alertContent = (
            <div>
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>Failed to add member</div>
              <div style={{ fontSize: "14px" }}>{error}</div>
            </div>
          );

          return Notify.error(alertContent);
        }

        return Notify.error("Failed to add member.");
      });
  }

  handleClick(e) {
    this.props.onClose();
  }

  handleSelect(member) {
    const { selected } = this.state;

    const existingMember = selected.find((id) => id === member.objectId);

    if (existingMember) {
      this.setState({
        selected: selected.filter((id) => id != member.objectId),
      });
    } else {
      this.setState({
        selected: [...selected, member.objectId],
      });
    }
  }

  handleUnselectAll() {
    this.setState({
      selected: [],
      allSelected: false,
    });
  }

  handleSelectAll() {
    const { members, admins, owner } = this.state;

    const allMembers = [...members, ...admins, owner];

    const allIds = allMembers.map((member) => member.objectId);

    this.setState({
      selected: allIds,
      allSelected: true,
    });
  }

  handleStart() {
    this.props.onStart(this.state.selected);
    $("#participantsSelectModal").modal("hide");
  }

  render() {
    const {
      isLoading,
      isCreator,
      isAdmin,
      loadingMessage,
      members,
      admins,
      owner,
      currentTab,
      allSelected = false,
    } = this.state;

    return (
      <div id="participantsSelectModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-full-height">
            <div className="modal-header">
              <h5 className="modal-title hc-primary-text" style={{ fontSize: "1.25rem" }}>
                Invite members to telemed
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <button
                className={`btn btn-sm mr-2 ${allSelected ? "btn-primary" : "btn-light"}`}
                onClick={(e) => this.handleSelectAll()}
              >
                <i className="fas fa-check-double mr-1" /> Select all
              </button>
              <button className="btn btn-light btn-sm" onClick={(e) => this.handleUnselectAll()}>
                <i className="fas fa-times mr-1"></i>
                Unselect all
              </button>
            </div>
            <div className="modal-body pt-0" style={{ height: "100%", overflowY: "auto" }}>
              <MemberList
                selected={this.state.selected}
                onSelect={this.handleSelect.bind(this)}
                onClick={(e) => this.handleClick(e)}
                members={members}
                admins={admins}
                owner={isCreator ? {} : owner}
                isLoading={isLoading}
                isCreator={isCreator}
                isAdmin={isAdmin}
                onAssignAdmin={this.assignAdmin}
                onRemoveAdmin={this.removeAdmin}
                onRemoveMember={this.removeMember}
              />
              <OverlayLoader isLoading={isLoading} />
            </div>
            <div className="modal-footer">
              <button className="btn btn-success" onClick={(e) => this.handleStart()}>
                <i className="fas fa-video mr-1"></i>
                Start
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // Access values from store
});

export default connect(mapStateToProps, { assignAdmin, removeAdmin, removeMember, inviteMember })(
  ParticipantsSelectModal
);
