import React, { Component } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import {
  fetchThreads,
  getThreads,
  setActiveThread,
  createThread,
  fetchThreadsUnread,
  sortThreads,
  filterThreads,
  hideTagsModal,
  hideTagsFilterModal,
  fetchThreadsByLabel,
  fetchThreadsForMe,
  setSubMessageTab,
  setMessageTab,
  fetchPrivateThreadCount,
  fetchUnreadBadges,
  setMessagesShown,
} from "../../../actions/ThreadAction";
import { getUrgentCount } from "../../../actions/DashboardAction";
import { fetchPriorities } from "../../../actions/PriorityAction";
import MessageList from "./MessageList";
import LoadingSpinner from "../../../components/Conversation/Bubble/LoadingSpinner";
import PriorityList from "./PriorityList";
import TagsFilterModal from "./TagsFilterModal";
import { useInsertionEffect } from "react";

export class MessageContainer extends Component {
  constructor() {
    super();
    this.state = {
      routeObjectId: "",
      isLoadingMore: false,
      preventReload: false,
      searchTimeout: null,
      isLoadingSearch: false,
      hasPrivate: false,
      searchValue: "",
      selectedUnreadSubFilter: "all",
      selectedAlertSubFilter: 2, // High Priority level
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.getHasMore = this.getHasMore.bind(this);
    // this.searchThread = this.searchThread.bind(this);
    // this.triggerSearchThread = this.triggerSearchThread.bind(this);
  }

  isScrolledToBottom(event, offset = 0) {
    let initialHeight = event.target.scrollHeight;
    let height = event.target.offsetHeight;
    let scrollTop = event.target.scrollTop;

    return scrollTop + height >= initialHeight - offset;
  }

  getHasMore() {
    const {
      tagsSelectShown,
      threadsByLabel,
      threadsUnread,
      hasMore,
      prioritiesHasMore,
      activeMessageTab,
      activeUnreadTab,
    } = this.props;

    switch (activeMessageTab) {
      case "ALL":
        if (activeUnreadTab === "all") {
          return tagsSelectShown ? threadsByLabel.hasMore : hasMore;
        } else {
          return threadsUnread.hasMore;
        }

      case "TASKS":
        return prioritiesHasMore;

      case "ALERTS":
        return prioritiesHasMore;

      case "RECENT":
        return false;

      default:
        return hasMore;
    }
  }

  getCurrentPage() {
    const {
      tagsSelectShown,
      threadsByLabel,
      threadsUnread,
      page,
      prioritiesPage,
      activeMessageTab,
      recentThreads,
      activeUnreadTab,
    } = this.props;

    switch (activeMessageTab) {
      case "ALL":
        if (activeUnreadTab === "all") {
          return tagsSelectShown ? threadsByLabel.page : page;
        } else {
          return threadsUnread.page;
        }

      case "TASKS":
        return prioritiesPage;

      case "ALERTS":
        return prioritiesPage;

      // case "RECENT":
      //   return recentThreads;

      default:
        return page;
    }
  }

  handleScroll(event) {
    const hasMore = this.getHasMore();

    if (!hasMore) return;

    const bottomScrolled = this.isScrolledToBottom(event, 100);

    const { isLoadingMore, preventReload } = this.state;

    if (bottomScrolled && !isLoadingMore && !preventReload) {
      this.setState({
        isLoadingMore: true,
      });

      this.loadMore()
        .then(() => {
          this.setState({
            preventReload: true,
            isLoadingMore: false,
          });
        })
        .catch(() => {
          this.setState({
            preventReload: true,
            isLoadingMore: false,
          });
        });
    } else {
      this.setState({ preventReload: false });
    }
  }

  loadMore() {
    const { tagsSelectShown, activeMessageTab, circleLabelId, activeUnreadTab } = this.props;

    const { routeObjectId } = this.state;

    const page = this.getCurrentPage();

    this.setState({
      isLoadingMore: true,
    });

    const nextPage = page + 1;

    switch (activeMessageTab) {
      case "ALL":
        if (activeUnreadTab === "all") {
          return tagsSelectShown
            ? this.loadMoreThreadsByLabel(nextPage, circleLabelId)
            : this.loadMoreThreads(routeObjectId, nextPage);
        } else {
          return this.loadMoreThreadsUnread(nextPage);
        }

      case "TASKS":
        return this.loadMorePriorities(nextPage);

      case "ALERTS":
        return this.loadMorePriorities(nextPage);

      default:
        return Promise.resolve();
    }
  }

  loadMoreThreads(routeObjectId, nextPage) {
    return this.props.fetchThreads(routeObjectId, true, nextPage, "", this.props.typeValue, this.props.read);
  }

  loadMoreThreadsByLabel(nextPage, circleLabelId) {
    return this.props.fetchThreadsByLabel(nextPage, circleLabelId);
  }

  loadMoreThreadsUnread(nextPage) {
    // if (this.state.selectedUnreadSubFilter === "attention") {
    //   return this.props.fetchThreadsForMe(nextPage);
    // } else {
    return this.props.fetchThreadsUnread(nextPage, this.state.selectedUnreadSubFilter, this.props.read);
    // }
  }

  loadMorePriorities(nextPage) {
    return this.props.fetchPriorities(true, nextPage, this.state.selectedAlertSubFilter);
  }

  componentDidMount() {
    if (!this.props.threadInitialLoad) {
      console.log("initial load:", this.props.threadInitialLoad);
      let path = this.props.location.pathname;
      let routeObjectId;
      if (path !== "/u") {
        routeObjectId = this.props.location.pathname.replace("/u/", "");
      }

      this.setState({ routeObjectId });
      this.props.fetchThreads(routeObjectId, false, 0, "", "", this.props.read);
    }

    // Dashboard link quick open
    if (this.props.activeUnreadTab === "urgent") {
      this.props.fetchThreadsUnread(0, "urgent", this.props.read);
      this.setState({ selectedUnreadSubFilter: "urgent" });
    }
    if (this.props.activeUnreadTab === "attention") {
      this.props.fetchThreadsUnread(0, "attention", this.props.read);
      this.setState({ selectedUnreadSubFilter: "attention" });
    }

    // Activity link quick open
    if (this.props.activeMessageTab === "TASKS") {
      this.handleAlertSubFilter(this.props.selectedSubFilter);
    }

    this.content.addEventListener("scroll", this.handleScroll);
    // if private has data show red-badge
    // this.props.fetchPrivateThreadCount().then((recent) => {
    //   if (recent.length > 0) {
    //     this.setState({ hasPrivate: true });
    //   }
    // });
    // if urgent has data show red-badge
    this.props.fetchUnreadBadges();
    if (!this.props.urgentCount) {
      this.props.getUrgentCount();
    }
  }

  componentWillUnmount() {
    this.content.removeEventListener("scroll", this.handleScroll);
  }

  showNewMessages() {
    this.props.sortThreads({ reverse: false });
  }

  removeAttentionFilter() {
    this.props.filterThreads({ attention: false });
  }

  handleActiveSubMessageTab(subTab) {
    if (subTab === "all") {
      this.props.setMessageTab("ALL");
      this.props.fetchThreadsUnread(0, "", this.props.read);
    } else {
      if (subTab === "pinned") {
        this.props.fetchThreadsUnread(0, subTab, "");
      } else {
        this.props.fetchThreadsUnread(0, subTab, this.props.read);
      }
    }

    // if (subTab === "attention") {
    //   this.props.fetchThreadsForMe();
    // } else {
    this.props.setSubMessageTab(subTab);
    this.setState({ selectedUnreadSubFilter: subTab });
  }

  handleAlertSubFilter(subTab) {
    this.setState({ selectedAlertSubFilter: subTab });
    this.props.fetchPriorities(false, 0, subTab);
  }

  // triggerSearchThread(value) {
  //   let routeObjectId = "";
  //   let path = this.props.location.pathname;
  //   if (path !== "/u") {
  //     routeObjectId = this.props.location.pathname.replace("/u/", "");
  //   }

  //   this.props.fetchThreads(routeObjectId, false, 0, "", value);
  // }

  // searchThread = (searchValue) => {
  //   const debounced = debounce(
  //     () => this.triggerSearchThread(searchValue),
  //     2000
  //   );
  //   debounced();
  // };

  // componentWillReceiveProps(newProps) {
  //   if (newProps.typeValue) {
  //     this.searchThread(newProps.typeValue);
  //   }
  // }

  //   componentWillReceiveProps(newProps) {
  //     if (newProps.pubNub && newProps.threads.length > 0) {
  //       newProps.pubNub.subscribe({
  //         channels: newProps.threads.map(thread => `group_${thread.threadId}`)
  //       });

  //       this.props.pubNub.hereNow(
  //         {
  //           channels: [`group_${this.props.threads[0].threadId}`]
  //         },
  //         function(status, response) {
  //           console.log(status, response);
  //         }
  //       );
  //     }
  //   }

  componentWillReceiveProps() {
    if (this.props.activeMessageTab != "TASKS" && this.state.selectedAlertSubFilter != 2) {
      this.setState({ selectedAlertSubFilter: 2 });
    }

    if (this.props.activeMessageTab != "ALL" && this.state.selectedUnreadSubFilter != "all") {
      this.setState({ selectedUnreadSubFilter: "all" });
    }
  }

  componentDidUpdate(prevProps) {
    const { read, activeUnreadTab, fetchThreads, fetchThreadsUnread } = this.props;
    const { routeObjectId, selectedUnreadSubFilter } = this.state;
    if (prevProps.read !== read) {
      console.log("routeObjectId", routeObjectId);
      if (activeUnreadTab === "all") {
        fetchThreads(routeObjectId, false, 0, "", "", read);
      } else {
        fetchThreadsUnread(0, selectedUnreadSubFilter, read);
      }
    }
  }

  render() {
    const {
      isLoading,
      threads = [],
      priorities = [],
      activeMessageTab,
      sortReverse,
      filterAttention,
      recentToTag,
      tagsSelectShown,
      threadsByLabel,
      threadsUnread,
      recentThreads,
      urgentCount,
      activeUnreadTab,
      messageBadges,
      read,
    } = this.props;

    const viewThreads = activeMessageTab === "ALL" || activeMessageTab === "UNREAD" || activeMessageTab === "RECENT";
    const currentThreads = tagsSelectShown
      ? threadsByLabel.items
      : activeUnreadTab === "attention" ||
        activeUnreadTab === "urgent" ||
        activeUnreadTab === "personal" ||
        activeUnreadTab === "pinned" ||
        activeUnreadTab === "" ||
        activeUnreadTab === "all"
      ? threadsUnread.items
      : activeMessageTab === "RECENT"
      ? recentThreads
      : threads; // default 'all' messages sub-filter

    const isEmpty = viewThreads ? currentThreads.length === 0 : priorities.length === 0;

    return (
      <>
        {activeMessageTab === "TASKS" ? (
          <div
            className="btn-list-tasks"
            style={{
              marginLeft: "18px",
              marginRight: "18px",
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
              marginTop: "3px",
            }}
          >
            <button
              className={`btn btn-sm ${this.state.selectedAlertSubFilter === 2 ? "btn-warning" : "btn-secondary"}`}
              style={{ marginRight: 5 }}
              onClick={() => this.handleAlertSubFilter(2)}
            >
              <i class="fad fa-angle-double-up fa-fw"></i> High
            </button>
            <button
              className={`btn btn-sm ${this.state.selectedAlertSubFilter === 1 ? "btn-warning" : "btn-secondary"}`}
              style={{ marginRight: 5 }}
              onClick={() => this.handleAlertSubFilter(1)}
            >
              <span
                style={{
                  padding: "0px 4px",
                  fontWeight: "bold",
                }}
              >
                =
              </span>{" "}
              Medium
            </button>
            <button
              className={`btn btn-sm ${this.state.selectedAlertSubFilter === 0 ? "btn-warning" : "btn-secondary"}`}
              style={{ marginRight: 5 }}
              onClick={() => this.handleAlertSubFilter(0)}
            >
              <i class="fad fa-angle-double-down fa-fw"></i> Routine
            </button>
            {/* <button
              className={`btn btn-sm ${
                this.state.selectedAlertSubFilter === ""
                  ? "btn-warning"
                  : "btn-secondary"
              }`}
              style={{ marginRight: 5 }}
              onClick={() => this.handleAlertSubFilter("")}
            >
              All
            </button> */}
          </div>
        ) : (
          ""
        )}
        <div className="Tab-Container mb-0" ref={(ul) => (this.content = ul)}>
          {activeMessageTab === "UNREAD" || activeMessageTab === "ALL" ? (
            <div
              className="btn-list-tasks"
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                display: "flex",
                flexDirection: "row",
                marginTop: "3px",
              }}
            >
              <button
                className={`btn btn-sm`}
                style={{
                  marginRight: 5,
                  fontSize: "12px",
                  color: "#ffffff",
                  position: "relative",
                  backgroundColor: this.state.selectedUnreadSubFilter === "attention" ? "#f0ad4e" : "#C8C8C8",
                }}
                onClick={() => this.handleActiveSubMessageTab("attention")}
              >
                @me
                {messageBadges.attention && (
                  <button
                    className="hc-badge-message btn-warning"
                    style={{
                      zIndex: "3",
                      height: "12px",
                      width: "12px",
                      position: "absolute",
                      top: "-3px",
                      left: "37px",
                      borderRadius: "50%",
                    }}
                  ></button>
                )}
              </button>
              <button
                className={`btn btn-sm`}
                style={{
                  marginRight: 5,
                  fontSize: "12px",
                  color: "#ffffff",
                  position: "relative",
                  backgroundColor: this.state.selectedUnreadSubFilter === "urgent" ? "#f0ad4e" : "#C8C8C8",
                }}
                onClick={() => this.handleActiveSubMessageTab("urgent")}
              >
                Urgent
                {messageBadges.urgent > 0 && (
                  <button
                    className="hc-badge-message btn-danger"
                    style={{
                      zIndex: "3",
                      height: "12px",
                      width: "12px",
                      position: "absolute",
                      top: "-3px",
                      left: "46px",
                      borderRadius: "50%",
                    }}
                  ></button>
                )}
              </button>
              <button
                className={`btn btn-sm`}
                style={{
                  marginRight: 5,
                  fontSize: "12px",
                  color: "#ffffff",
                  position: "relative",
                  backgroundColor: this.state.selectedUnreadSubFilter === "personal" ? "#f0ad4e" : "#C8C8C8",
                }}
                onClick={() => this.handleActiveSubMessageTab("personal")}
              >
                Personal
                {messageBadges.personal && (
                  <button
                    className="hc-badge-message btn-warning"
                    style={{
                      zIndex: "3",
                      height: "12px",
                      width: "12px",
                      position: "absolute",
                      top: "-4px",
                      left: "53px",
                      borderRadius: "50%",
                    }}
                  ></button>
                )}
              </button>
              {/* <button
                className={`btn btn-sm`}
                style={{ marginRight: 5, fontSize: "12px", color: '#ffffff',
                  backgroundColor: this.state.selectedUnreadSubFilter === "" ? '#f0ad4e' : '#C8C8C8' }}
                onClick={() => this.handleActiveSubMessageTab("")}
              >
                Unread
              </button> */}
              <button
                className={`btn btn-sm`}
                style={{
                  marginRight: 5,
                  fontSize: "12px",
                  color: "#ffffff",
                  backgroundColor: this.state.selectedUnreadSubFilter === "all" ? "#f0ad4e" : "#C8C8C8",
                }}
                onClick={() => this.handleActiveSubMessageTab("all")}
              >
                All
              </button>
              <button
                className={`btn btn-sm`}
                style={{
                  marginLeft: "auto",
                  marginRight: 5,
                  fontSize: "12px",
                  color: "#ffffff",
                  backgroundColor: this.state.selectedUnreadSubFilter === "pinned" ? "#f0ad4e" : "#C8C8C8",
                }}
                onClick={() => this.handleActiveSubMessageTab("pinned")}
              >
                <i className="fas fa-thumbtack" style={{ color: "white" }}></i>
              </button>
            </div>
          ) : (
            ""
          )}
          {/* {read === false && (
            <span className="text-danger" style={{ fontSize: 12, padding: "0px 20px", fontWeight: "bold" }}>
              Message Filter: UNREAD
            </span>
          )} */}
          {activeMessageTab !== "TASKS" && (
            <div className="unreadCls">
              <span className="text-primary">{read === "" ? "ALL" : read === false ? "UNREAD" : ""}</span>
              {read === "" && (
                <a href="#" onClick={() => this.props.setMessagesShown(false)}>
                  See Unread <i className="fas fa-chevron-right" />
                </a>
              )}
              {read === false && (
                <a href="#" onClick={() => this.props.setMessagesShown("")}>
                  See All <i className="fas fa-chevron-right" />
                </a>
              )}
            </div>
          )}
          {sortReverse ? (
            <div style={{ marginLeft: "20px", marginRight: "20px" }} className="alert alert-info" align="center">
              <strong>Showing older messages</strong>
              <button onClick={() => this.showNewMessages()} type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ) : (
            ""
          )}
          {filterAttention ? (
            <div style={{ marginLeft: "20px", marginRight: "20px" }} className="alert alert-warning" align="center">
              <button onClick={() => this.removeAttentionFilter()} type="button" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <p style={{ fontSize: "14px" }}>
                Showing unread private and important messages only. Change by clicking the menu{" "}
                <i className="fas fa-bars"></i> button above.
              </p>
            </div>
          ) : (
            ""
          )}
          {isLoading && <LoadingSpinner top="40%" />}
          {!isLoading && isEmpty && <div className="text-empty tab-placeholder">No messages</div>}
          {/* The message list */}
          {viewThreads ? (
            <MessageList
              {...this.props}
              threads={currentThreads}
              isLoadingMore={this.state.isLoadingMore}
              reverse={sortReverse}
              filterAttention={filterAttention}
              activeMessageTab={activeMessageTab}
              activeUnreadTab={activeUnreadTab}
            />
          ) : (
            <PriorityList
              isLoadingMore={this.state.isLoadingMore}
              selectedAlertSubFilter={this.state.selectedAlertSubFilter}
            />
          )}
          <TagsFilterModal isOpen={this.props.tagsFilterModalShown} onClose={this.props.hideTagsFilterModal} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  threadInitialLoad: state.thread.initialLoad,
  threads: state.thread.threads,
  activeThread: state.thread.activeThread,
  page: state.thread.currentPage,
  hasMore: state.thread.hasMore,
  isLoading: state.thread.isLoading,
  activeMessageTab: state.thread.activeMessageTab,
  activeUnreadTab: state.thread.activeUnreadTab,
  urgentCount: state.dashboard.urgentCount,
  selectedSubFilter: state.thread.selectedSubFilter,

  // Priorities
  priorities: state.priority.priorities,

  // Priorities
  prioritiesHasMore: state.priority.hasMore,

  // Priorities
  prioritiesPage: state.priority.currentPage,

  // Thread sorting
  sortReverse: state.thread.sortReverse,

  // Thread filtering
  filterAttention: state.thread.filterAttention,

  // Circle tagging
  tagsModalShown: state.thread.tagsModalShown,

  // Circle tagging
  recentToTag: state.thread.recentToTag,

  // Circle tag filtering
  tagsFilterModalShown: state.thread.tagsFilterModalShown,

  // Circle tag filtering
  threadsByLabel: state.thread.threadsByLabel,

  // Whether user is filtering by tag
  tagsSelectShown: state.thread.tagsSelectShown,

  // Whether user is filtering by tag
  threadsUnread: state.thread.threadsUnread,

  circleLabelId: state.thread.circleLabelId,

  // Recent viwed threads
  recentThreads: state.thread.recentThreads,

  // unread/all messages filter
  read: state.thread.read,

  messageBadges: state.thread.messageBadges,
});

export default connect(mapStateToProps, {
  setActiveThread,
  fetchThreads,
  getThreads,
  createThread,
  sortThreads,
  filterThreads,
  hideTagsModal,
  hideTagsFilterModal,
  fetchThreadsByLabel,
  fetchThreadsUnread,
  fetchPriorities,
  fetchThreadsForMe,
  setSubMessageTab,
  setMessageTab,
  fetchPrivateThreadCount,
  getUrgentCount,
  fetchUnreadBadges,
  setMessagesShown,
})(MessageContainer);
