import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import DOMPurify from "dompurify";
import { SET_PATIENT_INFO_REDUCED, SET_HAS_CRITICAL } from "../../../actions/types";
import Notify from "../../Common/Notify";
import { Select } from "../../Common/Forms";
import OverlayLoader from "../../Common/OverlayLoader";
import "./Reminder.css";
import DatePicker from "react-datepicker";
import { setReminderThread, unsetReminderThread, getCritical } from "../../../actions/ThreadAction";

const $ = window.$;

function ReminderModal({ circleId, currentReminder, modalClose = () => {}, criticalMsg = "", members = [] }) {
  const dispatch = useDispatch();

  const { patientInfoReduced } = useSelector(mySelector, shallowEqual);

  const removeHtmlTag = (text) => {
    const cleanHTML = DOMPurify.sanitize(text);
    const tempDiv = document.createElement("span");
    tempDiv.innerHTML = cleanHTML;
    const cleanText = tempDiv.innerText;
    return cleanText;
  };

  const cricMsg = removeHtmlTag(criticalMsg);

  var today = new Date();
  const [isLoading, setLoading] = useState(false);
  const [isRemoving, setRemoving] = useState(false);
  const [startDate, setStartDate] = useState({ date: new Date(today.setDate(today.getDate() + 1)) });
  const [desc, setDescription] = useState(cricMsg);
  const [assignedTo, setAssignee] = useState("");

  useEffect(() => {
    dispatch(getCritical(circleId)).then((task) => {
      if (task) {
        setStartDate({ date: new Date(task.remind_me_at.iso) });
        setAssignee(task.assignedTo.objectId);

        if (criticalMsg === "") {
          setDescription(removeHtmlTag(task.text));
        } else {
          setDescription(cricMsg);
        }
      }
    });
  }, []);

  const handleSetReminder = () => {
    setLoading(true);
    dispatch(setReminderThread(circleId, startDate, assignedTo, desc)).then(() => {
      let patientInfo = patientInfoReduced;
      patientInfo.isCritical = true;

      setLoading(false);
      Notify.info("Successfully set reminder.");

      // dispatch({
      //   type: SET_PATIENT_INFO_REDUCED,
      //   patientInfoReduced: patientInfo,
      // });

      // no longer used
      // dispatch({
      //   type: SET_HAS_CRITICAL,
      //   hasCritical: true,
      // });

      modalClose();
    });
  };

  const handleUnsetReminder = () => {
    setLoading(true);
    setRemoving(true);
    dispatch(unsetReminderThread(circleId)).then(() => {
      let patientInfo = patientInfoReduced;
      patientInfo.isCritical = false;
      setLoading(false);
      setRemoving(false);
      Notify.info("Reminder remove.");

      dispatch({
        type: SET_PATIENT_INFO_REDUCED,
        patientInfoReduced: patientInfo,
      });

      modalClose();
    });
  };

  function handleStartDate(date) {
    if (date === new Date()) {
      return;
    }
    setStartDate({
      ...startDate,
      date: date,
    });
  }

  return (
    <div className="reminder-modal-container">
      <div className="reminder-container">
        <div className="save-changes-flex" style={{ marginLeft: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <i className="fas fa-exclamation-circle hc-primary-text fa-1x"></i>
            <h6>Set Reminder</h6>
            <i className="fas fa-times" style={{ float: "right" }} role="button" onClick={() => modalClose()} />
          </div>
        </div>
        <div className="mb-15">
          <label>Assign to:</label>
          <div style={{ border: "1px solid #d1d3e2", borderRadius: "0.35rem" }}>
            <Select className="form-control" value={assignedTo} onChange={(e) => setAssignee(e.target.value)}>
              <option value="" disabled>
                Choose assignee
              </option>
              {members.map((member) => (
                <option key={member.objectId} value={member.objectId}>
                  {member.displayName}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div className="mb-15">
          <label>Description: </label>
          <textarea
            className="form-control"
            id="eventDescription"
            name="eventDescription"
            rows="5"
            onChange={(e) => setDescription(e.target.value)}
            value={desc}
            disabled={isLoading}
          />
        </div>
        <div className="mb-15">
          <label>Specific Date:</label>
          <div className="date-picker">
            <DatePicker
              className="text-center text-primary"
              todayButton={"Today"}
              dateFormat="EEEE, MMMM dd"
              selected={startDate.date}
              onChange={(date) => handleStartDate(date)}
              disabled={isLoading}
              minDate={new Date(today.setDate(today.getDate()))}
            />
          </div>
        </div>
        <div>
          <button
            // alignItems="left"
            type="button"
            style={{ marginRight: "10px" }}
            className="btn btn-hubchart btn-hubchart-danger"
            onClick={() => handleUnsetReminder()}
            disabled={isLoading || isRemoving}
          >
            {`${isRemoving ? "Removing..." : "Remove "}`}
          </button>
          <button
            type="button"
            style={{ float: "right" }}
            className="btn btn-hubchart btn-hubchart-primary"
            onClick={() => handleSetReminder()}
            disabled={isLoading || assignedTo === ""}
          >
            {`${
              isLoading && !isRemoving
                ? currentReminder === true
                  ? "Updating..."
                  : "Setting..."
                : currentReminder === true
                ? "Update Reminder"
                : "Set Reminder"
            }`}
          </button>
        </div>
      </div>
    </div>
  );
}

const mySelector = (state) => ({
  patientInfoReduced: state.patient.patientInfoReduced,
});

export default ReminderModal;
