import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Picker } from "emoji-mart";
import {
  setRichText,
} from "../../../actions/ThreadAction";
import { 
  CompositeDecorator, 
  Editor, 
  EditorState, 
  ContentState,
  Modifier, 
  RichUtils, 
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "emoji-mart/css/emoji-mart.css";
import "./RichText.css"

class RichTextInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPicker: false,
      isFocus: false,
    }

    this.state = {editorState: EditorState.createEmpty()};
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.richRef = React.createRef();
    this.emojiRef = React.createRef();
    this.buttonRef = React.createRef();
    
    this.handleClickBox = this.handleClickBox.bind(this);
  };

  componentDidMount() {
    const inputField = document.getElementById("editor");
    const width = inputField.offsetWidth;
    inputField.style.maxWidth = width.toString() + "px";

    window.addEventListener("click", this.handleClickBox);
  };

  componentDidUpdate() {
    if (this.props.richText === "") {
      this.onChange( EditorState.createEmpty() );
    };
  };

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClickBox);
  };

  handleClickBox(e) {
    if (
      this.emojiRef.current && !this.emojiRef.current.contains(e.target) &&
      this.buttonRef.current && !this.buttonRef.current.contains(e.target)
    ) {
      this.setState({ showPicker: false });
    }
  };

  onChange = (editorState) => {
    this.setState({ editorState });

    if (this.richRef.current) {
      this.getHtml(editorState);
    }
  };

  getHtml = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);    
    const html = draftToHtml(rawContent);
    this.props.setRichText(html);

    if (contentState.getPlainText() !== "") {
      let fakeTyping = { target: { value: "typing" }};
      this.props.isTyping(fakeTyping);
    } else {
      let fakeTyping = { target: { value: "" }};
      this.props.isTyping(fakeTyping);
    };
  };

  addEmoji = (emoji) => {
    const { editorState } = this.state;
    const currentContent = editorState.getCurrentContent();
    const contentStateWithEmoji = Modifier.insertText(
      currentContent,
      editorState.getSelection(),
      emoji.native
    );
    this.onChange( EditorState.push(editorState, contentStateWithEmoji, "insert-characters") );
  };

  handlePastedText = (text, html) => {
    const newContentState = Modifier.replaceText(
      this.state.editorState.getCurrentContent(),
      this.state.editorState.getSelection(),
      text,
      null,
    );
    this.onChange( EditorState.push(this.state.editorState, newContentState, "insert-characters"));
    return "handled";
  };

  toggleEmojiPicker = () => {
    this.setState((prevState) => ({ showPicker: !prevState.showPicker }));
  };

  toggleInlineStyle = (style) => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, style));
  };

  toggleBlockType = (style) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, style));
  };

  onEditorFocus = () => {
    this.setState({ isFocused: true });
  };

  onEditorBlur = () => {
    this.setState({ isFocused: false });
  };

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  isListBlockActive = () => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const block = contentState.getBlockForKey(selection.getStartKey());
    const blockType = block.getType();

    return blockType === "unordered-list-item" || blockType === "ordered-list-item";
  };

  render() {
    const isListActive = this.isListBlockActive();
    return (
      <div>
        <button 
          data-toggle="tooltip"
          data-placement="top"
          title="Bold"
          onClick={this.toggleInlineStyle.bind(this, "BOLD")}>
          <strong>
            B
          </strong>
        </button>&nbsp;
        <button 
          data-toggle="tooltip"
          data-placement="top"
          title="Italic"
          onClick={this.toggleInlineStyle.bind(this, "ITALIC")} 
          style={{ fontStyle: "italic", fontFamily: "Times New Roman" }}>
            I
        </button>&nbsp;
        <button 
          data-toggle="tooltip"
          data-placement="top"
          title="Underline"
          onClick={this.toggleInlineStyle.bind(this, "UNDERLINE")} 
          style={{ textDecoration: "underline" }}>
            U
        </button>&nbsp;
        <button 
          data-toggle="tooltip"
          data-placement="top"
          title="Strikethrough"
          onClick={this.toggleInlineStyle.bind(this, "STRIKETHROUGH")} 
          style={{ textDecoration: "line-through" }}>
            S
        </button>&nbsp;
        <button 
          data-toggle="tooltip"
          data-placement="top"
          title="Unordered list"
          onClick={this.toggleBlockType.bind(this, "unordered-list-item")}>
          <i class="fas fa-list fa-sm"></i>
        </button>&nbsp;
        <button 
          data-toggle="tooltip"
          data-placement="top"
          title="Ordered list"
          onClick={this.toggleBlockType.bind(this, "ordered-list-item")}>
          <i class="fas fa-list-ol"></i>
        </button>&nbsp;
        <button 
          data-toggle="tooltip"
          data-placement="top"
          title="Select emoji"
          ref={this.buttonRef}
          onClick={this.toggleEmojiPicker} >
            😃
        </button>&nbsp;
        {this.state.showPicker && (
          <div 
            ref={this.emojiRef}
            style={{ position: "absolute" }}
          >
            <Picker
              style={{
                position: "absolute",
                // top: "",
                bottom: "0px",
                left: "200px",
              }} 
              onSelect={this.addEmoji}
              title="Pick your emoji"
              emoji="point_up"
            />
          </div>
        )}
        <div 
          id="editor"
          className={`editor-container ${this.state.isFocused ? "focused" : ""}`}
          onFocus={this.onEditorFocus}
          onBlur={this.onEditorBlur}
          tabIndex={0}
        >
          <Editor
            editorState={this.state.editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            ref={this.richRef}
            onFocus={this.props.onFocus}
            isClick={this.props.isClick}
            placeholder={isListActive ? "" : this.props.placeholder}
            handlePastedText={this.handlePastedText}
          />
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  richText: state.thread.richText,
});

export default withRouter(
  connect(mapStateToProps, {
    setRichText,
  })(RichTextInput)
);