import React, { Component } from "react";
import Parse from "parse";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { parseAction } from "../../../actions/REST";
import {
  removeMember,
  inviteMember,
  assignAdmin,
  leaveGroup,
  deleteGroup,
  updateGroupName,
  getMembers,
} from "../../../actions/GroupAction";
import { setActiveThread, removeThread, updateNotifSettings } from "../../../actions/ThreadAction";
import Member from "./Member";
import LoadingSpinner from "../Bubble/LoadingSpinner";
import AddMemberModal from "./AddMemberModal";
import GroupWarningModal from "./GroupWarningModal";
import config from "../../../config";

const $ = window.$;
export class Group extends Component {
  constructor() {
    super();
    this.state = {
      groupObject: "",
      activeThread: "",
      isFetchingGroupMembers: false,
      fetchGroupMembersResult: "",
      isFetching: false,
      currentUserIsAdmin: false,
      willEditGroupName: false,
      openAddMemberModal: false,
      openWarningModal: false,
      nameIsUpdating: false,
      failedToUpdateName: false,
      notificationType: 0,
    };

    this.openAddMemberModal = this.openAddMemberModal.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.assignAdmin = this.assignAdmin.bind(this);
    this.openWarningModal = this.openWarningModal.bind(this);
    this.confirmAction = this.confirmAction.bind(this);
    this.updateGroupName = this.updateGroupName.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.state.activeThread.threadId !== this.props.thread.threadId) {
      const { thread = {} } = this.props;
      const { notificationType = 0 } = thread;

      this.setState({
        activeThread: this.props.thread,
        isFetching: true,
        currentUserIsAdmin: false,
        fetchGroupMembersResult: "",
        groupObject: "",
        notificationType,
      });
      this.fetchMembers(this.props.thread.threadId);
      // this.fetchGroupObject(this.props.thread.threadId);
    } else {
      /**
       * We will refetch for error results
       */
      if (this.state.fetchGroupMembersResult === "error") {
        const { thread = {} } = this.props;
        const { notificationType = 0 } = thread;

        this.setState({
          fetchGroupMembersResult: "",
          activeThread: this.props.thread,
          isFetching: true,
          currentUserIsAdmin: false,
          groupObject: "",
          notificationType,
        });
        this.fetchMembers(this.props.thread.threadId);
        // this.fetchGroupObject(this.props.thread.threadId);
      }
    }
    return null;
  }

  componentDidUpdate() {}

  fetchMembers(groupObjectId) {
    const method = "post";
    const url = config.BASE_URL + "/parse/functions/getCircleMembersV3";
    const data = {
      circleObjectId: groupObjectId,
    };

    parseAction(method, url, data)
      .then((response) => {
        if (!response.result) {
          this.setState({
            isFetching: false,
          });
          return;
        }
        var group = response.result;

        const { administrators = [], regMembers = [] } = group;

        let currentUserIsAdmin = false;
        for (let x = 0; x < administrators.length; x++) {
          regMembers.forEach((user, index) => {
            if (user.objectId === administrators[x].objectId) {
              if (regMembers[index]) {
                if (regMembers[index].objectId === Parse.User.current().id) {
                  currentUserIsAdmin = true;
                }
              }
              regMembers[index].isAdministrator = true;
            }
          });
        }

        if (groupObjectId === this.state.activeThread.threadId) {
          this.setState({
            isFetching: false,
            groupObject: group,
            fetchGroupMembersResult: "success",
            currentUserIsAdmin: currentUserIsAdmin,
          });
          this.props.saveGroupMembers(groupObjectId, group);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchGroupObject(groupObjectId) {
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: groupObjectId,
      },
    })
      .then((result) => {
        // Check if result is empty
        if (result.results.length === 0) {
          this.setState({
            isFetching: false,
          });
          return;
        }
        var group = result.results[0];
        group.members = []; //Set members as empty array
        this.getMembers(group)
          .then((result) => {
            let currentUserIsAdmin = false;
            for (let x = 0; x < group.administrators.length; x++) {
              result.regMembers.forEach((user, index) => {
                if (user.objectId === group.administrators[x].objectId) {
                  if (result.regMembers[index]) {
                    if (result.regMembers[index].objectId === Parse.User.current().id) {
                      currentUserIsAdmin = true;
                    }
                  }
                  result.regMembers[index].isAdministrator = true;
                }
              });
            }

            if (groupObjectId === this.state.activeThread.threadId) {
              group.members = result.regMembers;
              group.orgAdmins = result.orgAdmins;
              group.orgMembers = result.orgMembers;
              this.setState({
                isFetching: false,
                groupObject: group,
                fetchGroupMembersResult: "success",
                currentUserIsAdmin: currentUserIsAdmin,
              });
              this.props.saveGroupMembers(groupObjectId, result);
            }
          })
          .catch((error) => {
            this.setState({
              isFetching: false,
              groupObject: group,
              currentUserIsAdmin: false,
              fetchGroupMembersResult: "error",
            });
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getMembers(group, callback) {
    return this.props.getMembers(group.objectId);
  }

  /**
   *
   */
  removeMember(userObjectId) {
    this.props
      .removeMember(userObjectId, this.state.groupObject.uuid)
      .then((circleMember) => {
        let tempMembers = [...this.state.groupObject.members.filter((user) => user.objectId !== userObjectId)];
        let tempGroupObject = {
          ...this.state.groupObject,
          members: tempMembers,
        };

        this.setState({
          groupObject: tempGroupObject,
        });

        this.props.saveRegularMembers(tempGroupObject.objectId, tempMembers);
      })
      .catch(() => {});
  }

  /**
   *
   */
  inviteMember(toBeInvited) {
    toBeInvited.map((contact) => {
      this.props
        .inviteMember(contact, this.state.groupObject.uuid)
        .then((circleMember) => {
          let tempMembers = [...this.state.groupObject.members, contact];
          let tempGroupObject = {
            ...this.state.groupObject,
            members: tempMembers,
          };
          this.setState({
            groupObject: tempGroupObject,
          });

          this.props.saveRegularMembers(tempGroupObject.objectId, tempMembers);
        })
        .catch((error) => {
          //FIXME error when inviting member
        });
    });
  }

  /**
   *
   */
  assignAdmin(member) {
    this.props
      .assignAdmin(member.objectId, this.props.thread.threadId)
      .then((circle) => {
        let tempMembers = [
          ...this.state.groupObject.members.map((data, i) => {
            if (data.objectId === member.objectId) {
              return {
                ...data,
                isAdministrator: true,
              };
            }
            return data;
          }),
        ];

        let tempGroupObject = {
          ...this.state.groupObject,
          administrators: circle.administrators,
          members: tempMembers,
        };
        this.setState({
          groupObject: tempGroupObject,
        });

        this.props.saveRegularMembers(tempGroupObject.objectId, tempMembers);
      })
      .catch((error) => {
        //FIXME  error when assigning member to admin
      });
  }

  /**
   *
   */
  revokeAdmin() {}

  willEditGroupName() {
    this.setState({
      willEditGroupName: true,
    });
  }

  openAddMemberModal() {
    this.setState({
      openAddMemberModal: true,
    });
  }

  closeAddMemberModal() {
    this.setState({
      openAddMemberModal: false,
    });
  }

  confirmAction(isCreator) {
    const { threads = [], activeTab } = this.props;
    if (isCreator) {
      // if creator it means that user want to delete the group
      this.props.deleteGroup(this.state.groupObject.objectId).then(() => {
        // this.props.setActiveThread(idPath);
        this.props.removeThread(this.state.groupObject.objectId);
        this.props.handleCloseNav();
        if (activeTab === "message") {
          const selectedIndex = threads.map((item) => item.threadId).indexOf(this.state.groupObject.objectId);
          let idPath;
          if (threads[selectedIndex + 1].threadType === "private") {
            idPath = threads[selectedIndex + 1].partnerObjectId;
          } else if (threads[selectedIndex + 1].threadType === "group") {
            idPath = threads[selectedIndex + 1].threadId;
            if (threads[selectedIndex + 1].subgroupSession) {
              idPath = threads[selectedIndex + 1].subgroupSessionId;
            }
          } else if (threads[selectedIndex + 1].threadType === "adhoc") {
            idPath = threads[selectedIndex + 1].threadId;
          }
          this.props.history.push("/u/" + idPath);
        }
      });
    } else {
      this.props.leaveGroup(this.state.groupObject.objectId, Parse.User.current().id).then(() => {
        //   this.props.setActiveThread("");
        this.props.removeThread(this.state.groupObject.objectId);
        this.props.handleCloseNav();
        if (activeTab === "message") {
          const selectedIndex = threads.map((item) => item.threadId).indexOf(this.state.groupObject.objectId);
          let idPath;
          if (threads[selectedIndex + 1].threadType === "private") {
            idPath = threads[selectedIndex + 1].partnerObjectId;
          } else if (threads[selectedIndex + 1].threadType === "group") {
            idPath = threads[selectedIndex + 1].threadId;
            if (threads[selectedIndex + 1].subgroupSession) {
              idPath = threads[selectedIndex + 1].subgroupSessionId;
            }
          } else if (threads[selectedIndex + 1].threadType === "adhoc") {
            idPath = threads[selectedIndex + 1].threadId;
          }
          this.props.history.push("/u/" + idPath);
        }
      });
    }
  }

  closeModal() {
    this.setState({
      openWarningModal: false,
    });
  }

  openWarningModal() {
    this.setState({
      openWarningModal: true,
    });
  }

  updateGroupName(e) {
    e.preventDefault();
    let groupName = this.refs.groupName.value;

    if (groupName === "" || this.state.groupObject.name === groupName) {
      this.setState({
        nameIsUpdating: false,
        willEditGroupName: false,
        failedToUpdateName: false,
      });
      return;
    }

    this.setState({
      nameIsUpdating: true,
    });

    let self = this;

    this.props
      .updateGroupName(groupName, this.state.groupObject.objectId)
      .then(() => {
        self.setState({
          nameIsUpdating: false,
          willEditGroupName: false,
          failedToUpdateName: false,
          groupObject: {
            ...self.state.groupObject,
            name: groupName,
            nameLower: groupName.toLowerCase(),
          },
        });
      })
      .catch((error) => {
        self.setState({
          failedToUpdateName: true,
          nameIsUpdating: false,
          willEditGroupName: false,
        });
      });
  }

  handleChange(e) {
    const { activeThread } = this.state;
    const notificationType = Number(e.target.value);

    this.setState({
      notificationType,
    });

    this.props.updateNotifSettings(activeThread.objectId, notificationType);
  }

  render() {
    const group = this.state.groupObject;

    if (group === "") return null;

    let canEditName = false;
    let currentUserObjectId = Parse.User.current().id;
    if (group.owner.objectId === currentUserObjectId) {
      canEditName = true;
    }

    let _hasUser = group.administrators.find((c) => c.objectId === currentUserObjectId);
    if (_hasUser) canEditName = true;

    let imageURL = require("../../../assets/images/group-default.png");
    if (typeof group.image !== "undefined") {
      imageURL = group.image.url;
    }

    let isCreator = group.owner.objectId === Parse.User.current().id;
    const Members = (group.members || []).map((data) => {
      return (
        <Member
          key={data.objectId}
          member={data}
          isCreator={isCreator}
          isAdmin={this.state.currentUserIsAdmin}
          removeMember={this.removeMember}
          assignAdmin={this.assignAdmin}
        />
      );
    });

    const { notificationType } = this.state;

    return (
      <div className="setting-container">
        {this.state.isFetching && <LoadingSpinner />}
        <div className="img-container">
          <img src={imageURL} alt="Group Profile Picture" />
          <div className="Form-Container" style={{ margin: "0" }}>
            <form onSubmit={this.updateGroupName} style={{ position: "relative" }}>
              <div className="form-group" style={{ paddingRight: "50px" }}>
                {this.state.willEditGroupName && (
                  <div className="card" style={{ paddingLeft: "10px" }}>
                    <input
                      ref="groupName"
                      type="text"
                      id="groupName"
                      placeholder="Enter you organization name"
                      style={{ textTransform: "uppercase" }}
                      defaultValue={group.name}
                    />
                    <div
                      className="form-group Check-Container"
                      style={{ position: "absolute", top: "0", right: "-55px" }}
                    >
                      {this.state.nameIsUpdating ? (
                        <i
                          className="fas fa-circle-notch fa-spin"
                          style={{
                            color: "rgb(74, 173, 82)",
                            position: "relative",
                            left: "-11px",
                            top: "7px",
                          }}
                        ></i>
                      ) : (
                        <button
                          ref="submit"
                          type="submit"
                          className="Confirm-Button"
                          style={{
                            width: "100%",
                            height: "100%",
                            marginLeft: "5px",
                          }}
                        >
                          <i className="fas fa-check"></i>
                        </button>
                      )}
                    </div>
                  </div>
                )}

                {!this.state.willEditGroupName && (
                  <div>
                    <p style={{ padding: "0" }}>{(group.name || "").toUpperCase()}</p>
                    {canEditName && (
                      <span onClick={this.willEditGroupName.bind(this)} className="hc-primary-text">
                        Edit Name
                      </span>
                    )}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="detail-container">
          <h5 className="hc-primary-text">Details</h5>
          <p>{group.groupDescription}</p>
        </div>
        <div className="detail-container">
          <h5 className="hc-primary-text">Group Notifications</h5>
          <div className="radio">
            <label className={notificationType === 0 ? "hc-primary-text" : ""}>
              <input
                className="mr-1"
                type="radio"
                name="optradio"
                value="0"
                checked={notificationType === 0}
                onChange={this.handleChange}
              />
              <i className="fa fa-exclamation-triangle mr-1"></i>
              Important Messages Only
            </label>
          </div>
          <div className="radio">
            <label className={notificationType === 1 ? "hc-primary-text" : ""}>
              <input
                className="mr-1"
                type="radio"
                name="optradio"
                value="1"
                checked={notificationType === 1}
                onChange={this.handleChange}
              />
              <i className="fa fa-bell mr-1"></i>
              Notify All
            </label>
          </div>
          <div className="radio disabled">
            <label className={notificationType === 2 ? "hc-primary-text" : ""}>
              <input
                className="mr-1"
                type="radio"
                name="optradio"
                value="2"
                checked={notificationType === 2}
                onChange={this.handleChange}
              />
              <i className="fa fa-bell-slash mr-1"></i>
              <span>Mute All Notifications</span>
            </label>
          </div>
        </div>
        <div className="detail-container" style={{ marginTop: "15px", paddingTop: "20px" }}>
          {isCreator ? (
            <button
              onClick={this.openWarningModal}
              className="btn btn-hubchart btn-hubchart-danger"
              style={{ width: "100%", fontSize: "18px" }}
            >
              DELETE GROUP
            </button>
          ) : (
            <button
              onClick={this.openWarningModal}
              className="btn btn-hubchart btn-hubchart-danger"
              style={{ width: "100%", fontSize: "18px" }}
            >
              LEAVE GROUP
            </button>
          )}
        </div>

        {this.state.openAddMemberModal && (
          <AddMemberModal
            members={group.members || []}
            inviteMember={this.inviteMember.bind(this)}
            isOpen={this.state.openAddMemberModal}
            modalClose={this.closeAddMemberModal.bind(this)}
          />
        )}
        {this.state.openWarningModal && (
          <GroupWarningModal
            isCreator={isCreator}
            isOpen={this.state.openWarningModal}
            modalClose={this.closeModal.bind(this)}
            confirmAction={() => this.confirmAction(isCreator)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, {
    updateGroupName,
    removeMember,
    inviteMember,
    assignAdmin,
    leaveGroup,
    deleteGroup,
    setActiveThread,
    removeThread,
    updateNotifSettings,
    getMembers,
  })(Group)
);
