import React, { Component } from "react";
import Parse from "parse";
import { parseAction } from "../../../actions/REST";
import config from "../../../config";
import { ToastContainer } from 'react-toastify';
import Notify from "../../Common/Notify";

const $ = window.$;
export class UserAvailability extends Component {
  constructor() {
    super();
    this.state = {
      reason: "",
      isloading: false,
    };
    this.reasonRef = React.createRef();
  }

  componentDidMount() {
    this.reasonRef.current.focus();
    if (this.props.isOpen) {
      $("#userReasonModal").modal({
        backdrop: "static",
        keyboard: false
      });
      $("#userReasonModal").css({
        "z-index": "3000",
        "position": "fixed"
      });
      $("#userReasonModal").modal("show");
    } else {
      $("#userReasonModal").modal("hide");
    }
    $("#userReasonModal").on(
      "hidden.bs.modal",
      function(e) {
        this.props.modalClose();
      }.bind(this)
    );
  }

  confirm() {
    this.setState({ isloading: true });

    const user = Parse.User.current();

    try {
      user.set({ 
        isAvailable: false,
        unavailabilityReason: this.state.reason,
      });
      user.save();
      parseAction(
        "post",
        config.BASE_URL + "/parse/functions/updateAvailability",
        {
          isAvailable: false,
          unavailabilityReason: this.state.reason,
        },{
          "Content-Type": "application/json",
        }
      )
      .then(() => {
        this.setState({ isloading: false });
        this.props.modalClose();
        this.props.handleChange(this.state.reason);
      });
      $("#userReasonModal").modal("hide");

    } catch (err) {
      Notify.errorWithTitle(
        "Failed to update User status",
        err.message,
      );
      throw err;
    }
  }

  onClose() {
    this.props.modalClose();
    this.props.handleChange(false);
    $("#userReasonModal").modal("hide");
  }

  render() {
    return (
      <div id="userReasonModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="hc-primary-text" style={{ fontSize: "1.25rem" }}>
                User Availability
              </h5>
              <button 
                style={{ position: "absolute", top: "15px", right: "15px" }} 
                type="button" 
                className="close"
                onClick={() => this.onClose()}>
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div className="modal-body" style={{ paddingBottom: "0px" }}>
              <div
                className="Form-Container"
                style={{ margin: "20px 0", maxWidth: "100%" }}
              >
                <form>
                  <div className="form-group">
                    <div className="card">
                      <input
                        ref={this.reasonRef}
                        type="text"
                        id="reason"
                        placeholder="Reason for unavailability"
                        value={this.state.reason}
                        onChange={(e) => this.setState({ reason: e.target.value })}
                        disabled={this.state.isloading}
                      />
                      <i className="fas fa-user hc-primary-text"></i>
                    </div>
                  </div>
                  <div className="form-group" style={{ textAlign: "right" }}>
                    <button
                      type="submit"
                      style={{ marginRight: "15px" }}
                      className="btn btn-hubchart btn-hubchart-primary"
                      onClick={() => this.confirm()}
                      disabled={this.state.isloading}
                    >
                      {!this.state.isloading ? 
                        "Update Status" 
                        :
                        "Updating..."
                      }
                    </button>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserAvailability;
