import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Parse from "parse";
import CriticalModal from "./index";
import { SET_OPEN_CRITICAL } from "../../../../actions/types";
import {
  setActiveTab,
  getAllCritical,
  completeActionItem,
} from "../../../../actions/ThreadAction";

const CriticalComponent = ({ history }) => {
  const dispatch = useDispatch();

  const { allToDosPendingCritical, hasCritical, openCritical } = useSelector(
    mySelector,
    shallowEqual
  );

  const [criticalModalOpened, setCriticalModalOpened] = useState(false);

  useEffect(() => {

    let today = new Date().toISOString().slice(0, 10); // Format: YYYY-MM-DD
    let lastShownDate = localStorage.getItem('modalLastShownDate');

    let critical = [];
    let hasCritical = false;

    if (Parse.User.current()) { // if user is login
      dispatch(getAllCritical())
      .then((data) => {
        critical = data;
        critical.map((task) => {
          if (task.status === "PENDING") {
            hasCritical = true;
          }
        });

        // store current month
        localStorage.setItem('lastShownMonth', new Date().getMonth());

        if (hasCritical && lastShownDate !== today) {
          setCriticalModalOpened(true);
          localStorage.setItem('modalLastShownDate', today);
        }
      });
    }

    // setInterval(() => {
    //   let critical = [];
    //   let hasCritical = false;
    //   dispatch(getAllCritical()).then((data) => {
    //     critical = data;
    //     critical.map((task) => {
    //       if (task.status === "PENDING") {
    //         hasCritical = true;
    //       }
    //     });
    //     if (Parse.User.current() && hasCritical) {
    //       setCriticalModalOpened(true);
    //     }
    //   });
    //   // }, 60000); // 5 mins
    //   // }, 20000); // 10 sec
    // }, 21600000); // 6 hrs
  }, []);

  useEffect(() => {
    if (openCritical == true) {
      setCriticalModalOpened(true);
    }
  }, [openCritical]);

  const closeCriticalModal = () => {
    setCriticalModalOpened(false);
    dispatch({
      type: SET_OPEN_CRITICAL,
      value: false
    });
  }

  const openThread = (cthread) => {
    setCriticalModalOpened(false);
    dispatch({
      type: SET_OPEN_CRITICAL,
      value: false
    })
    // dispatch(completeActionItem(cthread.objectId));
    dispatch(setActiveTab("message"));

    history.push({
      pathname: "/u/" + cthread.threadId,
    });
  };

  return (
    <div>
      {criticalModalOpened && (
        <CriticalModal
          isOpen={criticalModalOpened}
          onClose={closeCriticalModal}
          openThread={openThread}
        />
      )}
    </div>
  );
};

const mySelector = (state) => ({
  allToDosPendingCritical: state.thread.allToDosPendingCritical,
  hasCritical: state.thread.hasCritical,
  openCritical: state.thread.openCritical,
});

export default CriticalComponent;
