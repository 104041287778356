import React, { useMemo } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Parse from "parse";
import Logo from "../../../assets/images/logo-blue.png";
import defaultProfilePic from "../../../assets/images/default.png";
import { showProfileSidebar } from "../../../actions/GlobalAction";
import { setActiveTab, setActiveThread, setAttentionedMessage } from "../../../actions/ThreadAction";
import "./index.css";
import NavbarSearch from "./NavbarSearch";
import ActivityList from "./ActivityList";

const Navbar = ({ history }) => {
  const dispatch = useDispatch();

  // Get values from redux store
  const {
    activeTab,
    unreadMessagesTotal,
    conferenceScheduleCount,
  } = useSelector(
    (state) => ({
      activeTab: state.thread.activeTab,
      unreadMessagesTotal: state.thread.unreadMessagesTotal,
      conferenceScheduleCount: state.conference.conferenceScheduleCount,
    }),
    shallowEqual
  );

  const imageUrl = useMemo(() => {
    return getImageUrl();
  }, []);

  const handleProfileClick = () => {
    dispatch(showProfileSidebar());
  };

  const handleTabClick = (tab) => {
    dispatch(setActiveTab(tab));
  };

  const handleThreadAndHistory = () => {
    dispatch(setActiveThread(""));
    history.push({
      pathname: "/u",
    });
  };

  const unreadBadge = useMemo(() => {
    return unreadMessagesTotal > 99 ? "99+" : unreadMessagesTotal;
  }, [unreadMessagesTotal]);

  return (
    <nav className="navbar navbar-expand justify-content-center hc-nav px-3">
      <span className="navbar-brand">
        <img
          className="d-none d-sm-inline"
          width="40"
          height="40"
          src={Logo}
          alt="H"
        ></img>
        <span
          className="hc-primary-text d-none d-md-inline"
          style={{ fontWeight: "500" }}
        >
          HUBCHART
        </span>
      </span>
      <div className="text-center hc-nav-link-container mx-auto text-cls-nav">
        <Link
          onClick={() => {
            handleThreadAndHistory();
            handleTabClick("dashboard");
          }}
          isActive={activeTab === "dashboard"}
          iconClass="fad fa-fw fa-home"
          label="Home"
        />

        <Link
          badge={unreadBadge}
          onClick={() => {
            handleThreadAndHistory();
            handleTabClick("message");
          }}
          isActive={activeTab === "message"}
          iconClass="fad fa-fw fa-comment"
          label="Inbox"
        />

        <Link
          onClick={() => {
            handleThreadAndHistory();
            handleTabClick("group");
          }}
          isActive={activeTab === "group"}
          iconClass="fad fa-fw fa-users"
          label="Group"
        />

        <Link
          onClick={() => {
            handleThreadAndHistory();
            handleTabClick("chart");
          }}
          isActive={activeTab === "chart"}
          iconClass="fad fa-fw fa-clipboard-list"
          label="Patient hub"
        />

        <Link
          onClick={() => {
            handleThreadAndHistory();
            handleTabClick("contact");
          }}
          isActive={activeTab === "contact"}
          iconClass="fad fa-fw fa-address-book"
          label="Contacts"
        />

        <Link
          badge={conferenceScheduleCount}
          onClick={() => {
            // handleThreadAndHistory();
            handleTabClick("conference");
          }}
          isActive={activeTab === "conference"}
          iconClass="fad fa-fw fa-calendar"
          label="Calendar"
        />

        {/* <Link
          badge={('')}
          onClick={() => {
            handleThreadAndHistory();
            handleTabClick("progressNote");
          }}
          isActive={activeTab === "progressNote"}
          iconClass="fad fa-fw fa-file-signature"
          label="Progress note"
        /> */}
      </div>
      <div className="d-flex justify-content-end">
        <NavbarSearch 
          history={history}
        />
        <ActivityList history={history} />
        <div className="Profile-Setting" onClick={handleProfileClick}>
          <img src={imageUrl} alt="Profile Image" />
          <div className="Menu-Icon-Container">
            <i className="fas fa-bars"></i>
          </div>
        </div>
      </div>
    </nav>
  );
};

const Link = ({ iconClass, label, isActive, onClick, badge }) => {
  const activeClass = isActive ? "active" : "";

  return (
    <a
      onClick={(e) => onClick(e)}
      href="#"
      className={`nav-link d-flex flex-column ${activeClass} m-1`}
      data-toggle="collapse"
    >
      <span style={{ position: "relative" }}>
        <span className={iconClass} style={{ fontSize: "25px" }}></span>
        {badge ? (
          <span
            className="hc-icon-badge"
            style={{
              width: "12px",
              height: "12px",
              right: "14px",
              top: "-4px",
              borderRadius: "50%",
            }}
          >
            {}
          </span>
        ) : (
          ""
        )}
      </span>
      <span
        className="d-none d-sm-inline text-nowrap text-truncate"
        style={{ fontSize: "14px" }}
      >
        {label}
      </span>
    </a>
  );
};

const getImageUrl = () => {
  const picture = Parse.User.current().get("picture");

  return picture ? picture._url : defaultProfilePic;
};

export default Navbar;
