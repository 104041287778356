import React, { useEffect, useMemo, Fragment } from "react";
import TextareaAutosize from "react-textarea-autosize";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useState } from "react";
import Parse from "parse";
import Modal from "../../../Common/Modal";
import ActionViewModal from "./ActionViewModal";
import DiscussionModal from "../DiscussionModal";
import OverlayLoader from "../../../Common/OverlayLoader";
import OverlayPlaceholder from "../../../Common/OverlayPlaceholder";
import { parseAction } from "../../../../actions/REST";
import { calculateAge } from "../../../../helper/util";
import config from "../../../../config";
import { Input, Select, Label, Spinner } from "../../../Common/Forms";
import { useDispatch } from "react-redux";
import DOMPurify from "dompurify";
import {
  createActionItems,
  completeActionItem,
  updateActionItem,
  sendNewMessage,
  randomID,
  setActiveTab,
  getActionItemDiscussion,
  sendGroupMessage,
} from "../../../../actions/ThreadAction";
// import { sendGroupMessage } from "../../../../api/Message";
import ProfilePic from "../../../Common/ProfilePic";
import _ from "lodash";
import "./actionModal.css";

const ActionsModal = ({
  thread,
  threadId,
  isOpen,
  onClose,
  refreshData,
  activeTab,
  selectedActionItem = "",
  handleActionUpdate,
  history,
}) => {
  const [circle, setCircle] = useState({});
  const [members, setMembers] = useState([]);
  const [actionItems, setActionItems] = useState([]);
  const [actionItemsOriginal, setActionItemsOriginal] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [addItemShown, setAddItemShown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("all");

  const fetchPatientInfo = async (threadId) => {
    const url = config.BASE_URL + "/parse/classes/Circle";

    const params = {
      where: {
        objectId: threadId,
      },
      include: ["actionItems", "actionItems.assignedTo", "actionItems.createdBy", "members"],
    };

    const res = await parseAction("get", url, params);

    const circle = res.results[0] || {};
    // const sorted = sortActionItems(circle.actionItems || []);

    setCircle(circle);
    // setActionItems(sorted);
    // setActionItemsOriginal(sorted);
  };

  const fetchMembers = async (threadId) => {
    const url = config.BASE_URL + "/parse/functions/getCircleMembers";

    const params = {
      circleObjectId: threadId,
    };

    const res = await parseAction("post", url, params);

    const { orgAdmins, orgMembers, regMembers } = res.result;

    const allMembers = [...orgAdmins, ...orgMembers, ...regMembers];

    const uniqueMembers = _.uniqBy(allMembers, "objectId");

    setMembers(uniqueMembers);
  };

  const fetchToDos = async (threadId) => {
    const url = config.BASE_URL + "/parse/functions/getAllTodos";

    const params = {
      threadId: threadId,
      page: 0,
      currentToDos: [],
      pageSize: 999,
      status: "ALL",
    };

    const res = await parseAction("post", url, params);
    const sorted = sortActionItems(res.result.todos || []);

    setActionItems(sorted);
    setActionItemsOriginal(sorted);
  };

  const handleNewItem = (actionItem) => {
    setActionItems((actionItems) => {
      return [actionItem, ...actionItems];
    });

    setAddItemShown(false);
  };

  const sortActionItems = (actionItems) => {
    const sorted = _.orderBy(actionItems, [(obj) => new Date(obj.createdAt)], ["desc"]);

    return sorted;
  };

  const handleCompleteItem = (itemId) => {
    setActionItems((actionItems) => {
      return actionItems.map((item) => {
        if (item.objectId === itemId) {
          if (handleActionUpdate) 
            handleActionUpdate(itemId);
          return {
            ...item,
            status: "DONE",
          };
        }

        return item;
      });
    });
  };

  const handleOnAssigneeChange = (selItem, val, idx, propName) => {
    const selectedMember = members.filter((itm) => val == itm.objectId);

    let newActionItems = [...actionItems];
    const item = {
      ...selItem,
      [propName]: selectedMember[0],
    };

    newActionItems[idx] = item;
    setActionItems(newActionItems);
  };

  const handleOnActionChange = (selItem, val, idx, propName) => {
    let newActionItems = [...actionItems];
    const item = {
      ...selItem,
      [propName]: val,
    };

    newActionItems[idx] = item;
    setActionItems(newActionItems);
  };

  const handleFilteredActionItems = (state) => {
    setSelectedFilter(state);
    if (state === "all") {
      setActionItems(actionItemsOriginal);
    }
    if (state === "assigned_to_me") {
      const currentUserId = Parse.User.current().id;
      const filtered = actionItemsOriginal.filter((item) => {
        if (!item.assignedTo) {
          return false;
        }
        if (item.assignedTo && item.assignedTo.objectId === currentUserId) {
          return true;
        }
        return false;
      });
      // const filtered = actionItemsOriginal.filter((item) => {
      //   const filteredAssignees = item.assignees.filter(
      //     (assignee) => assignee.objectId === currentUserId
      //   );
      //   if (filteredAssignees.length > 0) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // });

      setActionItems(filtered);
    }
    if (state === "done") {
      const filtered = actionItemsOriginal.filter((item) => {
        if (item.status === "DONE") {
          return true;
        }
        return false;
      });
      setActionItems(filtered);
    }
    if (state === "pending") {
      const filtered = actionItemsOriginal.filter((item) => {
        if (item.status === "PENDING") {
          return true;
        }
        return false;
      });
      setActionItems(filtered);
    }
  };

  useEffect(() => {
    setLoading(true);

    Promise.all([fetchPatientInfo(threadId), fetchMembers(threadId), fetchToDos(threadId)])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [threadId]);

  return (
    <Modal size="modal-xl" shown={isOpen} onHide={onClose}>
      <ModalHeader />

      <ModalBody
        style={{
          height: "100%",
          overflowY: "auto",
          paddingTop: "0",
          paddingBottom: "0",
        }}
      >
        <PatientDetails patientInfo={circle} />

        <hr className="m-3" />

        <ActionForm
          onNewItem={handleNewItem}
          circleId={threadId}
          setAddItemShown={setAddItemShown}
          addItemShown={addItemShown}
          onCancel={() => setAddItemShown(false)}
          activeTab={activeTab}
          refreshData={refreshData}
          members={members}
          thread={thread}
        />
        <div style={{ overflowX: "auto" }}>
          <div
            className="btn-list-tasks"
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
              marginTop: "3px",
            }}
          >
            <button
              className={`btn btn-sm ${selectedFilter === "all" ? "btn-warning" : "btn-secondary"}`}
              style={{ marginRight: 5 }}
              onClick={() => handleFilteredActionItems("all")}
            >
              All
            </button>
            <button
              className={`btn btn-sm ${selectedFilter === "assigned_to_me" ? "btn-warning" : "btn-secondary"}`}
              style={{ marginRight: 5 }}
              onClick={() => handleFilteredActionItems("assigned_to_me")}
            >
              Assigned to me
            </button>
            <button
              className={`btn btn-sm ${selectedFilter === "done" ? "btn-warning" : "btn-secondary"}`}
              style={{ marginRight: 5 }}
              onClick={() => handleFilteredActionItems("done")}
            >
              Done
            </button>
            <button
              className={`btn btn-sm ${selectedFilter === "pending" ? "btn-warning" : "btn-secondary"}`}
              style={{ marginRight: 5 }}
              onClick={() => handleFilteredActionItems("pending")}
            >
              Pending
            </button>
          </div>
          <ActionsTable
            onCompleteItem={handleCompleteItem}
            onAssigneeChange={handleOnAssigneeChange}
            onActionChange={handleOnActionChange}
            actionItems={actionItems}
            members={members}
            activeTab={activeTab}
            refreshData={refreshData}
            thread={thread}
            selectedActionItem={selectedActionItem}
            history={history}
            onClose={onClose}
          />
        </div>
        <div style={{ position: "relative", height: "calc(100vh - 380px)" }}>
          <OverlayPlaceholder show={!isLoading && !actionItems.length}>
            <span className="text-empty mr-1">No action items yet.</span>
            <button className="btn btn-link p-0" onClick={() => setAddItemShown(true)}>
              Add new?
            </button>
          </OverlayPlaceholder>
        </div>
        <OverlayLoader isLoading={isLoading} />
      </ModalBody>
      <div className="modal-footer rm-border" />
    </Modal>
  );
};

const ModalHeader = () => {
  return (
    <div className="modal-header">
      <h5 className="text-primary mb-0" style={{ fontSize: "1.25rem" }}>
        Action Items
      </h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

const ModalBody = ({ children, style = {} }) => {
  const styles = {
    ...style,
  };

  return (
    <div className="modal-body" style={styles}>
      {children}
    </div>
  );
};

const PatientDetails = ({ patientInfo }) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-hospital-user"></i> Patient Name:{" "}
          </span>
          <span>{patientInfo.name || "--"}</span>
        </div>
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-address-card"></i> Address:{" "}
          </span>
          <span>{patientInfo.address || "--"}</span>
        </div>
        <div className="patient-details">
          <span>
            {" "}
            <i className="fas fa-fw fa-calendar-day"></i> Birth date:{" "}
          </span>
          <span>{`${calculateAge(patientInfo.dob)}`}</span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-phone"></i> Contact No.:{" "}
          </span>
          <span>{patientInfo.phoneNumber || "--"}</span>
        </div>
        <div className="patient-details">
          <span>
            <i className="fas fa-fw fa-allergies"></i> Allergies:{" "}
          </span>
          <span>{patientInfo.allergies || "--"}</span>
        </div>
      </div>
    </div>
  );
};

const ActionsTable = ({
  actionItems = [],
  members = [],
  addItemShown,
  onCompleteItem,
  onAssigneeChange,
  onActionChange,
  refreshData,
  activeTab,
  thread,
  selectedActionItem = "",
  history,
  onClose,
}) => {
  const thStyles = {
    fontWeight: "bold",
  };

  const tableStyles = {
    color: "#000",
    fontSize: "12px",
  };

  const tableModal = {
    display: "grid",
    gridTemplateColumns: "10% 10% 300px 20% 20% 5% 5%",
    fontWeight: "bold",
    width: "100%",
    color: "#000",
    fontSize: "12px",
    textAlign: "center",
  };

  return (
    <>
      <table className="table table-borderless border shadow-sm" style={tableStyles}>
        <thead className="border-bottom">
          <tr style={thStyles}>
            <td>ASSIGNED TO</td>
            <td style={{ whiteSpace: "nowrap" }}>ASSIGNED BY</td>
            <td className="w-100">ACTIONS</td>
            <td className=" text-center align-middle">DUE DATE</td>
            <td className=" text-center align-middle" style={{ width: "10%" }}>
              DATE ENTERED
            </td>
            <td className=" text-center align-middle">STATUS</td>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {actionItems.map((item, i) => (
            <ActionItem
              key={item.objectId}
              item={item}
              itemIndex={i}
              hideButtons={addItemShown}
              onComplete={onCompleteItem}
              onAssigneeChange={onAssigneeChange}
              onActionChange={onActionChange}
              activeTab={activeTab}
              refreshData={refreshData}
              members={members}
              thread={thread}
              selectedActionItem={selectedActionItem}
              history={history}
              onClose={onClose}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

const ActionForm = ({
  circleId,
  addItemShown,
  setAddItemShown,
  onCancel,
  members = [],
  onNewItem,
  activeTab = "",
  refreshData,
  thread,
}) => {
  const [text, setText] = useState("");
  const [checked, setIsChecked] = useState(false);
  const [assignee, setAssignee] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState({ date: new Date() });
  const [priority, setPriority] = useState(0);

  const dispatch = useDispatch();

  const clearForm = () => {
    setText("");
    setAssignee("");
    setIsChecked(false);
  };

  function handleStartDate(date) {
    if (date === new Date()) return;
    setStartDate({
      ...startDate,
      date: date,
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const assignedTo = members.find((member) => {
      return member.objectId === assignee;
    });

    console.log("assignTp", assignedTo);
    console.log("assignee", assignee);
    

    const data = {
      text,
      assignedTo,
      thread: thread,
      threadName: thread.groupName,
      threadId: circleId,
      due_date: startDate.date,
    };

    setLoading(true);

    const dataV3 = {
      text,
      threadId: circleId,
      attachmentIds: [],
      attention: [assignee],
      urgent: false,
      due_date: startDate.date,
      // actionItem: 
      priorityLevel: parseInt(priority, 10),
    }

    var actionItem = await dispatch(sendGroupMessage(dataV3));

    // var actionItem = await dispatch(createActionItems(circleId, data));
    console.log("actionItem:", actionItem);
    
    actionItem.assignedTo = assignedTo;
    actionItem.createdBy = actionItem.user;
    actionItem.status = "PENDING";
    actionItem.objectId = actionItem.actionItem.objectId;

    /** Prevent duplicate item due to API update */
    // handleAlertSubmit([assignedTo], actionItem);
    setLoading(false);
    onNewItem(actionItem); //////<<<<
    clearForm();
    // refreshData();
  };

  const handleCancel = () => {
    clearForm();
    onCancel();
  };

  function handleAlertSubmit(attArray = [], actionItem) {
    const attNames = attArray.map((att) => att.firstName);
    const text = `${actionItem.text}`;

    createNewMessage(thread, text, attArray, attNames, actionItem);
  }

  function createNewMessage(data, text, attentionArray, attNames, actionItem) {
    let newThread = {
      senderName: "",
      senderObjectId: "",
      threadDetail: "",
      threadType: "group",
      unreadMessageCount: 0,
      threadId: data.threadId,
      objectId: data.threadId,
      groupName: data.groupName,
      groupType: data.groupType,
    };

    createNewMessageThread(newThread, text, attentionArray, attNames, actionItem);
  }

  function createNewMessageThread(newThread, text, attentionArray, attNames = [], actionItem) {
    const date = new Date();
    let newMessage = {
      text: text.trim(),
      originalMessageDate: date,
      threadId: newThread.threadId,
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: Parse.User.current().id,
      },
      objectId: randomID(),
      threadType: "group",
      newMessageStatus: "sending",
      createdAt: date,
      circle: {
        __type: "Pointer",
        className: "Circle",
        objectId: newThread.threadId,
      },
      adhocGroupId: newThread.threadId,
      attention: attentionArray,
      attentionName: attNames,
      actionItem: actionItem.objectId,
    };

    /** Prevent duplicate item due to API update */
    // sendNewMessage(newMessage, newThread)(dispatch);
  }

  if (!addItemShown) {
    return (
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-sm btn-primary ml-auto"
          onClick={() => setAddItemShown(true)}
          disabled={addItemShown}
        >
          <i className="fas fa-plus mr-1"></i>
          Add action item
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="row">
        <div className="col-md-4 pr-md-0 mb-3 mb-md-0">
          <Label>Assign to:</Label>
          <Select className="form-control" value={assignee} onChange={(e) => setAssignee(e.target.value)}>
            <option value="">Choose assignee</option>
            {members.map((member) => (
              <option key={member.objectId} value={member.objectId}>
                {member.displayName}
              </option>
            ))}
          </Select>
        </div>
        {/* <div className="col-md-2 pr-md-0 mb-3 mb-md-0">
          <Label>Due Date:</Label>
          <div className="form-control shadow-sm border-0 rounded-0">
            <DatePicker
              todayButton={"Today"}
              dateFormat="EEEE, MMMM dd"
              selected={startDate.date}
              onChange={(date) => handleStartDate(date)}
            />
          </div>
        </div> */}
        <div className="col-md-4 pr-md-0 mb-3 mb-md-0">
          <Label>Priority:</Label>
          <Select 
            className="form-control" 
            value={priority} 
            onChange={(e) => setPriority(e.target.value)}
          >
            <option value="" disabled>Choose assignee</option>
            <option key={2} value={2}>
              High Priority
            </option>
            <option key={1} value={1}>
              Medium Priority
            </option>
            <option key={0} value={0}>
              Routine Priority
            </option>
          </Select>
        </div>
        {/* <div className="col-md-2 pr-md-0 mb-3 mb-md-0">
          <Label>Send Reminder:</Label>
          <br />
          <div style={{ marginTop: "6px" }}>
            <Input
              type="checkbox"
              onChange={e => setIsChecked(e.target.checked)}
              checked={checked}
            />
          </div>
        </div> */}
        <div className="col-md-2 d-flex align-items-start" style={{ marginTop: "26px" }}>
          <button
            type="button"
            className="btn btn-sm btn-light mr-1"
            onClick={() => handleCancel()}
            style={{ marginLeft: "auto", width: "35px" }}
          >
            <i className="fas fa-times text-danger"></i>
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-light"
            disabled={isLoading || !assignee || !text}
            style={{ marginRight: "12px", width: "35px" }}
          >
            {isLoading ? (
              <div className="spinner-border spinner-border-sm text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <i className="fas fa-check text-primary"></i>
            )}
          </button>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-8 pr-md-0 mb-3 mb-md-0">
          <Label>Action:</Label>
          <TextareaAutosize
            className="form-control shadow-sm border-0"
            placeholder="Enter what needs to be done"
            onChange={(e) => setText(e.target.value)}
            style={{
              borderRadius: "0px",
              resize: "none",
            }}
            value={text}
            required
          />
        </div>
      </div>
    </form>
  );
};

const ActionItem = ({
  item = {},
  itemIndex,
  hideButtons,
  onComplete,
  onAssigneeChange,
  onActionChange,
  refreshData,
  activeTab,
  members,
  thread,
  selectedActionItem = "",
  history,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isItemChecked, setIsItemChecked] = useState(false);
  const [isMoreAction, setIsMoreAction] = useState(false);

  const isComplete = useMemo(() => {
    return item.status === "DONE";
  }, [item.status]);

  const handleComplete = async () => {
    setLoading(true);

    await dispatch(completeActionItem(item.objectId));

    setLoading(false);
    onComplete(item.objectId);
    if (activeTab === "chart") refreshData();
  };

  const handleUpdate = async (assignedTo, dueDate) => {
    setIsMoreAction(false);
    // if (item.due_date) {
      item.due_date = dueDate;
    // }
    setLoading(true);
    const actionItem = await dispatch(updateActionItem(item)).then(() => {
      if (item.due_date) {
        item.due_date.iso = dueDate;
      }
    });
    setLoading(false);
    setIsEditMode(false);

    if (isItemChecked) {
      handleAlertSubmit([assignedTo], actionItem);
    }

    if (activeTab === "chart") refreshData();
  };

  const handleDelete = async () => {};

  function handleAlertSubmit(attArray = [], actionItem) {
    const attNames = attArray.map((att) => att.firstName);
    const text = "Your task is updated on action items.";

    createNewMessage(thread, text, attArray, attNames, actionItem);
  }

  function createNewMessage(data, text, attentionArray, attNames, actionItem) {
    let newThread = {
      senderName: "",
      senderObjectId: "",
      threadDetail: "",
      threadType: "group",
      unreadMessageCount: 0,
      threadId: data.threadId,
      objectId: data.threadId,
      groupName: data.groupName,
      groupType: data.groupType,
    };

    createNewMessageThread(newThread, text, attentionArray, attNames, actionItem);
  }

  function createNewMessageThread(newThread, text, attentionArray, attNames = [], actionItem) {
    const date = new Date();
    let newMessage = {
      text: text.trim(),
      originalMessageDate: date,
      threadId: newThread.threadId,
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: Parse.User.current().id,
      },
      objectId: randomID(),
      threadType: "group",
      newMessageStatus: "sending",
      createdAt: date,
      circle: {
        __type: "Pointer",
        className: "Circle",
        objectId: newThread.threadId,
      },
      adhocGroupId: newThread.threadId,
      attention: attentionArray,
      attentionName: attNames,
      actionItem: actionItem.objectId,
    };

    sendNewMessage(newMessage, newThread)(dispatch);
    setIsItemChecked(false);
  }

  function openThread(threadId) {
    onClose();
    dispatch(setActiveTab("message"));

    history.push({
      pathname: "/u/" + threadId,
    });
  }

  function openCommet() {
    setOpenDiscussion(true);
  };

  function handleHtmlText(text) {
    const html_string = text;
    const cleanHTML = DOMPurify.sanitize(html_string);

    return <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />;
  }

  function removeHtmlTag(text) {
    const cleanHTML = DOMPurify.sanitize(item.text);
    const tempDiv = document.createElement("span");
    tempDiv.innerHTML = cleanHTML;
    const cleanText = tempDiv.innerText;
    return cleanText;  
  }

  var today = new Date();
  var lines = item.text.split(/\r\n|\r|\n/).length;
  var due_date = item.due_date ? new Date(item.due_date.iso) : "";
  var past_due = item.due_date && due_date < new Date() && item.status === "PENDING" ? true : false;
  var allow_update = Parse.User.current().id === item.createdBy.objectId ? true : false;
  var allow_mark_done =
    Parse.User.current().id === item.createdBy.objectId || Parse.User.current().id === (item.assignedTo || {}).objectId
      ? true
      : false;

  const [dueDate, setDueDate] = useState({ date: due_date });
  const [openActionView, setOpenActionView] = useState(false);
  const [openDiscussion, setOpenDiscussion] = useState(false);

  return (
    <tr
      className={item.objectId === selectedActionItem ? "active-action-item" : ""}
      style={{ backgroundColor: `${past_due ? "#f5d3d0" : ""}` }}
    >
      <td className="text-left align-middle" style={{ display: "flex" }}>
        {item.assignedTo && !isEditMode && <AssignedTo user={item.assignedTo} />}
        {item.assignedTo && isEditMode && (
          <Select
            className="form-control"
            value={item.assignedTo.objectId}
            onChange={(e) => onAssigneeChange(item, e.target.value, itemIndex, "assignedTo")}
          >
            <option value="" disabled>
              Choose assignee
            </option>
            {members.map((member) => (
              <option key={member.objectId} value={member.objectId}>
                {member.displayName}
              </option>
            ))}
          </Select>
        )}
        {/* {(!item.assignedTo && item.createdBy) && (
          <AssignedTo user={item.createdBy} />
        )} */}
        {!item.assignedTo && (
          <div className="text-empty" style={{ textAlign: "left" }}>
            None
          </div>
        )}
      </td>
      <td style={{ whiteSpace: "nowrap" }}>
        <Label>{item.createdBy ? item.createdBy.displayName : item.user.displayName}</Label>
      </td>
      <td className="">
        {!isEditMode && !isMoreAction && (
          <label
            className="action-text"
            style={{
              display: "flex",
              margin: "0px",
              height: "55px",
              whiteSpace: "pre-line",
              overflow: "hidden",
            }}
          >
            {handleHtmlText(item.text)}
          </label>
        )}
        {isMoreAction && (
          <label
            className="action-text"
            style={{
              display: "flex",
              whiteSpace: "pre-line",
            }}
          >
            {handleHtmlText(item.text)}
          </label>
        )}
        {!isEditMode && lines > 3 && (
          <label
            onClick={() => setIsMoreAction(!isMoreAction)}
            style={{ color: "blue", fontSize: "10px" }}
            role="button"
          >
            {isMoreAction ? "show less..." : "show more..."}
          </label>
        )}
        {isEditMode && (
          <TextareaAutosize
            className="form-control shadow-sm border-0"
            placeholder="Enter what needs to be done"
            onChange={(e) => onActionChange(item, e.target.value, itemIndex, "text")}
            value={removeHtmlTag(item.text)}
            required
            style={{
              borderRadius: "0px",
              resize: "none",
            }}
          />
        )}
      </td>
      <td className="text-center align-middle">
        {!isEditMode && item.due_date && moment(dueDate.date).format("l")}
        {isEditMode && (
          <div className="dueDatePicker form-control shadow-sm border-0 rounded-0">
            <DatePicker
              todayButton={"Today"}
              dateFormat="MMM/dd/yyyy"
              placeholderText="Click to select a date"
              selected={dueDate.date}
              onChange={(day) => setDueDate({ date: day })}
              withPortal
              portalId="root-portal"
              minDate={new Date(today.setDate(today.getDate() + 1))}
            />
          </div>
        )}
      </td>
      <td className="text-center align-middle">{moment(item.createdAt).format("l")}</td>
      <td className="text-center align-middle">
        <ItemStatus status={item.status} />
        {isEditMode && (
          <div>
            <Fragment>
              <span style={{ whiteSpace: "nowrap" }}>Send Reminder?</span>
              <Input
                type="checkbox"
                placeholder="Enter what needs to be done"
                onChange={() => setIsItemChecked(!isItemChecked)}
                checked={isItemChecked}
              />
            </Fragment>
          </div>
        )}
      </td>
      <td className="align-middle action-buttons-container">
        {!isComplete && !isEditMode && (
          <div className="dropdown">
            <button
              type="button"
              className="btn contact-menu-button btn-outline-primary dropdown-toggle bg-white"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {isLoading ? (
                <Spinner className="spinner-border-sm text-primary" />
              ) : (
                <i className="fas fa-ellipsis-v"></i>
              )}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a onClick={() => setOpenActionView(true)} className="dropdown-item" href="#">
                <i className="fas fa-ellipsis-h mr-2"></i>
                View Details
              </a>
              <a onClick={() => openThread(item.threadId)} className="dropdown-item" href="#">
                <i className="fas fa-share-square mr-2"></i>
                Go to Thread
              </a>
              {allow_update && (
                <a onClick={() => setIsEditMode(true)} className="dropdown-item" href="#">
                  <i className="fas fa-edit mr-2"></i>
                  Update
                </a>
              )}
              {/* <a onClick={() => comment(item.threadId)} className="dropdown-item" href="#">
                <i className="fas fa-reply" style={{ transform: "rotate(180deg)" }}></i>
                Comment
              </a> */}
              <a onClick={() => openCommet(item.objectId)} className="dropdown-item" href="#">
                <i className="fa fa-comment fa-sm mr-2"></i>
                View Comments
              </a>
              {allow_mark_done && (
                <>
                  <div className="dropdown-divider"></div>
                  <a onClick={() => handleComplete()} className="dropdown-item" href="#">
                    <i className="fas fa-check mr-2 btn-outline-success"></i>
                    Mark as Done
                  </a>
                </>
              )}
            </div>
          </div>
        )}
        {isEditMode && (
          <button
            onClick={() => handleUpdate(item.assignedTo, dueDate.date)}
            className="btn btn-sm btn-outline-success"
            disabled={isLoading}
            style={{ marginRight: "5px" }}
          >
            {isLoading ? <Spinner className="spinner-border-sm text-success" /> : <i className="fas fa-fw fa-save"></i>}
          </button>
        )}
        {/* {!hideButtons && !isComplete && !isEditMode ? (
          <button
            onClick={() => handleComplete()}
            className="btn btn-sm btn-outline-success"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner className="spinner-border-sm text-success" />
            ) : (
              <i className="fas fa-fw fa-check"></i>
            )}
          </button>
        ) : (
          ""
        )} */}
        {openActionView && <ActionViewModal isOpen={openActionView} modalClose={setOpenActionView} item={item} />}
        {openDiscussion && 
          <DiscussionModal
            isOpen={openDiscussion}
            modalClose={setOpenDiscussion}
            item={item}
          />
        }
      </td>
    </tr>
  );
};

const AssignedTo = ({ user = {} }) => {
  const { picture = {} } = user;

  return (
    <div className="d-flex align-items-center text-truncate">
      <ProfilePic url={picture.url} size="25" />
      <span className="text-primary ml-1">{user.displayName}</span>
    </div>
  );
};

const ItemStatus = ({ status = "" }) => {
  const classNames = useMemo(() => {
    switch (status) {
      case "PENDING":
        return "badge badge-warning badge-pill";
      case "DONE":
        return "badge badge-success badge-pill";
      default:
        return "badge badge-light badge-pill";
    }
  }, [status]);

  return <span className={classNames}>{status}</span>;
};

export default ActionsModal;
