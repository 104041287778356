import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import OverlayLoader from "./OverlayLoader";
import ImagePlaceholder from "./ImagePlaceholder";

const ImageLoad = ({ openLightbox, imageURL, iconSize, type }) => {
  const [width, setWidth] = useState("200px");
  const [height, setHeight] = useState("200px");
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errLoading, setErrLoading] = useState(false);
  const [imageNull, setImageNull] = useState(false);

  useEffect(() => {
    if (imageURL === null) {
      setImageNull(true);
    }
  }, [imageURL]);

  const styles = useMemo(() => {
    return {
      background: "gray",
      display: "block",
      cursor: "pointer",
      // marginBottom: "15px",
      width,
      height,
    };
  }, [width, height]);

  const handleImageLoad = () => {
    setHasLoaded(true);
    setWidth("100%");
    setHeight("auto");
  };

  const handleImageError = () => {
    setHasLoaded(true);
    setErrLoading(true);
  };

  return (
    <div style={{ position: "relative" }}>
      <img
        onLoad={(e) => handleImageLoad()}
        onError={(e) => handleImageError()}
        className="img-fluid image-lazy-fade-in"
        onClick={openLightbox}
        style={styles}
        src={imageURL}
        alt="Photo"
      />
      <OverlayLoader
        background="lightgray"
        color="white"
        isLoading={!hasLoaded && !imageNull}
      />
      <Placeholder 
        show={errLoading || imageNull} 
        iconSize={iconSize}
      />
    </div>
  );
};

const Placeholder = ({ show, iconSize = "5x" }) => {
  const styles = {
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: "white",
    width: "100%",
    height: "200px",
    display: show ? "flex" : "none",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={styles}>
      <ImagePlaceholder type="pdf" iconSize={iconSize} />
    </div>
  );
};

export default ImageLoad;
