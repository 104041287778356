import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual, connect } from "react-redux";
import Parse from "parse";
import Loader from "../Common/Loader";
import Notify from "../Common/Notify";
import { setActiveTab, setMessagesShown } from "../../actions/ThreadAction";
import {
  markAllThreadsAsRead,
  sortThreads,
  filterThreads,
  deleteAllPriorities,
  showTagsFilterModal,
  fetchRecentThreads,
  setMessageTab,
} from "../../actions/ThreadAction";

const $ = window.$;

function TabMenu(props) {
  // State hooks
  const [loading, setLoading] = useState(false);

  // Reducer hooks
  const dispatch = useDispatch();

  // Get values from redux store
  const { sortReverse, filterAttention, activeMessageTab, activeTab, read } = useSelector(
    (state) => ({
      sortReverse: state.thread.sortReverse,
      filterAttention: state.thread.filterAttention,
      activeMessageTab: state.thread.activeMessageTab,
      activeTab: state.thread.activeTab,
      read: state.thread.read,
    }),
    shallowEqual
  );

  function markAllasRead() {
    setLoading(true);

    markAllThreadsAsRead()(dispatch)
      .then(() => {
        Notify.success("Successfully marked all threads as read!");
        setLoading(false);
      })
      .catch(() => {
        Notify.error("Failed to mark all threads as read.");
        setLoading(false);
      });
  }

  function markPrioritiesAsRead() {
    setLoading(true);

    deleteAllPriorities()(dispatch)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function showOldMessages() {
    sortThreads({ reverse: true })(dispatch);
  }

  function showNewMessages() {
    sortThreads({ reverse: false })(dispatch);
  }

  function filterAttentionMessages() {
    filterThreads({ attention: true })(dispatch);
  }

  function removeAttentionFilter() {
    filterThreads({ attention: false })(dispatch);
  }

  function handleFilterByTags() {
    showTagsFilterModal()(dispatch);
  }

  function filterRecentMessages() {
    fetchRecentThreads()(dispatch);
    dispatch(setMessageTab("RECENT"));
  }

  let imageUrl = require("../../assets/images/default.png");

  let picture = Parse.User.current().get("picture");

  if (picture) {
    imageUrl = picture._url;
  }

  return (
    <div className="ml-2">
      {activeTab === "group" && (
        <button
          onClick={() => props.openCreateModal()}
          className="btn btn-light bg-white"
          data-toggle="tooltip"
          data-placement="top"
          data-html="true"
          title="Create New Group"
        >
          <i className="fas fa-fw fa-user-plus hc-primary-text"></i>
        </button>
      )}
      {activeTab === "chart" && (
        <button
          onClick={() => props.openCreateModal()}
          className="btn btn-light bg-white"
          data-toggle="tooltip"
          data-placement="top"
          data-html="true"
          title="Create New Patient"
        >
          <i className="fas fa-fw fa-user-plus hc-primary-text"></i>
        </button>
      )}
      {activeTab === "message" ? (
        <div className="dropdown">
          <button
            type="button"
            className="btn btn-light bg-white dropdown-toggle"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-fw fa-bars hc-primary-text"></i>
          </button>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <h6 className="dropdown-header">Action</h6>

            {activeMessageTab === "ALL" ? (
              <>
                <a className="dropdown-item" href="#" onClick={() => dispatch(setActiveTab("create_chat"))}>
                  <i className="fas fa-sticky-note" style={{ marginRight: "5px" }}></i>
                  Create Message
                </a>
                {/* <a className="dropdown-item" href="#" onClick={() => filterRecentMessages()}>
                  <i className="fas fa-envelope" style={{ marginRight: "5px" }}></i>
                  Recently Viewed
                </a> */}
                <a className="dropdown-item" href="#" onClick={() => handleFilterByTags()}>
                  <i className="fas fa-hashtag" style={{ marginRight: "5px" }}></i>
                  Filter by tags
                </a>
                <a className="dropdown-item" href="#" onClick={() => markAllasRead()}>
                  <i className="fas fa-check-double" style={{ marginRight: "5px" }}></i>
                  Mark all messages as read
                </a>
              </>
            ) : (
              ""
            )}
            {activeMessageTab === "TASKS" ? (
              <a className="dropdown-item" href="#" onClick={() => markPrioritiesAsRead()}>
                <i className="fas fa-check-double" style={{ marginRight: "5px" }}></i>
                Clear important messages
              </a>
            ) : (
              ""
            )}
            {activeMessageTab === "ALERTS" ? (
              <a className="dropdown-item" href="#" onClick={() => markPrioritiesAsRead()}>
                <i className="fas fa-check-double" style={{ marginRight: "5px" }}></i>
                Clear important messages
              </a>
            ) : (
              ""
            )}
            {activeMessageTab === "ALL" ? (
              <>
                {sortReverse ? (
                  <a className="dropdown-item" href="#" onClick={() => showNewMessages()}>
                    <i className="fa fa-sort" style={{ marginRight: "5px" }}></i>
                    Newest messages on top
                  </a>
                ) : (
                  <a className="dropdown-item" href="#" onClick={() => showOldMessages()}>
                    <i className="fa fa-sort" style={{ marginRight: "5px" }}></i>
                    Oldest messages on top
                  </a>
                )}

                {/* {filterAttention ? (
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => removeAttentionFilter()}
                  >
                    <i
                      className="fa fa-exclamation-triangle"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Show all messages
                  </a>
                ) : (
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => filterAttentionMessages()}
                  >
                    <i
                      className="fa fa-exclamation-triangle"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Show messages @ to me
                  </a>
                )} */}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <Loader isLoading={loading} />
    </div>
  );
}

export default TabMenu;
