import Parse from "parse";
import axios from "axios";
import config from "../config";
const $ = window.$;

const activeRequests = new Map();

const createAbortController = (identifier) => {
  const currentController = activeRequests.get(identifier);
  if (currentController) {
    currentController.abort();
  }
  const newController = new AbortController();
  activeRequests.set(identifier, newController);
  return newController;
};

const getRequestIdentifier = (method, uri) => {
  return `${method}-${uri}`;
};

const ajaxCall = async (settings, identifier) => {
  try {
    const res = await $.ajax(settings);
    activeRequests.delete(identifier);
    return res;
  } catch (error) {
    activeRequests.delete(identifier);
    if ((error.responseJSON || {}).code === 209) {
      window.location.reload();
    } else if (error.name !== "AbortError") {
      throw new Error(error);
    }
  }
};

export const parseAction = async (method, uri, params = {}, headers = {}) => {
  const identifier = getRequestIdentifier(method, uri);
  const abortController = createAbortController(identifier);

  let sessionToken = Parse.User.current().getSessionToken();
  let settings = {
    method: method.toUpperCase(),
    url: uri,
    headers: {
      "X-Parse-Application-Id": config.APPLICATION_ID,
      "X-Parse-REST-API-Key": config.REST_API_KEY,
      "X-Parse-Session-Token": sessionToken,
      ...headers,
    },
    signal: abortController.signal,
  };

  if (headers["Content-Type"]) {
    settings.data = JSON.stringify(params);
  } else {
    settings.data = params;
  }

  return ajaxCall(settings, identifier);
};

export const parseActionNoAuth = async (method, uri, params = {}, headers = {}) => {
  let settings = {
    method: method.toUpperCase(),
    url: uri,
    headers: {
      "X-Parse-Application-Id": config.APPLICATION_ID,
      "X-Parse-REST-API-Key": config.REST_API_KEY,
      ...headers,
    },
  };

  if (headers["Content-Type"]) {
    settings.data = JSON.stringify(params);
  } else {
    settings.data = params;
  }

  return $.ajax(settings)
    .done((res) => {
      return res;
    })
    .fail((xhr, status, error) => {
      console.log(xhr, status);
      if ((xhr.responseJSON || {}).code === 209) {
        window.location.reload();
      } else {
        return new Error(error);
      }
    });
};

export const revenueAction = async (method, uri, params = {}) => {
  var settings = {
    async: true,
    crossDomain: true,
    url: "https://api.revenuecat.com/v1/subscribers/" + Parse.User.current().id,
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer CjHisFFvMnOTzkPYeVXpNtwUvLnFvLxC",
      "Content-Type": " application/json",
    },
  };

  return $.ajax(settings)
    .done((res) => {
      // console.log(res.getResponseHeaders());
      return res;
    })
    .fail((xhr, status, error) => {
      if ((xhr.responseJSON || {}).code === 209) {
        window.location.reload();
      } else {
        return new Error(error);
      }
    });
};

export const stripeAction = async (method, uri, params = {}) => {
  var settings = {
    async: true,
    crossDomain: true,
    url: uri,
    method: method.toUpperCase(),
    headers: {
      Accept: "application/json",
      Authorization: "Bearer sk_test_7uu9iLa9UMCx9l4yUHa5WmtR",
      "Content-Type": " application/json",
    },
  };

  return $.ajax(settings)
    .done((res) => {
      return res;
    })
    .fail((xhr, status, error) => {
      if ((xhr.responseJSON || {}).code === 209) {
        window.location.reload();
      } else {
        return new Error(error);
      }
    });
};
