import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Modal from "../../Common/Modal";
import GroupCollapse from "../../Common/GroupCollapse";
import defaultImageUrl from "../../../assets/images/default.png";
import { parseAction } from "../../../actions/REST";
import { 
  fetchGroups,
  fetchPatients
 } from '../../../actions/GroupAction';
import { fetchContacts } from '../../../actions/ContactAction';
import OverlayLoader from "../../Common/OverlayLoader";
import useMessage from "../../../use/message";
import useThread from "../../../use/thread";
import config from "../../../config";
import { useDispatch } from "react-redux";
import moment from "moment";
import _ from "lodash";
import thread from "../../../use/thread";

const ForwardModal = ({
  isOpen,
  onClose,
  messages = [],
  resetSelectedMessages = () => {},
  history,
  groupFilter = ''
}) => {
  const { contacts, groups, patients, threads } = useSelector(
    mySelector,
    shallowEqual
  );
  
  const dispatch = useDispatch();
  const commentsRef = useRef();
  const Thread = useThread();
  const Message = useMessage();

  const [searchKey, setSearchKey] = useState("");
  const [group, setGroups] = useState([]);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [patient, setPatients] = useState([]);
  const [loadingPatients, setLoadingPatients] = useState(false);
  const [contact, setContacts] = useState([]);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [localThreads, setLocalThreads] = useState([]);
  const [comments, setComments] = useState("");
  const [textareaRows, setTextareaRows] = useState(2);
  const [isLoading, setIsLoading] = useState(true);

  // const { threads } = useSelector(
  //   (state) => ({
  //     threads: state.thread.threads,
  //   }),
  //   shallowEqual
  // );

  useEffect(() => {
    switch (groupFilter) {
      case 'group':
        dispatch(fetchGroups())
          .then(() => {
            setIsLoading(false);
          });break;
      case 'contact':
        dispatch(fetchContacts())
          .then(() => {
            setIsLoading(false);
          });break;
      case 'patient':
        dispatch(fetchPatients())
          .then(() => {
            setIsLoading(false);
          });break;
      default: {
        dispatch(fetchGroups());
        dispatch(fetchContacts());
        dispatch(fetchPatients())
          .then(() => {
            setIsLoading(false);
          });
      }
    }
  }, []);

  const filterThreads = (searchKey = "", threads) => {
    const newThreads = threads
      .filter((recent) => {
        const threadName = recent.groupName || recent.partnerName || "";
        const threadNameLower = threadName.toLowerCase();
        return threadNameLower.includes(searchKey.toLowerCase());
      })
      .slice(0, 5);
    const filteredThreads = [];
    for (let i = 0; i < newThreads.length; i++) {
      const filtered = filteredThreads.filter(
        (thread) => thread.objectId === newThreads[i].objectId
      );
      if (filtered.length === 0) {
        filteredThreads.push(newThreads[i]);
      }
    }
    return filteredThreads;
  };

  const searchGroups = async (text = "") => {
    setGroups([]);
    setLoadingGroups(true);

    const url = config.BASE_URL + "/parse/functions/searchGroups";

    const params = {
      page: 0,
      pageSize: 10,
      search: text,
    };

    try {
      const res = await parseAction("post", url, params);

      const { circles } = res.result;

      const withoutCurrentThread = circles.filter(
        (c) => c.objectId != Thread.activeThread.threadId
      );

      setGroups(withoutCurrentThread);
      setLoadingGroups(false);
    } catch (err) {
      console.log(err);
      setLoadingGroups(false);
    }
  };

  const searchPatients = async (text = "") => {
    setPatients([]);
    setLoadingPatients(true);

    const url = config.BASE_URL + "/parse/functions/searchPatients:v3";

    const params = {
      page: 0,
      pageSize: 10,
      search: text,
    };

    try {
      const res = await parseAction("post", url, params);

      const { circles } = res.result;

      const withoutCurrentThread = circles.filter(
        (c) => c.objectId != Thread.activeThread.threadId
      );

      setPatients(withoutCurrentThread);
      setLoadingPatients(false);
    } catch (err) {
      setLoadingPatients(false);
    }
  };

  const searchContacts = async (text = "") => {
    setContacts([]);
    setLoadingContacts(true);

    const url = config.BASE_URL + "/parse/functions/searchContactsV2";

    const params = {
      page: 0,
      limit: 10,
      filter: text.toLowerCase().trim(),
    };

    try {
      const res = await parseAction("post", url, params);

      const { rosters = [] } = res.result;

      const contacts = rosters
        .filter((r) => !!r.contact)
        .map((r) => {
          return {
            ...r.contact,
            rosterId: r.objectId,
          };
        });

      const unique = _.uniqBy(contacts, "objectId");

      const withoutCurrentThread = unique.filter((c) => {
        const contactThreadId = Thread.threadIdForContact(c.objectId);

        return contactThreadId != Thread.activeThread.threadId;
      });

      setContacts(withoutCurrentThread);
      setLoadingContacts(false);
    } catch (err) {
      setLoadingContacts(false);
    }
  };

  const handleFocus = () => {
    setTextareaRows(8);
  };

  const handleBlur = () => {
    setTextareaRows(2);
  };

  const threadList = useMemo(() => {
    return searchKey ? filterThreads(searchKey, localThreads) : localThreads;
  }, [searchKey, localThreads]);

  useEffect(() => {
    if (!searchKey) return;

    const searchTimeout = setTimeout(() => {
      
      switch (groupFilter) {
        case 'group':
          searchGroups(searchKey);
          break;
        case 'contact':
          searchContacts(searchKey);
          break;
        case 'patient':
          searchPatients(searchKey);
          break;
        default: {
          searchGroups(searchKey);
          searchContacts(searchKey);
          searchPatients(searchKey);
        }
      }
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchKey]);

  useEffect(() => {
    const withoutCurrentThread = threads.filter(
      (t) => t.objectId != Thread.activeThread.objectId
    );

    setLocalThreads(withoutCurrentThread);
  }, [Thread.isLoading]);

  return (
    <Modal shown={isOpen} onHide={onClose}>
      <ModalHeader
        resetSelectedMessages={resetSelectedMessages}
        onClose={onClose}
      />
      <ModalBody className="pb-0">
        <label className="text-primary">Add comments</label>

        <textarea
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          className="form-control w-100"
          placeholder="Type comment..."
          rows={textareaRows}
          style={{ resize: "none" }}
        ></textarea>
      </ModalBody>
      <ModalBody>
        <input
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          className="form-control rounded-pill"
          placeholder="Search"
        />
      </ModalBody>
      <ModalBody
        className="py-0 h-100"
        style={{ height: "100%", width: "100%", overflowY: "auto" }}
      >
        {(
          <GroupCollapse label="Recents" collapseId="recents">
          {threadList.map((thread, index) => (
            <Item
              recent={thread}
              key={thread.objectId + index}
              messages={messages}
              comments={comments}
              history={history}
              onClose={onClose}
            />
          ))}
          {!threadList.length ? (
            <div className="text-empty">No recents found</div>
          ) : (
            ""
          )}
          <OverlayLoader isLoading={Thread.isLoading} />
        </GroupCollapse>
        )}
        {(groupFilter === undefined || groupFilter === "group") && (
          <GroupCollapse label="Groups" collapseId="groups">
            {searchKey ? (
              <>
                {group.map((circle) => (
                  <GroupItem
                    circle={circle}
                    key={circle.objectId}
                    messages={messages}
                    comments={comments}
                  />
                ))}
                {!group.length ? (
                  <div className="text-empty">No groups found</div>
                ) : (
                  ""
                )}
                <OverlayLoader isLoading={loadingGroups} />
              </>
             ) : ( 
              <>
                {groups.map((circle) => (
                  <GroupItem
                    circle={circle}
                    key={circle.objectId}
                    messages={messages}
                    comments={comments}
                  />
                ))}
                {!groups.length ? (
                  <div className="text-empty">No groups found</div>
                ) : (
                  ""
                )}
                <OverlayLoader isLoading={isLoading} />
              </>
            )}
          </GroupCollapse>
        )}
        {(groupFilter === undefined || groupFilter === "patient") && (
          <GroupCollapse label="Patient Hub" collapseId="patients">
            {searchKey ? (
              <>
                {patient.map((circle) => (
                  <PatientItem
                    circle={circle}
                    key={circle.objectId}
                    messages={messages}
                    comments={comments}
                  />
                ))}
                {!patient.length ? (
                  <div className="text-empty">No patients found</div>
                ) : (
                  ""
                )}
                <OverlayLoader isLoading={loadingPatients} />
              </>
            ) : (
              <>
                {patients.map((circle) => (
                  <PatientItem
                    circle={circle}
                    key={circle.objectId}
                    messages={messages}
                    comments={comments}
                  />
                ))}
                {!patients.length ? (
                  <div className="text-empty">No patients found</div>
                ) : (
                  ""
                )}
                <OverlayLoader isLoading={isLoading} />
              </>)}
            
          </GroupCollapse>
        )}
        {(groupFilter === undefined || groupFilter === "contact") && (
          <GroupCollapse label="Contacts" collapseId="contacts">
            {searchKey ? (
              <>
              {contact.map((contact) => (
                <ContactItem
                  contact={contact}
                  key={contact.objectId}
                  messages={messages}
                  comments={comments}
                />
              ))}
              {!contact.length ? (
                <div className="text-empty">No contacts found</div>
              ) : (
                ""
              )}
              <OverlayLoader isLoading={loadingContacts} />
              </>
            ) : (
              <>
              {contacts.map((contact) => (
                <ContactItem
                  contact={contact.contact}
                  key={contact.objectId}
                  messages={messages}
                  comments={comments}
                />
              ))}
              {!contacts.length ? (
                <div className="text-empty">No contacts found</div>
              ) : (
                ""
              )}
              <OverlayLoader isLoading={isLoading} />
              </>
            )}
          </GroupCollapse>
        )}
        <hr />
      </ModalBody>
      <div className="modal-footer rm-border" />
    </Modal>
  );
};

const ModalHeader = ({ resetSelectedMessages, onClose }) => {
  return (
    <div className="modal-header">
      <h5 className="text-primary mb-0" style={{ fontSize: "1.25rem" }}>
        <i className="fas fa-share-square mr-1"></i>
        Forward message(s) to
      </h5>
      <button
        type="button"
        className="close"
        // data-dismiss="modal"
        aria-label="Close"
      >
        <span
          aria-hidden="true"
          onClick={() => {
            resetSelectedMessages();
            onClose();
          }}
        >
          &times;
        </span>
      </button>
    </div>
  );
};

const ModalBody = ({ children, style = {}, className = "" }) => {
  const styles = {
    ...style,
  };

  const classNames = ["modal-body", className].join(" ");

  return (
    <div className={classNames} style={styles}>
      {children}
    </div>
  );
};

const ModalFooter = ({ children }) => {
  return (
    <div className="modal-footer justify-content-start shadow">{children}</div>
  );
};

const Item = ({ recent, messages = [], comments, history, onClose }) => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const threadName = recent.groupName || recent.partnerName || "Group";
  const threadImage =
    recent.groupImageURL || recent.partnerImageURL || defaultImageUrl;

  const Message = useMessage();

  const handleSend = async () => {
    setLoading(true);
    setError(false);

    try {
      await Message.forwardMultiple({
        messages,
        thread: recent,
        comments,
      });

      setLoading(false);
      setSuccess(true);
      // onClose();
      // history.push({
      //   pathname: `/u/${recent.threadId}`,
      // });
    } catch (err) {
      // debugger;
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div className="border-item layout-item">
      <img
        src={threadImage}
        alt=""
        className="profile-image rounded-circle mr-2"
        style={{ width: "30px", height: "30px" }}
      />
      <div className="d-flex flex-column">
        <p className="name m-0 text-uppercase">{threadName}</p>
        <div className="row">
          <div className="col-auto">
            {/* <p className="mb-0" style={{ fontSize: "12px", marginTop: '3px' }}> */}
              <ItemStatus recent={recent} />
            {/* </p> */}
          </div>
        </div>
      </div>
      <SendButton
        loading={loading}
        error={error}
        success={success}
        onSend={handleSend}
      />
    </div>
  );
};

const GroupItem = ({ circle, messages = [], comments }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const threadName = circle.name;
  const threadImage = circle.image ? circle.image.url : defaultImageUrl;

  const { forwardMultiple, getCircleThread } = useMessage();

  const handleSend = async () => {
    setLoading(true);
    setError(false);

    try {
      await forwardMultiple({
        messages,
        thread: getCircleThread(circle),
        comments,
      });

      setLoading(false);
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div className="border-item layout-item">
      <img
        src={threadImage}
        alt=""
        className="profile-image rounded-circle mr-2"
        style={{ width: "30px", height: "30px" }}
      />
      <p className="name m-0 text-uppercase">{threadName}</p>
      <SendButton
        loading={loading}
        error={error}
        success={success}
        onSend={handleSend}
      />
    </div>
  );
};

const PatientItem = ({ circle, messages = [], comments }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const threadName = circle.name;
  const threadImage = circle.image ? circle.image.url : defaultImageUrl;

  const { forwardMultiple, getCircleThread } = useMessage();

  const handleSend = async () => {
    console.log('PatientItem sennd');
    setLoading(true);
    setError(false);

    try {
      await forwardMultiple({
        messages,
        thread: getCircleThread(circle),
        comments,
      });

      setLoading(false);
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  const owner = circle.owner ? circle.owner.displayName : "";

  return (
    <div className="border-item layout-item">
      <img
        src={threadImage}
        alt=""
        className="profile-image rounded-circle mr-2"
        style={{ width: "30px", height: "30px", alignSelf: "start" }}
      />
      <div className="d-flex flex-column">
        <p className="name m-0 text-uppercase">{threadName}</p>
        <div className="row">
          <div className="col-auto">
            <p className="mb-0" style={{ fontSize: "12px", marginTop: '3px' }}>
              <i className="fas fa-user-md mr-1 text-primary"></i>
              {owner}
            </p>
          </div>
          {circle.dob ? (
            <div className="col-auto">
              <p className="mb-0" style={{ fontSize: "12px", marginTop: '3px' }}>
                <i className="fas fa-calendar mr-1 text-primary"></i>
                {circle.dob}
              </p>
            </div>
          ) : (
            ""
          )}
          {circle.lastMessageDate ? (
            <div className="col-12">
              <p className="mb-0" style={{ fontSize: "12px", marginTop: '3px' }}>
                <span className="badge badge-success">Last Activity</span>{" "}
                {moment(circle.lastMessageDate.iso).format("dddd, MMM DD HH:mm a")}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <SendButton
        loading={loading}
        error={error}
        success={success}
        onSend={handleSend}
      />
    </div>
  );
};

const ContactItem = ({ contact, messages = [], comments }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const threadName = contact.displayName;
  const threadImage = contact.picture ? contact.picture.url : defaultImageUrl;

  const { forwardMultiple, getContactThread } = useMessage();

  const handleSend = async () => {
    setLoading(true);
    setError(false);

    console.log('comment', comments); // << check

    try {
      await forwardMultiple({
        messages,
        thread: getContactThread(contact),
        comments,
      });

      setLoading(false);
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div className="border-item layout-item">
      <img
        src={threadImage}
        alt=""
        className="profile-image rounded-circle mr-2"
        style={{ width: "30px", height: "30px" }}
      />
      <p className="name m-0 text-uppercase">{threadName}</p>
      <SendButton
        loading={loading}
        error={error}
        success={success}
        onSend={handleSend}
      />
    </div>
  );
};

const SendButton = ({ loading, error, success, onSend }) => {
  const handleSend = () => {
    onSend();
  };
  return (
    <div className="d-flex flex-column">
      {!success ? (
        <button
          onClick={handleSend}
          className="btn btn-primary btn-sm ml-auto text-nowrap"
          disabled={loading}
        >
          {loading ? (
            <div
              className="spinner-border spinner-border-sm mr-1"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <i className="fas fa-paper-plane mr-1"></i>
          )}
          {error ? "Retry" : "Send"}
        </button>
      ) : (
        ""
      )}
      {error && !loading ? (
        <small className="text-danger">Failed to send</small>
      ) : (
        ""
      )}
      {success ? (
        <small className="text-primary mb-0">
          <i className="fas fa-check mr-1"></i>
          <strong>SENT</strong>
        </small>
      ) : (
        ""
      )}
    </div>
  );
};

const ItemStatus = ({ recent = "" }) => {
  let type = '';
  if (recent.threadType === 'private') {
    type = 'CONTACT';
  } else if (recent.groupType === 'regular') {
    type = 'GROUP';
  } else if (recent.groupType === 'patient') {
    type = 'PATIENT';
  }
  return <span className="badge badge-primary badge-pill" style={{fontSize: '0.7em'}}>{type}</span>;
};

const mySelector = (state) => ({
  threads: state.thread.threads,
  contacts: state.contact.contacts,
  contactInitialLoad: state.contact.initialLoad,
  groups: state.group.groups,
  groupInitialLoad: state.group.initialLoad,
  patients: state.group.patients,
});

export default ForwardModal;
