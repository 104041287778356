import Parse from "parse";
import { parseAction } from "./REST";
import config from "../config";
import {
  PATIENT_ORDERS_LOADING,
  SET_PATIENT_ORDERS,
  SET_PATIENT_ORDER,
  PATIENT_ORDER_LOADING,
  PATIENT_ORDERS_HISTORY_LOADING,
  SET_PATIENT_ORDERS_HISTORY,
  IS_CREATING_PRESCRIPTION_HISTORY,
  PRESCRIPTIONS_HISTORY_LOADING,
  PRESCRIPTION_HISTORY_LOADING,
  SET_PRESCRIPTIONS_HISTORY,
  SET_PRESCRIPTION_HISTORY,
  IS_CREATING_GROUPED_LIST,
  GROUPED_LISTS_LOADING,
  GROUPED_LIST_LOADING,
  SET_GROUPED_LISTS,
  SET_GROUPED_LIST,
  E_SIGN_LOADING,
  SET_PATIENT_INFO_REDUCED,
  SET_PATIENT_MEDICATIONS,
  IS_PATIENT_MEDICATIONS_LOADING,
  SET_PATIENT_PROBLEMS,
  IS_PATIENT_PROBLEMS_LOADING,
  SET_PATIENT_DIAGNOSES,
  IS_PATIENT_DIAGNOSES_LOADING,
  SET_RECENT_SEARCH,
} from "../actions/types";

export const getRecentPatients = () => (dispatch) => {
  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getRecentPatients"
  ).then((res) => {
    dispatch({
      type: "SET_RECENT_PATIENTS",
      recents: res.result.recents,
    });
  });
};

export const searchRecentPatients = (text, newPage = 0) => (dispatch) => {
  const params = {
    searchText: text,
    pageSize: 10,
    page: newPage,
  };

  setLoading(true);

  return parseAction(
    "post",
    config.BASE_URL + "/parse/functions/searchPatients",
    params
  ).then((res) => {
    setLoading(false);

    const { circles, page, pageSize, hasMore } = res.result;

    dispatch({
      type: "SEARCH_RECENT_PATIENTS",
      results: circles,
      text: text,
      page,
      hasMore,
    });
  });

  function setLoading(value) {
    dispatch({
      type: "SET_LOADING_SEARCH",
      value,
    });
  }
};

export const setIsSearching = (value) => (dispatch) => {
  dispatch({
    type: "SET_IS_SEARCHING",
    value,
  });
};

export const fetchPatientInfo = (threadId, callBack) => (dispatch) => {
  parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
    where: {
      objectId: threadId,
    },
    include: [
      "facility",
      "serviceLocation",
      "primaryInsurance",
      "primaryInsurance.provider",
      "secondaryInsurance",
      "secondaryInsurance.provider",
      "otherInsurance",
      "otherInsurance.provider",
    ],
  })
    .then((result) => {
      var group = result.results[0];
      dispatch({
        type: "SET_PATIENT_INFO",
        patientInfo: group,
      });
      callBack(group);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const createNewOrder = (data, objectId, callBack) => (dispatch) => {
  dispatch({
    type: PATIENT_ORDERS_LOADING,
    isOrdersLoading: true,
  });

  const method = "post";
  const url = config.BASE_URL + "/parse/classes/PatientOrder";
  const Circle = Parse.Object.extend("Circle");
  const circle = Circle.createWithoutData(objectId);

  return parseAction(
    method,
    url,
    {
      ...data,
      circle: circle.toPointer(),
    },
    { "Content-Type": "application/json" }
  )
    .then((result) => {
      getPatientOrders(objectId)(dispatch);
      dispatch({
        type: PATIENT_ORDERS_LOADING,
        isOrdersLoading: false,
      });
      return callBack(result.objectId, data);
    })
    .catch((error) => {
      dispatch({
        type: PATIENT_ORDERS_LOADING,
        isOrdersLoading: false,
      });
    });
};

export const getPatientOrders = (objectId) => (dispatch) => {
  dispatch({
    type: PATIENT_ORDERS_LOADING,
    isOrdersLoading: true,
  });

  const Circle = Parse.Object.extend("Circle");
  const objId = Circle.createWithoutData(objectId);

  parseAction("get", config.BASE_URL + "/parse/classes/PatientOrder", {
    where: {
      circle: objId.toPointer(),
    },
  })
    .then((result) => {
      dispatch({
        type: PATIENT_ORDERS_LOADING,
        isOrdersLoading: false,
      });

      dispatch({
        type: SET_PATIENT_ORDERS,
        patientOrders: (result.results || []).map((item) => ({
          ...item,
          isChecked: item.isChecked || false,
        })),
      });
    })
    .catch((error) => {
      dispatch({
        type: PATIENT_ORDERS_LOADING,
        isOrdersLoading: false,
      });
      console.error(error);
    });
};

export const updateOrder = (data, patientId, callBack) => (dispatch) => {
  const { objectId, createdAt, updatedAt, ...rest } = data;
  const method = "put";
  const url = config.BASE_URL + `/parse/classes/PatientOrder/${objectId}`;

  return parseAction(method, url, rest, { "Content-Type": "application/json" })
    .then((result) => {
      getPatientOrders(patientId)(dispatch);
      return callBack();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const deleteOrder = (objectId, patientId, callBack) => (dispatch) => {
  dispatch({
    type: PATIENT_ORDERS_LOADING,
    isOrdersLoading: true,
  });

  const method = "delete";
  const url = config.BASE_URL + `/parse/classes/PatientOrder/${objectId}`;

  return parseAction(method, url)
    .then((result) => {
      getPatientOrders(patientId)(dispatch);
      return callBack();
    })
    .catch((error) => {
      console.error(error);

      dispatch({
        type: PATIENT_ORDERS_LOADING,
        isOrdersLoading: false,
      });
    });
};

export const createNewOrderHistory = (data, objId, patientId, callBack) => (
  dispatch
) => {
  dispatch({
    type: PATIENT_ORDERS_LOADING,
    isOrdersLoading: true,
  });

  const method = "post";
  const url = config.BASE_URL + "/parse/classes/OrderHistory";
  const Circle = Parse.Object.extend("Circle");
  const circle = Circle.createWithoutData(patientId);
  const { objectId, createdAt, updatedAt, ...rest } = data;

  return parseAction(
    method,
    url,
    {
      ...rest,
      circle: circle.toPointer(),
    },
    { "Content-Type": "application/json" }
  )
    .then((result) => {
      getPatientOrdersHistory(patientId)(dispatch);
      dispatch({
        type: PATIENT_ORDERS_LOADING,
        isOrdersLoading: false,
      });
      return callBack(result);
    })
    .catch((error) => {
      dispatch({
        type: PATIENT_ORDERS_LOADING,
        isOrdersLoading: false,
      });
    });
};

export const getPatientOrdersHistory = (objectId) => (dispatch) => {
  dispatch({
    type: PATIENT_ORDERS_HISTORY_LOADING,
    isOrdersHistoryLoading: true,
  });

  const Circle = Parse.Object.extend("Circle");
  const objId = Circle.createWithoutData(objectId);

  parseAction("get", config.BASE_URL + "/parse/classes/OrderHistory", {
    where: {
      circle: objId.toPointer(),
    },
  })
    .then((result) => {

      dispatch({
        type: SET_PATIENT_ORDERS_HISTORY,
        patientOrdersHistory: result.results,
      });

      dispatch({
        type: PATIENT_ORDERS_HISTORY_LOADING,
        isOrdersHistoryLoading: false,
      });
      if (result.results.length === 0) {
        return;
      }
    })
    .catch((error) => {
      dispatch({
        type: PATIENT_ORDERS_HISTORY_LOADING,
        isOrdersHistoryLoading: false,
      });
      console.error(error);
    });
};

export const getOrderHistory = (objectId) => (dispatch) => {
  dispatch({
    type: PATIENT_ORDER_LOADING,
    isOrderLoading: true,
  });

  parseAction(
    "get",
    config.BASE_URL + `/parse/classes/OrderHistory/${objectId}`
  )
    .then((result) => {
      dispatch({
        type: PATIENT_ORDER_LOADING,
        isOrderLoading: false,
      });

      dispatch({
        type: SET_PATIENT_ORDER,
        patientOrder: result || {},
      });
    })
    .catch((error) => {
      dispatch({
        type: PATIENT_ORDER_LOADING,
        isOrderLoading: false,
      });
      console.error(error);
    });
};

export const setOrdersHistory = (data = []) => (dispatch) => {
  dispatch({
    type: SET_PATIENT_ORDER,
    patientOrder: data,
  });
};

export const checkUncheckPatientOrder = (data, isChecked, index) => (
  dispatch
) => {
  const patientOrders = data.map((item, i) => {
    if (index === i) {
      return {
        ...item,
        isChecked: isChecked,
      };
    } else {
      return {
        ...item,
        isChecked: false,
      };
    }
  });

  dispatch({
    type: SET_PATIENT_ORDERS,
    patientOrders,
  });
};

// prescription history

export const createNewPrescriptionHistory = (data, objId) => (dispatch) => {
  dispatch({
    type: IS_CREATING_PRESCRIPTION_HISTORY,
    isCreatingPrescriptionHistory: true,
  });

  const method = "post";
  const url = config.BASE_URL + "/parse/classes/PrescriptionHistory";
  const Circle = Parse.Object.extend("Circle");
  const circle = Circle.createWithoutData(objId);

  return parseAction(
    method,
    url,
    {
      circle: circle.toPointer(),
      medications: data,
    },
    { "Content-Type": "application/json" }
  )
    .then((result) => {
      getPrescriptionsHistory(objId)(dispatch);
      dispatch({
        type: IS_CREATING_PRESCRIPTION_HISTORY,
        isCreatingPrescriptionHistory: false,
      });
      return result;
    })
    .catch((error) => {
      dispatch({
        type: IS_CREATING_PRESCRIPTION_HISTORY,
        isCreatingPrescriptionHistory: false,
      });
      return error;
    });
};

export const getPrescriptionsHistory = (objectId) => (dispatch) => {
  dispatch({
    type: PRESCRIPTIONS_HISTORY_LOADING,
    isPrescriptionsHistoryLoading: true,
  });

  const Circle = Parse.Object.extend("Circle");
  const objId = Circle.createWithoutData(objectId);

  parseAction("get", config.BASE_URL + "/parse/classes/PrescriptionHistory", {
    where: {
      circle: objId.toPointer(),
    },
  })
    .then((result) => {
      dispatch({
        type: SET_PRESCRIPTIONS_HISTORY,
        prescriptionsHistory: result.results || [],
      });

      dispatch({
        type: PRESCRIPTIONS_HISTORY_LOADING,
        isPrescriptionsHistoryLoading: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: PRESCRIPTIONS_HISTORY_LOADING,
        isPrescriptionsHistoryLoading: false,
      });
    });
};

export const getPrescriptionHistory = (objectId) => (dispatch) => {
  dispatch({
    type: PRESCRIPTION_HISTORY_LOADING,
    isPrescriptionHistoryLoading: true,
  });
  parseAction(
    "get",
    config.BASE_URL + `/parse/classes/PrescriptionHistory/${objectId}`
  )
    .then((result) => {
      dispatch({
        type: PRESCRIPTION_HISTORY_LOADING,
        isPrescriptionHistoryLoading: false,
      });

      dispatch({
        type: SET_PRESCRIPTION_HISTORY,
        prescriptionHistory: result || {},
      });
    })
    .catch((error) => {
      dispatch({
        type: PRESCRIPTION_HISTORY_LOADING,
        isPrescriptionHistoryLoading: false,
      });
      console.error(error);
    });
};

// Grouped list

export const createNewGroupedList = ({ subject, body }, objId) => (
  dispatch
) => {
  dispatch({
    type: IS_CREATING_GROUPED_LIST,
    isCreatingGroupedList: true,
  });

  const method = "post";
  const url = config.BASE_URL + "/parse/classes/GroupedList";
  const Circle = Parse.Object.extend("Circle");
  const circle = Circle.createWithoutData(objId);

  return parseAction(
    method,
    url,
    {
      circle: circle.toPointer(),
      subject,
      body,
    },
    { "Content-Type": "application/json" }
  )
    .then((result) => {
      getGroupedLists(objId)(dispatch);
      dispatch({
        type: IS_CREATING_GROUPED_LIST,
        isCreatingGroupedList: false,
      });
      return result;
    })
    .catch((error) => {
      dispatch({
        type: IS_CREATING_GROUPED_LIST,
        isCreatingGroupedList: false,
      });
      return error;
    });
};

export const getGroupedLists = (objectId) => (dispatch) => {
  dispatch({
    type: GROUPED_LISTS_LOADING,
    isGroupedListsLoading: true,
  });

  const Circle = Parse.Object.extend("Circle");
  const objId = Circle.createWithoutData(objectId);

  parseAction("get", config.BASE_URL + "/parse/classes/GroupedList", {
    where: {
      circle: objId.toPointer(),
    },
  })
    .then((result) => {
      dispatch({
        type: SET_GROUPED_LISTS,
        groupedLists: result.results,
      });

      dispatch({
        type: GROUPED_LISTS_LOADING,
        isGroupedListsLoading: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: GROUPED_LISTS_LOADING,
        isGroupedListsLoading: false,
      });
      console.error(error);
    });
};

export const getGroupedList = (objectId) => (dispatch) => {
  dispatch({
    type: GROUPED_LIST_LOADING,
    isGroupedListLoading: true,
  });
  parseAction("get", config.BASE_URL + `/parse/classes/GroupedList/${objectId}`)
    .then((result) => {
      dispatch({
        type: GROUPED_LIST_LOADING,
        isGroupedListLoading: false,
      });

      dispatch({
        type: SET_GROUPED_LIST,
        groupedList: result || {},
      });
    })
    .catch((error) => {
      dispatch({
        type: GROUPED_LIST_LOADING,
        isGroupedListLoading: false,
      });
      console.error(error);
    });
};

export const updateGroupedList = (data, objId, patientId, callBack) => (
  dispatch
) => {
  dispatch({
    type: GROUPED_LISTS_LOADING,
    isGroupedListsLoading: true,
  });

  const method = "put";
  const url = config.BASE_URL + `/parse/classes/GroupedList/${objId}`;

  return parseAction(method, url, data, { "Content-Type": "application/json" })
    .then((result) => {
      dispatch({
        type: GROUPED_LISTS_LOADING,
        isGroupedListsLoading: false,
      });
      getGroupedLists(patientId)(dispatch);
      callBack();
      return result;
    })
    .catch((error) => {
      dispatch({
        type: GROUPED_LISTS_LOADING,
        isGroupedListsLoading: false,
      });
      return error;
    });
};

export const deleteGroupedList = (objectId, patientId, callBack) => (
  dispatch
) => {
  dispatch({
    type: GROUPED_LISTS_LOADING,
    isGroupedListsLoading: true,
  });

  const method = "delete";
  const url = config.BASE_URL + `/parse/classes/GroupedList/${objectId}`;

  return parseAction(method, url)
    .then((result) => {
      getGroupedLists(patientId)(dispatch);
      dispatch({
        type: GROUPED_LISTS_LOADING,
        isGroupedListsLoading: false,
      });
      callBack();
    })
    .catch((error) => {
      dispatch({
        type: GROUPED_LISTS_LOADING,
        isGroupedListsLoading: false,
      });
    });
};

export const requestOTP = (phoneNumber) => (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + "/parse/functions/requestOTP";

  return parseAction(
    method,
    url,
    {
      phoneNumber,
    },
    { "Content-Type": "application/json" }
  )
    .then((result) => {})
    .catch((error) => {
      console.log("error: ", error);
    });
};

export const eSignPDF = (data, eType = "prescription", callBack) => (
  dispatch
) => {
  const method = "post";
  const route =
    eType === "prescription" ? "esignPrescription" : "esignPatientOrder";
  const url = config.BASE_URL + `/parse/functions/${route}`;

  dispatch({
    type: E_SIGN_LOADING,
    isESignLoading: true,
  });

  return parseAction(method, url, data, { "Content-Type": "application/json" })
    .then((result) => {
      dispatch({
        type: E_SIGN_LOADING,
        isESignLoading: false,
      });
      callBack(result);
    })
    .catch((error) => {
      dispatch({
        type: E_SIGN_LOADING,
        isESignLoading: false,
      });
    });
};

export const validateOTP = (data, callBack) => (dispatch) => {
  const method = "post";
  const url = config.BASE_URL + `/parse/functions/validateOTP`;

  dispatch({
    type: E_SIGN_LOADING,
    isESignLoading: true,
  });

  return parseAction(method, url, data, { "Content-Type": "application/json" })
    .then((result) => {
      dispatch({
        type: E_SIGN_LOADING,
        isESignLoading: false,
      });
      callBack(result);
    })
    .catch((error) => {
      dispatch({
        type: E_SIGN_LOADING,
        isESignLoading: false,
      });
    });
};

export const setPrescriptionHistory = (data = {}) => (dispatch) => {
  dispatch({
    type: SET_PRESCRIPTION_HISTORY,
    prescriptionHistory: data,
  });
};

export const fetchPatientInfoReduced = (patientId) => (dispatch) => {
  const params = {
    id: patientId,
  };

  parseAction("post", config.BASE_URL + "/parse/functions/getPatient", params)
    .then(
      (response) => {
        let patientInfoReduced =
          response.result.patient.length > 0 ? response.result.patient[0] : {};
        patientInfoReduced.isCritical = response.result.isCritical;
        dispatch({
          type: SET_PATIENT_INFO_REDUCED,
          patientInfoReduced,
        });
      },
      (error) => {
        console.log("error: ", error);
      }
    )
    .catch((error) => {
      console.log("error: ", error);
      return new Error(error);
    });
};

export const fetchPatientMedications = (patientId) => (dispatch) => {
  const params = {
    id: patientId,
  };

  dispatch({
    type: IS_PATIENT_MEDICATIONS_LOADING,
    patientMedicationsLoading: true,
  });

  parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getMedications",
    params
  )
    .then(
      (response) => {
        const patient =
          response.result.patient.length > 0 ? response.result.patient[0] : {};
        const patientMedications = patient.medications || [];
        dispatch({
          type: SET_PATIENT_MEDICATIONS,
          patientMedications,
        });

        dispatch({
          type: IS_PATIENT_MEDICATIONS_LOADING,
          patientMedicationsLoading: false,
        });
      },
      (error) => {
        dispatch({
          type: IS_PATIENT_MEDICATIONS_LOADING,
          patientMedicationsLoading: false,
        });
      }
    )
    .catch((error) => {
      dispatch({
        type: IS_PATIENT_MEDICATIONS_LOADING,
        patientMedicationsLoading: false,
      });
      return new Error(error);
    });
};

export const fetchPatientProblems = (patientId) => (dispatch) => {
  const params = {
    id: patientId,
  };

  dispatch({
    type: IS_PATIENT_PROBLEMS_LOADING,
    patientProblemsLoading: true,
  });

  parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getProblemsList",
    params
  )
    .then(
      (response) => {
        const patient =
          response.result.patient.length > 0 ? response.result.patient[0] : {};
        const patientProblems = patient.problems || [];
        dispatch({
          type: SET_PATIENT_PROBLEMS,
          patientProblems,
        });

        dispatch({
          type: IS_PATIENT_PROBLEMS_LOADING,
          patientProblemsLoading: false,
        });
      },
      (error) => {
        dispatch({
          type: IS_PATIENT_PROBLEMS_LOADING,
          patientProblemsLoading: false,
        });
      }
    )
    .catch((error) => {
      dispatch({
        type: IS_PATIENT_PROBLEMS_LOADING,
        patientProblemsLoading: false,
      });
      return new Error(error);
    });
};

export const fetchPatientDiagnoses = (patientId) => (dispatch) => {
  const params = {
    id: patientId,
  };

  dispatch({
    type: IS_PATIENT_DIAGNOSES_LOADING,
    patientDiagnosesLoading: true,
  });

  parseAction(
    "post",
    config.BASE_URL + "/parse/functions/getPatientDiagnosis",
    params
  )
    .then(
      (response) => {
        const patient =
          response.result.patient.length > 0 ? response.result.patient[0] : {};
        const patientDiagnoses = patient.diagnosis || [];
        dispatch({
          type: SET_PATIENT_DIAGNOSES,
          patientDiagnoses,
        });

        dispatch({
          type: IS_PATIENT_DIAGNOSES_LOADING,
          patientDiagnosesLoading: false,
        });
      },
      (error) => {
        dispatch({
          type: IS_PATIENT_DIAGNOSES_LOADING,
          patientDiagnosesLoading: false,
        });
      }
    )
    .catch((error) => {
      dispatch({
        type: IS_PATIENT_DIAGNOSES_LOADING,
        patientDiagnosesLoading: false,
      });
      return new Error(error);
    });
};

export const fetchRecentResult = (page = 0, pageSize = 10) => (dispatch) => {
  const params = {
    page: page,
    pageSize: pageSize,
  };

  parseAction("post", config.BASE_URL + "/parse/functions/getHistory", params, {
    "Content-Type": "application/json",
  })
    .then(
      (response) => {
        dispatch({
          type: SET_RECENT_SEARCH,
          recentSearch: response.result.history,
        });
      },
      (error) => {
        console.log("error: ", error);
      }
    )
    .catch((error) => {
      console.log("error: ", error);
      return new Error(error);
    });
};
