import React, { Component } from "react";
import SettingContainer from "./Setting/SettingContainer";
import Parse from "parse";
// import PatientInfoModal from './Setting/PatientInfoModal'
import PatientDiagnosisModal from "./Setting/PatientDiagnosisModal";
import PrescriptionModal from "./Setting/PrescriptionModal";
import ActionsModal from "./Setting/ActionsModal";
import util from "../../helper/util";
import Notify from "../Common/Notify";
import { createConferenceCall, launchTelemed } from "../../actions/ConferenceAction";
import { fetchPatientInfo, fetchPatientInfoReduced } from "../../actions/PatientAction";
import { connect } from "react-redux";
import ConferenceHelper from "../../helper/conference";
import Loader from "../Common/Loader";
import { parseAction } from "../../actions/REST";
import MemberListModal from "./Setting/MemberListModal";
import ManageEventModal from "../Pages/ConferenceMain/ManageEventModal";
import config from "../../config";
import { ParticipantsSelectModal } from "./Setting/ParticipantsSelectModal";
import ContactBanner from "./ContactBanner";
const $ = window.$;

export class ConversationHeader extends Component {
  constructor() {
    super();
    this.state = {
      openNav: false,
      groupObject: {},
      // patientInfoShown: false,
      memberListShown: false,
      patientDxShown: false,
      patientRxShown: false,
      isLoading: false,
      actionsModalShown: false,
      modalShown: false,
      eventModalShown: false,
    };

    this.closeNav = this.closeNav.bind(this);
    this.saveGroupObject = this.saveGroupObject.bind(this);
    this.handleOpenManageEventModal = this.handleOpenManageEventModal.bind(this);
    this.handleCloseManageEventModal = this.handleCloseManageEventModal.bind(this);
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeThread } = nextProps;
    if (activeThread.threadId && activeThread.threadId != this.props.activeThread.threadId) {
      this.fetchPatient(activeThread.threadId);
    }
  }

  fetchPatient(threadId) {
    const { fetchPatientInfoReduced } = this.props;
    fetchPatientInfoReduced(threadId);
  }

  openNav() {
    this.setState({
      openNav: true,
    });
  }

  closeNav() {
    this.setState({
      openNav: false,
    });
  }

  goBack() {
    this.props.setActiveThread("");
    // this.props.history.goBack();
    this.props.history.push({
      pathname: "/",
    });
  }

  toggleCCM() {
    $('[data-toggle="tooltip"]').tooltip("hide");
    if (this.props.ccmIsOpen) {
      this.props.closeCCM();
    } else {
      this.props.openCCM();
    }
  }

  toggleDropbox() {
    if (this.props.documentIsOpen) {
      this.props.closeDocument();
    } else {
      this.props.openDocument();
    }
  }

  saveGroupObject(group, members) {
    let currentUserIsAdmin = false;
    for (let x = 0; x < group.administrators.length; x++) {
      members.forEach((element, index) => {
        if (element.user.objectId === group.administrators[x].objectId) {
          if (members[index].user) {
            if (members[index].user.objectId === Parse.User.current().id) {
              currentUserIsAdmin = true;
            }
          }
        }
      });
    }
    this.setState({
      groupObject: group,
      userIsAdmin: currentUserIsAdmin,
    });
  }

  showMemberList() {
    this.setState({ memberListShown: true });
  }

  showPatientInfo(data) {
    // this.setState({ patientInfoShown: true });
    if (data.editMode) {
      localStorage.setItem("fsState", JSON.stringify(data));
    }
    const filesUrl = `${window.location.origin}/facesheet/${this.props.activeThread.threadId}`;
    window.open(filesUrl, "Hubchart Facesheet");
  }

  hidePatientInfo() {
    this.setState({ patientInfoShown: false });
  }

  showDxInfo() {
    this.setState({ patientDxShown: true });
  }

  hideDxInfo() {
    this.setState({ patientDxShown: false });
  }

  showRxInfo() {
    this.setState({ patientRxShown: true });
  }

  showActionsModal() {
    this.setState({ actionsModalShown: true });
  }

  hideActionsModal() {
    this.setState({ actionsModalShown: false });
  }

  hideRxInfo() {
    this.setState({ patientRxShown: false });
  }

  hideMemberList() {
    $("#memberListModal").modal("hide");
    this.setState({ memberListShown: false });
  }

  handleCall() {
    const { activeThread } = this.props;

    debugger;

    const { partnerObjectId } = activeThread;

    return this.props.launchTelemed([partnerObjectId]);
  }

  handleTelemed() {
    const { activeThread } = this.props;

    return this.props.launchTelemed([], {
      circleId: activeThread.threadId,
    });
  }

  handleStart(selected = []) {
    const { activeThread } = this.props;

    return this.props.launchTelemed(selected, {
      circleId: activeThread.threadId,
    });
  }

  showSelectParticipants() {
    this.setState({
      modalShown: true,
    });
  }

  hideModal() {
    this.setState({
      modalShown: false,
    });
  }

  setLoading(boolean, message) {
    this.setState({
      isLoading: boolean,
      loadingMessage: message,
    });
  }

  openFilesWindow() {
    const imageUrl = this.getImageUrl(this.props.activeThread);

    const threadName = this.getThreadName(this.props.activeThread);

    const filesUrl = [
      window.location.origin,
      "/files",
      "/" + this.props.activeThread.threadId,
      "?imageUrl=" + window.encodeURIComponent(imageUrl),
      "&threadName=" + window.encodeURIComponent(threadName),
    ].join("");

    window.open(filesUrl, "Hubchart Files"); // "status=1,menubar=0"
    // window.location.href = filesUrl;
  }

  openProgressNotes() {
    const filesUrl = `${window.location.origin}/progressnotes/${this.props.activeThread.threadId}`;
    window.open(filesUrl, "Hubchart Progress Notes", "_blank");
  }

  getImageUrl(activeThread) {
    let Image;

    switch (activeThread.threadType) {
      case "private":
        Image = activeThread.partnerImageURL;
        break;

      case "group":
        Image = activeThread.groupImageURL;
        break;

      case "adhoc":
        Image = activeThread.groupImageURL;
        break;

      default:
        Image = "";
    }

    return Image;
  }

  getThreadName(activeThread) {
    let Name;

    switch (activeThread.threadType) {
      case "private":
        Name = activeThread.partnerName;
        break;

      case "group":
        Name = activeThread.groupName;
        break;

      case "adhoc":
        Name = "Private Group";
        break;

      default:
        Name = "";
    }

    return Name;
  }

  handleOpenManageEventModal() {
    this.setState({
      eventModalShown: true,
    });
  }

  handleCloseManageEventModal() {
    this.setState({
      eventModalShown: false,
    });
  }

  render() {
    const { activeThread } = this.props;
    let Name;
    let SubgroupName;
    let AdhocName;
    let showSetting = false;
    let Image;
    let Title;
    let isSubgroup = false;
    let isCCM = false;
    let NameStyle = {
      lineHeight: 1.2,
    };

    let SmallNameStyle = {
      fontSize: "13px",
      // position: "relative",
      bottom: "10px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      // display: "block",
      maxWidth: "250px",
    };

    let patientDOBStyle = {
      color: "black",
      fontSize: "13px",
      position: "relative",
      bottom: "4px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      display: "block",
    };

    let imgStyle = {};

    switch (activeThread.threadType) {
      case "private":
        Title = "Conversation Setting";
        Name = activeThread.partnerName;
        showSetting = true;
        if (typeof activeThread.partnerImageURL === "undefined" || activeThread.partnerImageURL === "") {
          Image = (
            <img
              style={imgStyle}
              src={require("../../assets/images/default.png")}
              alt=""
              className="header-img profile-image rounded-circle"
            />
          );
        } else {
          Image = (
            <img
              style={imgStyle}
              src={activeThread.partnerImageURL}
              alt=""
              className="header-img profile-image rounded-circle"
            />
          );
        }
        break;

      case "group":
        Title = "Group Conversation Setting";
        Name = activeThread.groupName.toUpperCase();
        showSetting = true;
        var urlImage = require("../../assets/images/group-default.png");
        if (activeThread.currentPlan === "none") {
          // patientColorScheme.color = "#ff99c7";
          urlImage = require("../../assets/images/none-default.png");
        }
        if (activeThread.currentPlan === "nf2f") {
          // patientColorScheme.color = "rgb(238, 151, 73)";
          urlImage = require("../../assets/images/nf2f-default.png");
        }
        if (activeThread.currentPlan === "ccm") {
          // patientColorScheme.color = "#9550b3";
          urlImage = require("../../assets/images/ccm-default.png");
        }
        if (activeThread.currentPlan === "tcm") {
          // patientColorScheme.color = "#00b9ea";
          urlImage = require("../../assets/images/tcm-default.png");
        }
        if (activeThread.currentPlan === "cpo") {
          // patientColorScheme.color = "#23aaa0";
          urlImage = require("../../assets/images/cpo-default.png");
        }

        if (!activeThread.groupImageURL) {
          Image = <img style={imgStyle} src={urlImage} alt="" className="header-img rounded-circle" />;
        } else {
          Image = (
            <img style={imgStyle} src={activeThread.groupImageURL} alt="" className="header-img rounded-circle" />
          );
        }

        if (activeThread.subgroupSession) {
          isSubgroup = true;
          SubgroupName = activeThread.subgroupName;
          NameStyle.position = "relative";
          NameStyle.top = "-5px";
          NameStyle.color = "#d32f2f";
          SmallNameStyle.color = "#283593";
        }

        if (activeThread.isCCM) {
          isCCM = true;
          SubgroupName = activeThread.subgroupName;
          NameStyle.position = "relative";
          // NameStyle.top = "-5px";
          NameStyle.color = "rgb(62, 120, 178)";
          SmallNameStyle.color = "rgb(62, 120, 178)";
        }
        break;

      case "adhoc":
        Name = "Private Group";
        let adhocName = [];
        for (let x = 0; x < activeThread.adhocGroupMembers.length; x++) {
          const element = activeThread.adhocGroupMembers[x];
          adhocName.push(element.name);
        }

        if (!activeThread.senderImageURL) {
          Image = <img style={imgStyle} src={urlImage} alt="" className="header-img rounded-circle" />;
        } else {
          Image = (
            <img style={imgStyle} src={activeThread.senderImageURL} alt="" className="header-img rounded-circle" />
          );
        }

        AdhocName = adhocName.join(", ");
        NameStyle.position = "relative";
        break;
      default:
    }

    let showCCMButton = false;

    if (this.props.activeThread.threadId === this.state.groupObject.objectId) {
      if (this.state.userIsAdmin) {
        showCCMButton = true;
      }
    }

    const isPatient = activeThread.threadType === "group" && activeThread.groupType === "patient";

    let age;

    if (activeThread.patientDOB) {
      age = util.computeAge(activeThread.patientDOB);
    }

    const { isLoading, loadingMessage } = this.state;
    const { patientInfoReduced } = this.props;

    let allergies = "";

    if (patientInfoReduced.allergies) {
      const suffix = patientInfoReduced.allergies.length > 10 ? "..." : "";

      allergies = patientInfoReduced.allergies.substr(0, 10) + suffix;
    }

    const { contactStatus } = this.props;

    const showCallButtons = activeThread.threadType === "private" && contactStatus === "accepted";

    const showBanner = activeThread.threadType === "private";

    return (
      <>
        <div className="Conversation-Header d-flex flex-column">
          <div>
            {this.props.isMobile && (
              <i
                onClick={this.goBack.bind(this)}
                className="fas fa-chevron-left"
                style={{
                  fontSize: "24px",
                  color: "#908e8e",
                  paddingTop: "15px",
                }}
              />
            )}
            <div className="Header-Details">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!isPatient && <div className="Image-Container">{Image}</div>}
                {isPatient ? (
                  <div className="Patient-Details">
                    <div style={{ display: "flex" }}>
                      <div className="Image-Container">{Image}</div>
                      <div>
                        {!isPatient && !showSetting && (
                          <p style={NameStyle} className="Patient-Thread-Name">
                            {Name}
                          </p>
                        )}
                        {isPatient && showSetting && (
                          <a
                            style={{ ...NameStyle, cursor: "pointer" }}
                            className="Patient-Thread-Name"
                            onClick={this.showPatientInfo.bind(this)}
                          >
                            {Name}
                          </a>
                        )}
                        <span style={patientDOBStyle} className="mt-1">
                          {isCCM ? (
                            <span className="mr-1">
                              <span className="badge badge-primary mr-1">ADMIN</span>
                              <span style={{ color: "rgb(62, 120, 178)" }}>{activeThread.groupTags}</span>
                            </span>
                          ) : (
                            ""
                          )}
                          <span className="mr-1">{activeThread.patientDOB}</span>

                          {age ? <span>({age + " yo"})</span> : ""}
                          {patientInfoReduced.gender ? <span>({patientInfoReduced.gender})</span> : ""}
                        </span>
                        {patientInfoReduced.isCritical ? (
                          <span
                            style={{ bottom: "7px", position: "relative" }}
                            class="badge badge-pill badge-danger text-xs mr-0"
                          >
                            Critical
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="ml-3 pt-1">
                        <div className="m-cls-50">
                          <a
                            className="facesheet-link"
                            href="#!"
                            onClick={this.showPatientInfo.bind(this, {
                              editMode: true,
                              inputId: "insuranceType",
                            })}
                            style={patientDOBStyle}
                          >
                            <span className="hc-primary-text mr-1">
                              <i className="fas fa-credit-card"></i>
                            </span>
                            <InsuranceProvider insurance={patientInfoReduced.primaryInsurance} />
                          </a>
                          <a
                            className="facesheet-link"
                            href="#!"
                            onClick={this.showPatientInfo.bind(this, {
                              editMode: true,
                              inputId: "planName",
                            })}
                            style={patientDOBStyle}
                          >
                            <span className="hc-primary-text mr-1">
                              <i className="fas fa-book-open mr-1"></i>
                            </span>
                            <PlanName insurance={patientInfoReduced.primaryInsurance} />
                          </a>
                          <a
                            className="facesheet-link"
                            href="#!"
                            onClick={this.showPatientInfo.bind(this, {
                              editMode: true,
                              inputId: "cprStatus",
                            })}
                            style={patientDOBStyle}
                          >
                            <span className="hc-primary-text mr-1">
                              <i className="fas fa-procedures mr-1"></i>
                            </span>
                            {patientInfoReduced.cprStatus ? (
                              patientInfoReduced.cprStatus
                            ) : (
                              <span className="text-blank">CPR/Code status</span>
                            )}
                          </a>
                        </div>
                      </div>
                      <div className="ml-3 pt-1">
                        <a
                          className="facesheet-link"
                          href="#!"
                          onClick={this.showPatientInfo.bind(this, {
                            editMode: true,
                            inputId: "allergies",
                          })}
                          style={patientDOBStyle}
                        >
                          <span className="hc-primary-text mr-1">
                            <i className="fas fa-allergies mr-1"></i>
                            Allergies:
                          </span>
                          {allergies || <span className="text-blank">none</span>}
                        </a>
                        <a
                          className="facesheet-link"
                          href="#!"
                          onClick={this.showPatientInfo.bind(this, {
                            editMode: true,
                            inputId: "pos",
                          })}
                          style={patientDOBStyle}
                        >
                          <span className="hc-primary-text mr-1">
                            <i className="fas fa-map-marked-alt mr-1"></i>
                            POS:
                          </span>
                          {patientInfoReduced.serviceLocation ? (
                            patientInfoReduced.serviceLocation.name
                          ) : (
                            <span className="text-blank">Service location</span>
                          )}
                        </a>
                        <a
                          className="facesheet-link"
                          href="#!"
                          onClick={this.showPatientInfo.bind(this, {
                            editMode: true,
                            inputId: "advanceDirective",
                          })}
                          style={patientDOBStyle}
                        >
                          <span className="hc-primary-text mr-1">
                            <i className="fas fa-file-medical mr-1"></i>
                          </span>
                          {patientInfoReduced.advanceDirective ? (
                            patientInfoReduced.advanceDirective
                          ) : (
                            <span className="text-blank">Advance Directive</span>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p style={NameStyle} className="Thread-Name">
                      {Name}
                    </p>
                    {activeThread.threadType === "adhoc" && (
                      <div style={SmallNameStyle} title={AdhocName}>
                        {AdhocName}
                      </div>
                    )}
                  </div>
                )}
                {isSubgroup && (
                  <span style={SmallNameStyle}>
                    <span
                      style={{
                        background: "#283593",
                        position: "relative",
                        top: "-1px",
                      }}
                      className="badge badge-primary"
                    >
                      SUBGROUP
                    </span>{" "}
                    {SubgroupName}
                  </span>
                )}
              </div>

              <div className="web-view">
                {activeThread.threadType === "group" && (
                  <button
                    onClick={() => this.showSelectParticipants()}
                    className="Setting-Button"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-html="true"
                    title="Start telemed"
                  >
                    <i className="fas fa-video"></i>
                  </button>
                )}
                {activeThread.threadType === "group" && (
                  <button
                    onClick={() => this.handleOpenManageEventModal()}
                    className="Setting-Button"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-html="true"
                    title="Schedule telemed"
                  >
                    <i className="fas fa-calendar"></i>
                  </button>
                )}
                {showCallButtons && (
                  <button
                    onClick={() => this.handleCall(false)}
                    className="Setting-Button"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-html="true"
                    title="Start a call"
                  >
                    <i className="fas fa-phone fa-flip-horizontal"></i>
                  </button>
                )}
                {showCallButtons && (
                  <button
                    onClick={() => this.handleCall(true)}
                    className="Setting-Button"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-html="true"
                    title="Start a video call"
                  >
                    <i className="fas fa-video"></i>
                  </button>
                )}
                {activeThread.isCCM && showCCMButton && (
                  <button
                    onClick={this.toggleCCM.bind(this)}
                    className="Setting-Button"
                    style={{ paddingBottom: "7px" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    data-html="true"
                    title="Chronic Care Management"
                  >
                    <span className="CCM-Button" style={{ padding: "3px 10px" }}>
                      CCM
                    </span>
                  </button>
                )}
                {activeThread.threadType === "group" && (
                  <button onClick={this.showMemberList.bind(this)} className="Setting-Button">
                    <i
                      className="fa fa-users"
                      data-toggle="tooltip"
                      data-placement="top"
                      data-html="true"
                      title="Members"
                    ></i>
                  </button>
                )}
                {showSetting && (
                  <button className="Setting-Button">
                    <i
                      onClick={this.openNav.bind(this)}
                      className="fas fa-cog"
                      data-toggle="tooltip"
                      data-placement="top"
                      data-html="true"
                      title={Title}
                    ></i>
                  </button>
                )}
              </div>
              <div className="mobile-view">
                <div class="dropdown">
                  <button
                    class="Setting-Button"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-ellipsis-v-alt"></i>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {activeThread.threadType === "group" && (
                      <a className="dropdown-item ddi-custom" onClick={() => this.showSelectParticipants()}>
                        <i className="fas fa-video fa-fw"></i> Start Telemed
                      </a>
                    )}
                    {activeThread.threadType === "group" && (
                      <a className="dropdown-item ddi-custom" onClick={() => this.handleOpenManageEventModal()}>
                        <i className="fas fa-calendar fa-fw"></i> Schedule Telemed
                      </a>
                    )}
                    {showCallButtons && (
                      <a className="dropdown-item ddi-custom" onClick={() => this.handleCall(false)}>
                        <i className="fas fa-phone fa-flip-horizontal fa-fw"></i> Start a Call
                      </a>
                    )}
                    {showCallButtons && (
                      <a className="dropdown-item ddi-custom" onClick={() => this.handleCall(true)}>
                        <i className="fas fa-video fa-fw"></i> Start Telemed
                      </a>
                    )}
                    {activeThread.isCCM && showCCMButton && (
                      <a className="dropdown-item ddi-custom" onClick={this.toggleCCM.bind(this)}>
                        <span className="CCM-Button" style={{ padding: "3px 10px" }}>
                          CCM
                        </span>
                      </a>
                    )}
                    {activeThread.threadType === "group" && (
                      <a className="dropdown-item ddi-custom" onClick={this.showMemberList.bind(this)}>
                        <i className="fa fa-users fa-fw"></i> Members
                      </a>
                    )}
                    {showSetting && (
                      <a className="dropdown-item ddi-custom" onClick={this.openNav.bind(this)}>
                        <i className="fas fa-cog fa-fw"></i> {Title}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!this.props.isFetching && showBanner ? (
            <ContactBanner
              contactId={activeThread.partnerObjectId}
              status={this.props.contactStatus}
              blocked={this.props.blocked}
            />
          ) : (
            ""
          )}

          <SettingContainer
            isOpen={this.state.openNav}
            closeNav={this.closeNav}
            thread={activeThread}
            saveGroupMembers={this.props.saveGroupMembers}
            saveGroupObject={this.saveGroupObject}
          />

          {this.state.actionsModalShown && (
            <ActionsModal
              thread={activeThread}
              threadId={activeThread.threadId}
              isOpen={this.state.actionsModalShown}
              onClose={this.hideActionsModal.bind(this)}
            />
          )}

          {this.state.patientRxShown && (
            <PrescriptionModal
              thread={activeThread}
              threadId={activeThread.threadId}
              isOpen={this.state.patientRxShown}
              onClose={this.hideRxInfo.bind(this)}
              openDocuments={this.openFilesWindow.bind(this)}
            />
          )}

          {this.state.patientDxShown && (
            <PatientDiagnosisModal
              threadId={activeThread.threadId}
              isOpen={this.state.patientDxShown}
              onClose={this.hideDxInfo.bind(this)}
            />
          )}

          {/* {this.state.patientInfoShown &&
                    <PatientInfoModal threadId={activeThread.threadId} isOpen={this.state.patientInfoShown} onClose={this.hidePatientInfo.bind(this)} />
                } */}

          {this.state.memberListShown && (
            <MemberListModal
              onNewMember={this.props.onNewMember}
              threadId={activeThread.threadId}
              isOpen={this.state.memberListShown}
              onClose={this.hideMemberList.bind(this)}
            />
          )}

          {this.state.modalShown && (
            <ParticipantsSelectModal
              threadId={activeThread.threadId}
              isOpen={this.state.modalShown}
              onClose={this.hideModal.bind(this)}
              onStart={this.handleStart.bind(this)}
            />
          )}

          {this.state.eventModalShown && (
            <ManageEventModal
              isOpen={this.state.eventModalShown}
              onClose={() => this.handleCloseManageEventModal()}
              title={Name}
            />
          )}

          <Loader isLoading={isLoading} message={loadingMessage} />
        </div>
      </>
    );
  }
}

const InsuranceProvider = ({ insurance }) => {
  if (!insurance) return <span className="text-blank">Insurance</span>;

  const { provider } = insurance;

  if (!provider) return <span className="text-blank">Insurance</span>;

  return <span>{provider.name}</span>;
};

const PlanName = ({ insurance }) => {
  if (!insurance) return <span className="text-blank">Plan name</span>;

  return <span>{insurance.planName}</span>;
};

const mapStateToProps = (state) => ({
  patientInfoReduced: state.patient.patientInfoReduced,
});

export default connect(mapStateToProps, {
  createConferenceCall,
  launchTelemed,
  fetchPatientInfo,
  fetchPatientInfoReduced,
})(ConversationHeader);
