import { filter } from "lodash";
import {
  SET_APP_STATE,
  SET_IDLE_MODAL_STATE,
  ORGANIZATION_FETCH_STATUS,
  FETCH_ORGANIZATION,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_IMAGE,
  NEW_ORGANIZATION_MEMBERS,
  NEW_ORGANIZATION_GUESTS,
  REMOVE_ORGANIZATION_MEMBERS,
  REMOVE_ORGANIZATION_GUESTS,
  NEW_ADMIN_ORGANIZATION_MEMBERS,
  REMOVE_ADMIN_ORGANIZATION_MEMBERS,
  FETCH_ORGANIZATION_GROUPS,
  ADD_GROUP_TO_ORGANIZATION,
  REMOVE_GROUP_TO_ORGANIZATION,
  UPDATE_INSTALLATION_ID,
  REVENUE_CAT_SUBSCRIBER,
  FETCH_PHYSICIANS_AND_EDITORS,
  PHYSICIAN_ROLE_USERS,
  EDITOR_ROLE_USERS,
  SET_ORGANIZATION_ROLE,
} from "../actions/types";

const initialState = {
  appFirstLoad: false,
  isLogin: false,
  installationId: "",
  appState: "Visible",
  idleModalState: "hidden",
  organization: {},
  organizationFetchStatus: "",
  organizationGroups: [],
  users_with_role: [],
  physicians: [],
  editors: [],
  organizationGroupsInitialLoad: false,
  revenueCatSubscriber: {},
  addingOrgMembers: false,
  currentUser: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "ADD_ORG_EMAIL":
      return {
        ...state,
        organization: {
          ...state.organization,
          emails: [...state.organization.emails, action.email],
        },
      };
    case "REMOVE_ORG_EMAIL":
      const filtered = state.organization.emails.filter((email) => {
        return email != action.email;
      });

      return {
        ...state,
        organization: {
          ...state.organization,
          emails: filtered,
        },
      };

    case SET_APP_STATE:
      return {
        ...state,
        appState: action.state,
      };
      break;

    case SET_IDLE_MODAL_STATE:
      return {
        ...state,
        idleModalState: action.state,
      };
      break;

    case ORGANIZATION_FETCH_STATUS:
      return {
        ...state,
        organizationFetchStatus: action.status,
      };
      break;

    case FETCH_ORGANIZATION:
      return {
        ...state,
        organization: action.organization,
        organizationFetchStatus: action.status,
      };
      break;

    case UPDATE_ORGANIZATION:
      return {
        ...state,
        organization: {
          ...state.organization,
          name: action.name,
        },
      };
      break;

    case UPDATE_ORGANIZATION_IMAGE:
      let newImage = {
        ...action.image,
        url: action.image._url,
      };
      return {
        ...state,
        organization: {
          ...state.organization,
          image: newImage,
        },
      };
      break;

    case NEW_ORGANIZATION_MEMBERS:
      return {
        ...state,
        organization: {
          ...state.organization,
          members: [...action.members, ...state.organization.members],
        },
      };
      break;

    case "ADDING_ORGANIZATION_MEMBERS":
      return {
        ...state,
        addingOrgMembers: action.value,
      };
      break;

    case NEW_ORGANIZATION_GUESTS:
      return {
        ...state,
        organization: {
          ...state.organization,
          guests: [...action.guests, ...state.organization.guests],
        },
      };
      break;

    case REMOVE_ORGANIZATION_MEMBERS:
      if (!state.organization.members) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        organization: {
          ...state.organization,
          members: [...state.organization.members.filter((item) => item.objectId !== action.objectId)],
          administrators: [...state.organization.administrators.filter((item) => item.objectId !== action.objectId)],
        },
      };
      break;

    case REMOVE_ORGANIZATION_GUESTS:
      if (!state.organization.guests) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        organization: {
          ...state.organization,
          guests: [...state.organization.guests.filter((item) => item.objectId !== action.objectId)],
        },
      };
      break;

    case NEW_ADMIN_ORGANIZATION_MEMBERS:
      return {
        ...state,
        organization: {
          ...state.organization,
          administrators: [action.user, ...state.organization.administrators],
        },
      };
      break;
    default:

    case REMOVE_ADMIN_ORGANIZATION_MEMBERS:
      if (!state.organization.administrators) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        organization: {
          ...state.organization,
          administrators: [...state.organization.administrators.filter((item) => item.objectId !== action.objectId)],
        },
      };
      break;

    case FETCH_ORGANIZATION_GROUPS:
      return {
        ...state,
        organizationGroups: action.groups,
        organizationGroupsInitialLoad: true,
      };
      break;

    case ADD_GROUP_TO_ORGANIZATION:
      return {
        ...state,
        organizationGroups: [
          ...state.organizationGroups.map((item) => {
            if (item.objectId === action.objectId) {
              return {
                ...item,
                excludeFromOrganization: false,
              };
            }
            return item;
          }),
        ],
      };
      break;

    case REMOVE_GROUP_TO_ORGANIZATION:
      return {
        ...state,
        organizationGroups: [
          ...state.organizationGroups.map((item) => {
            if (item.objectId === action.objectId) {
              return {
                ...item,
                excludeFromOrganization: true,
              };
            }
            return item;
          }),
        ],
      };
      break;

    case UPDATE_INSTALLATION_ID:
      return {
        ...state,
        installationId: action.installationId,
      };
      break;

    case REVENUE_CAT_SUBSCRIBER:
      return {
        ...state,
        revenueCatSubscriber: action.subscriber,
      };
      break;

    case FETCH_PHYSICIANS_AND_EDITORS:
      return {
        ...state,
        users_with_role: action.users_with_role,
      };
      break;

    case PHYSICIAN_ROLE_USERS:
      return {
        ...state,
        physicians: action.physicians,
      };
      break;

    case EDITOR_ROLE_USERS:
      return {
        ...state,
        editors: action.editors,
      };
      break;

    case SET_ORGANIZATION_ROLE:
      return {
        ...state,
      };
      break;

      return state;
  }
}
