import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Parse from "parse";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { parseAction } from "../../../../actions/REST";
import Loader from "../../../Common/Loader";
import Notify from "../../../Common/Notify";
import AddProblemsModal from "./AddProblemsModal";
import config from "../../../../config";
import { computeAge } from "../../../../helper/util";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../Pages/Login/Login.css";
import "../SettingModal.css";

const $ = window.$;
const modalId = "#progressNotesModal";
const defaultSelectedProblem = {
  problem: "",
  onsetDate: new Date(),
  is_active: false,
  is_resolved: false,
  type: "manual",
};

export default function PatientProblems({
  isOpen = false,
  threadId = "",
  refreshData = () => {},
  onClose = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [manualDxEntryDisabled, setIsManualDxEntryDisabled] = useState(false);
  const [addProblemsModalShown, setAddProblemsModalShown] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [selectedProblems, setSelectedProblems] = useState([]);
  const [selectedTempProblems, setSelectedTempProblems] = useState([
    { ...defaultSelectedProblem },
    { ...defaultSelectedProblem },
    { ...defaultSelectedProblem },
    { ...defaultSelectedProblem },
    { ...defaultSelectedProblem },
  ]);
  const [method, setMethod] = useState("create");

  function fetchPatientInfo(threadId) {
    setIsLoading(true);
    parseAction("get", config.BASE_URL + "/parse/classes/Circle", {
      where: {
        objectId: threadId,
      },
    })
      .then((result) => {
        const data = result.results[0];
        const problems = (data.problems || []).map((item) => ({
          ...item,
          onsetDate: (item.onsetDate || {}).iso
            ? new Date(item.onsetDate.iso)
            : "",
        })); // will be replaced with right props from backend
        setPatientInfo(data);
        setSelectedProblems(problems); //
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  function formatCircle() {
    const data = {
      objectId: patientInfo.objectId,
      problems: selectedProblems,
    };

    const Circle = Parse.Object.extend("Circle");
    const newCircle = new Circle(data);

    handleSave(newCircle);
  }

  async function handleSave(circle) {
    setIsLoading(false);

    try {
      const group = await circle.save();

      setPatientInfo(group.toJSON());
      setIsLoading(false);

      Notify.success("Problems successfully saved.");
      refreshData();
      $("#progressNotesModal").modal("hide");
    } catch (err) {
      setIsLoading(false);
      Notify.error("Could not save problems.");
    }
  }

  function setLoading(boolean) {
    setIsLoading(boolean);
  }

  function removeItem(item) {
    let problems = [...selectedProblems];
    problems.splice(problems.indexOf(item), 1);

    setSelectedProblems(problems);
  }

  function updateOnsetDate(item, date) {
    const problems = [...selectedProblems];
    const item_index = problems.indexOf(item);
    problems[item_index].onsetDate = date;

    setSelectedProblems(problems);
  }

  function manualProblemEntry() {
    setIsManualDxEntryDisabled(true);
    setTimeout(() => {
      const newSelectedProblems = [
        {
          problem: "",
          onsetDate: new Date(),
          is_active: false,
          is_resolved: false,
          type: "manual",
        },
        ...selectedProblems,
      ];
      setSelectedProblems(newSelectedProblems);
      setIsManualDxEntryDisabled(false);
    }, 100);
  }

  function onInputChange(e, idx) {
    const { value } = e.target;
    let problems = [...selectedProblems];

    problems[idx].problem = value;
    setSelectedProblems(problems);
  }

  function onInputCheckedChange(e, idx) {
    const { checked, name } = e.target;
    let problems = [...selectedProblems];

    problems[idx][name] = checked;
    setSelectedProblems(problems);
  }

  function onInputProblemChange(e, idx) {
    const { value } = e.target;
    let problems = [...selectedProblems];
    problems[idx].problem = value;

    setSelectedProblems(problems);
  }

  function calculateAge(dob) {
    const newDate = new Date(dob);
    return moment().diff(moment(newDate, "YYYY"), "years");
  }

  function handleChangeTextAreaDetails(e, i) {
    const { value } = e.target;
    let newArr = [...selectedTempProblems];
    newArr[i].problem = value;

    setSelectedTempProblems([...newArr]);
  }

  function addTextAreaField() {
    setSelectedTempProblems([
      {
        ...defaultSelectedProblem,
        problem: "",
      },
      ...selectedTempProblems,
    ]);
  }

  function removeTextAreaField(i) {
    let newArr = selectedTempProblems;
    newArr.splice(i, 1);
    setSelectedTempProblems([...newArr]);
  }

  function closeAddProblemsModal() {
    // $("#patientAddProblemsModal").modal('hide');
    setAddProblemsModalShown(false);
  }

  function handleSetAddProblemsModalShown() {
    setAddProblemsModalShown(true);
    setTimeout(() => {
      $("#patientAddProblemsModal").modal({
        backdrop: false,
        keyboard: false,
      });
      $("#patientAddProblemsModal").modal("show");
    }, 100);
  }

  function handleSaveTempProblems() {
    const filteredSelectedTempProblems = selectedTempProblems.filter(
      (item) => item.problem
    );
    const newProblems = [...filteredSelectedTempProblems, ...selectedProblems];

    setSelectedProblems(newProblems);
    setSelectedTempProblems([
      { ...defaultSelectedProblem },
      { ...defaultSelectedProblem },
      { ...defaultSelectedProblem },
      { ...defaultSelectedProblem },
      { ...defaultSelectedProblem },
    ]);
    closeAddProblemsModal();
  }

  useEffect(() => {
    if (isOpen) {
      localStorage.setItem("someModalIsOpen", "true");
      $(modalId).modal({
        backdrop: "static",
        keyboard: false,
      });
      $(modalId).modal("show");
      $(".modal-dialog").draggable({
        handle: ".modal-header",
      });
      fetchPatientInfo(threadId);
    } else {
      $(modalId).modal("hide");
    }
    $(modalId).on(
      "hidden.bs.modal",
      function(e) {
        localStorage.removeItem("someModalIsOpen");
        onClose();
      }.bind(this)
    );

    $("#patientAddProblemsModal").on(
      "hidden.bs.modal",
      function(e) {
        setAddProblemsModalShown(false);
      }.bind(this)
    );
  }, [isOpen]);

  return (
    <div
      id="progressNotesModal"
      className="modal"
      tabIndex="-1"
      role="dialog"
    >
      <div
        className="modal-dialog modal-print"
        role="document"
        style={{ maxWidth: "1000px" }}
      >
        <div className="modal-content modal-full-height">
          <div className="modal-header">
            <h5 className="hc-primary-text" style={{ fontSize: "1.25rem" }}>
              Patient Problems
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body body-style" style={{ overflow: "auto" }}>
            <div className="details-container">
              <div>
                <div className="patient-details">
                  <span>
                    <i className="fas fa-fw fa-hospital-user"></i> Patient Name:{" "}
                  </span>
                  <span>{patientInfo.name}</span>
                </div>
                <div className="patient-details">
                  <span>
                    <i className="fas fa-fw fa-address-card"></i> Address:{" "}
                  </span>
                  <span>{patientInfo.address}</span>
                </div>
                <div className="patient-details">
                  <span>
                    {" "}
                    <i className="fas fa-fw fa-calendar-day"></i> Birth date:{" "}
                  </span>
                  <span>
                    {patientInfo.dob
                      ? `${patientInfo.dob} (${computeAge(
                          patientInfo.dob
                        )} yrs old)`
                      : "--"}
                  </span>
                </div>
              </div>
              <div>
                <div className="patient-details">
                  <span>
                    <i className="fas fa-fw fa-phone"></i> Contact No.:{" "}
                  </span>
                  <span>{patientInfo.phoneNumber}</span>
                </div>
                <div className="patient-details">
                  <span>
                    <i className="fas fa-fw fa-allergies"></i> Allergies:{" "}
                  </span>
                  <span>{patientInfo.allergies}</span>
                </div>
              </div>
            </div>
            <hr style={{ margin: "0px 0px 15px 0px" }} />
            <div className="filter-view">
              <div className="search-flex search-identifier">
                <div className="rbt-container">
                  <div />
                </div>
                <button
                  type="button"
                  className="btn btn-hubchart btn-sm btn-hubchart-primary"
                  disabled={manualDxEntryDisabled}
                  onClick={() =>
                    handleSetAddProblemsModalShown()
                  } /* manualProblemEntry() */
                  style={{ margin: "0px", height: "33px", width: "170px" }}
                >
                  <i className="fas fa-plus"></i> Add
                </button>
              </div>
            </div>
            <div className="problems" style={{ overflowY: "auto" }}>
              {selectedProblems.length > 0 && (
                <div className="setting-modal-headers">
                  <span>Problem</span>
                  <span>Date Entered</span>
                  {/* <span>Active</span> */}
                  <span>Resolved</span>
                  <span>Action</span>
                </div>
              )}
              <div className="setting-modal-item-container">
                {selectedProblems.map((item, i) => (
                  <div
                    className="setting-modal-item"
                    key={`pms-${moment(new Date()).format("lll")}-${i}`}
                  >
                    <TextareaAutosize
                      className="setting-modal-input-2"
                      value={item.problem}
                      minRows={1.3}
                      onChange={(e) => onInputProblemChange(e, i)}
                    />
                    <span>
                      <DatePicker
                        className="date-picker-custom black"
                        placeholderText="mm/dd/yyyy"
                        selected={item.onsetDate}
                        onChange={(date) => updateOnsetDate(item, date)}
                        isClearable
                      />
                    </span>
                    {/* <span>
                      <input 
                        type="checkbox" 
                        id="is_active" 
                        name="is_active" 
                        checked={item.is_active}
                        onChange={e => onInputCheckedChange(e, i)} />
                    </span> */}
                    <span>
                      <input
                        type="checkbox"
                        id="is_resolved"
                        name="is_resolved"
                        checked={item.is_resolved}
                        style={{ marginTop: "10px" }}
                        onChange={(e) => onInputCheckedChange(e, i)}
                      />
                    </span>
                    <span>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        title="Remove Problem"
                        onClick={() => removeItem(item)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </span>
                  </div>
                ))}
              </div>
            </div>
            {selectedProblems.length === 0 && (
              <div className="default-view">
                <span>No Problems Found.</span>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-hubchart btn-hubchart-primary"
              onClick={() => formatCircle()}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-hubchart btn-hubchart-close"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {addProblemsModalShown && (
        <AddProblemsModal
          handleChangeTextAreaDetails={handleChangeTextAreaDetails}
          handleCloseAddProblemsModal={closeAddProblemsModal}
          addTextAreaField={addTextAreaField}
          removeTextAreaField={removeTextAreaField}
          saveTempProblems={handleSaveTempProblems}
          selectedTempProblems={selectedTempProblems}
          method={method}
        />
      )}
      <Loader isLoading={isLoading} />
    </div>
  );
}
