import React from "react";
import ImageLoad from "./Bubble/ImageLoad";
import ImagePlaceholder from "../Common/ImagePlaceholder";
import DOMPurify from "dompurify";

const ReplyTo = ({ replyTo = {} }) => {
  const { fileType, fileUrl } = replyTo;

  const detectType = (type = "") => {
    const typeRegex = {
      image: /(gif|jpe?g|tiff?|png|webp|bmp)$/i,
      video: /(mov|avi|wmv|flv|3gp|mp4|mpg)$/i,
      audio: /(mp3|wav)$/i,
      pdf: /pdf/i,
    };

    if (typeRegex.image.test(type)) return "image";
    if (typeRegex.video.test(type)) return "video";
    if (typeRegex.audio.test(type)) return "audio";
    if (typeRegex.pdf.test(type)) return "pdf";

    return "";
  };

  const getFileText = (url, type = "") => {
    if (!url) return "";

    return `${type} message`;
  };

  const type = detectType(fileType);

  const thumbUrl = type === "image" ? replyTo.thumbUrl || replyTo.fileUrl : replyTo.thumbUrl;

  const fileText = getFileText(fileType, type);

  const handleSanitizeText = (text = "") => {
    const cleanHTML = DOMPurify.sanitize(text);
    return <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />;
  };

  return (
    <div
      className=""
      style={{
        fontSize: "12px",
        position: "relative",
        minWidth: "0",
      }}
    >
      <div className="d-flex">
        <i className="fas fa-reply fa-rotate-180 mr-1 text-gray-800 mr-2" style={{ alignSelf: "center" }}></i>

        {thumbUrl ? (
          <div className="mr-2">
            <ImageLoad openLightbox={() => {}} imageURL={thumbUrl} />
          </div>
        ) : (
          ""
        )}

        {fileType && !thumbUrl ? (
          <div className="mr-2">
            <ImagePlaceholder type={type} iconSize="3x" />
          </div>
        ) : (
          ""
        )}

        <div
          className="mr-2"
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
          }}
        >
          <div style={{ minWidth: "0" }}>
            <div className="text-dark font-weight-bold">
              <span>{replyTo.sender}</span>
            </div>
            {replyTo.text ? (
              <p className="line-clamp-8 text-gray-800 mb-0" style={{ fontStyle: "italic" }}>
                {handleSanitizeText(replyTo.text)}
              </p>
            ) : (
              ""
            )}
            {!replyTo.text && fileText ? <p className="text-capitalize mb-0">{fileText}</p> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyTo;
